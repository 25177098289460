import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import "./FieldTypes.css"
import axios from "axios"

import configs from "config"
const { Url, apiVersion } = configs.client

import { useSelector } from "react-redux"
import CustomSpinner from "components/Common/CustomSpinner"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function FieldTypes({ setStep, fieldTypeData, setFieldTypeData }) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [fieldTypes, setFieldTypes] = useState([])
  const [loadingFieldTypes, setLoadingFieldTypes] = useState(true)

  const [selectedType, setSelectedType] = useState(0)

  const handleSelectType = type => {
    if (selectedType === type) {
      setSelectedType(0)
      setFieldTypeData({})
    } else {
      setSelectedType(type)
      setFieldTypeData(fieldTypes[type - 1])
    }
  }

  const fetchTicketFieldTypes = () => {
    axios
      .get(`${Url}/${apiVersion}/fieldTypes`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        // console.log("res", res)
        setFieldTypes(res.data.data.types)
        setLoadingFieldTypes(false)
      })
      .catch(err => {
        setLoadingFieldTypes(false)

        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to fetch field types. Please try again.")
        }
      })
  }

  useEffect(() => {
    fetchTicketFieldTypes()
  }, [])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="py-3 px-2 field-types">
      <Container fluid>
        <div className="d-flex align-items-center mb-1">
          <i
            onClick={() => navigate("/ticket/fields")}
            className="mdi mdi-arrow-left text-secondary py-0 px-3"
            style={{ cursor: "pointer" }}
          ></i>

          <div className="flex-grow-1">
            <Breadcrumbs title="Fields" breadcrumbItem="Add Field" />
          </div>
        </div>

        {loadingFieldTypes ? (
          <Col md={12}>
            <CustomSpinner />
          </Col>
        ) : (
          <>
            <Col md={8} className="m-auto mt-5">
              <Row className="gx-5">
                <h3 className="mb-3">Select Field Type</h3>

                <Col md={6}>
                  {fieldTypes.slice(0, 4).map((fieldType, idx) => {
                    return (
                      <Card
                        key={idx}
                        className={`rounded-3 overflow-hidden mb-3 ${
                          selectedType === idx + 1
                            ? "field-card-active"
                            : "field-card"
                        }`}
                        onClick={() => {
                          handleSelectType(idx + 1)
                        }}
                      >
                        <CardHeader className="d-flex justify-content-between align-items-center field-card-header p-1 px-2">
                          <h4 className="mb-0">
                            {fieldType.value === "dropdown" && (
                              <i className="mdi mdi-form-dropdown me-2"></i>
                            )}
                            {fieldType.value === "checkbox" && (
                              <i className="mdi mdi-checkbox-marked-outline me-2"></i>
                            )}
                            {fieldType.value === "text-area" && (
                              <i className="mdi mdi-format-align-left me-2"></i>
                            )}
                            {fieldType.value === "multi-select" && (
                              <i className="bx bx-select-multiple me-2"></i>
                            )}
                            {fieldType.value === "text" && (
                              <i className="mdi mdi-format-text me-2"></i>
                            )}
                            {fieldType.value === "number" && (
                              <i className="bx bx-hash me-2"></i>
                            )}
                            {fieldType.value === "date" && (
                              <i className="mdi mdi-calendar-blank-outline me-2"></i>
                            )}
                            {fieldType.value === "decimal" && (
                              <i className="mdi mdi-decimal me-2"></i>
                            )}
                            {fieldType.name} Field
                          </h4>

                          {selectedType === idx + 1 ? (
                            <i className="mdi mdi-checkbox-marked fs-3"></i>
                          ) : (
                            <i className="mdi mdi-checkbox-blank-outline fs-3"></i>
                          )}
                        </CardHeader>
                        <CardBody className="font-size-16 py-3 px-2">
                          {fieldType.description}
                        </CardBody>
                      </Card>
                    )
                  })}
                </Col>

                <Col md={6}>
                  {fieldTypes.slice(4).map((fieldType, idx) => {
                    return (
                      <Card
                        key={idx + 4}
                        className={`rounded-3 overflow-hidden mb-3 ${
                          selectedType === idx + 5
                            ? "field-card-active"
                            : "field-card"
                        }`}
                        onClick={() => {
                          handleSelectType(idx + 5)
                        }}
                      >
                        <CardHeader className="d-flex justify-content-between align-items-center field-card-header p-1 px-2">
                          <h4 className="mb-0">
                            {fieldType.value === "dropdown" && (
                              <i className="mdi mdi-form-dropdown me-2"></i>
                            )}
                            {fieldType.value === "checkbox" && (
                              <i className="mdi mdi-checkbox-marked-outline me-2"></i>
                            )}
                            {fieldType.value === "text-area" && (
                              <i className="mdi mdi-format-align-left me-2"></i>
                            )}
                            {fieldType.value === "multi-select" && (
                              <i className="bx bx-select-multiple me-2"></i>
                            )}
                            {fieldType.value === "text" && (
                              <i className="mdi mdi-format-text me-2"></i>
                            )}
                            {fieldType.value === "number" && (
                              <i className="bx bx-hash me-2"></i>
                            )}
                            {fieldType.value === "date" && (
                              <i className="mdi mdi-calendar-blank-outline me-2"></i>
                            )}
                            {fieldType.value === "decimal" && (
                              <i className="mdi mdi-decimal me-2"></i>
                            )}
                            {fieldType.name} Field
                          </h4>

                          {selectedType === idx + 5 ? (
                            <i className="mdi mdi-checkbox-marked fs-3"></i>
                          ) : (
                            <i className="mdi mdi-checkbox-blank-outline fs-3"></i>
                          )}
                        </CardHeader>
                        <CardBody className="font-size-16 py-3 px-2">
                          {fieldType.description}
                        </CardBody>
                      </Card>
                    )
                  })}
                </Col>
              </Row>

              <div className="d-flex justify-content-end mt-2">
                <Button
                  disabled={!selectedType}
                  onClick={() => setStep(fieldTypeData.value)}
                  variant="secondary"
                  className="font-size-16 py-1"
                >
                  Add Field
                </Button>
              </div>
            </Col>
          </>
        )}
      </Container>
    </div>
  )
}

export default FieldTypes
