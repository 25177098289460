import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import axios from "../../../../node_modules/axios/index"
import { useDispatch, useSelector } from "react-redux"
import configs from "config"
const { Url, apiVersion } = configs.client
import { useParams } from "react-router-dom"
import CustomSpinner from "components/Common/CustomSpinner"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function PastTickets({ ticketPastTicketsSocket }) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
  }))

  const [pastTicketsData, setPastTicketsData] = useState(
    ticketPastTicketsSocket || []
  )
  const [loadingPastTickets, setLoadingPastTickets] = useState(true)

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchPastTickets = () => {
    axios
      .get(`${Url}/${apiVersion}/tickets/${params.ticketId}/past-tickets`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setLoadingPastTickets(false)
        setPastTicketsData(res.data.data.tickets)
      })
      .catch(err => {
        setLoadingPastTickets(false)

        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to fetch past tickets. Please try again.")
        }
      })
  }

  useEffect(() => {
    fetchPastTickets()
  }, [])

  // Use useEffect to update the state when ticketPastTicketsSocket changes
  useEffect(() => {
    if (ticketPastTicketsSocket) {
      setPastTicketsData(ticketPastTicketsSocket)
    }
  }, [ticketPastTicketsSocket])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div
      style={{
        height: "calc(-6px + 77vh)",
        maxHeight: "calc(-6px + 77vh)",
        overflow: "auto",
        padding: "16px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      {loadingPastTickets ? (
        <CustomSpinner />
      ) : (
        <Table responsive bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Category</th>
              <th>Assignee</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {pastTicketsData.map((ticket, index) => (
              <tr
                key={index}
                onClick={() => navigate(`/ticket/list/${ticket._id}`)}
                style={{ cursor: "pointer" }}
              >
                <th scope="row">{ticket.order}</th>
                <td>{ticket?.category?.name}</td>
                <td>{`${ticket.assignee?.firstName} ${ticket.assignee?.lastName}`}</td>
                <td>
                  {new Date(ticket.createdAt).toISOString().split("T")[0]}
                </td>
                <td>
                  <span
                    className={`badge rounded-pill py-1 px-2 ${
                      ticket.priority === "Low"
                        ? "badge-soft-success bg-secondary"
                        : ticket.priority === "Normal"
                        ? "badge-soft-primary bg-primary"
                        : ticket.priority === "High"
                        ? "badge-soft-warning bg-warning"
                        : ticket.priority === "Urgent"
                        ? "badge-soft-danger bg-danger"
                        : ""
                    }`}
                  >
                    {ticket.priority === "Low"
                      ? "Low"
                      : ticket.priority === "Normal"
                      ? "Normal"
                      : ticket.priority === "High"
                      ? "High"
                      : ticket.priority === "Urgent"
                      ? "Urgent"
                      : ""}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default PastTickets
