import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import deleteIcon from "assets/images/setting/deleteIcon.png"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client

import configs from "../../../config"
import { useSelector } from "react-redux"
import { Toast, ToastBody, ToastHeader } from "reactstrap"

import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
export default function DeleteServiceHours({
  services,
  toggle,
  isOpen,
  setShowDeleteToast,
  fetchservicesData,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  // delete request
  const handleDelete = () => {
    setIsLoading(true)
    axios
      .delete(`${Url}/${apiVersion}/services/${services._id}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setShowDeleteToast(true)
        setTimeout(() => setShowDeleteToast(false), 2500)
        toggle()
        setIsLoading(false)
        fetchservicesData()
      })
      .catch(error => {
        // setLoading(false)
        if (
          error.response.status === 401 ||
          error.response.status === 0 ||
          !error.response.status
        ) {
          dispatch(unAuthUser())
        } else if (error.response?.status === 403) {
          setErrorMessage(error?.response?.data.message)
          setTimeout(() => setErrorMessage(false), 3500)
          setIsLoading(false)
        } else {
          console.log(error)
          setErrorMessage(error?.response?.data.message)
          setTimeout(() => setErrorMessage(false), 3500)
          setIsLoading(false)
        }
      })
  }
  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="delete-popup">
      <ModalHeader className="border-0" toggle={toggle}></ModalHeader>
      <ModalBody
        className="border-0 d-flex flex-column align-items-center justify-content-center"
        toggle={toggle}
      >
        {errorMessage && (
          <div className="" aria-live="polite" aria-atomic="true">
            <div className="">
              <Toast isOpen={errorMessage} fade={true}>
                <ToastBody className="delete-message-bg">
                  <h5 className="m-0 mb-2">Error</h5>
                  <p className="m-0">{errorMessage}</p>
                </ToastBody>
              </Toast>
            </div>
          </div>
        )}
        <img src={deleteIcon} alt="deleteIcon" />
        <p className="m-0 mt-1 delete-message text center d-flex  align-items-center justify-content-center">
          You are currently deleting
          <span className="fw-bold"> &nbsp; {services?.name}</span>
        </p>
        <p className="delete-message m-0"> Are you sure ?</p>
      </ModalBody>
      {isLoading && (
        <div className="loading-overlay">
          <Spinner />
        </div>
      )}
      <ModalFooter className="border-0 d-flex align-items-center justify-content-center">
        <Button onClick={handleDelete} className="delete-button  delete">
          Delete
        </Button>
        <Button className="delete-button cancel-delete" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
