import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"

import PerformanceContainer from "./PerformanceFiltersTeamMemeber/PerformanceContainer"
import Overview from "./PerformanceFiltersTeamMemeber/Overview"
import TeamMember from "./TeamMember/TeamMember"

import configs from "../../config"
import {
  teamSessionNumbers,
  unAuthUser,
  userSessionNumbers,
} from "store/actions"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
const { Url, apiVersion } = configs.client

import socket from "socket"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function PerformanceFiltersTeamMembers() {
  const { access, selectedTeams, userData, authorized } = useSelector(
    state => ({
      access: state.Login.userAccess,
      selectedTeams: state.Login.selectedTeams,
      userData: state.Login.userData,
      authorized: state.Login.authorized,
    })
  )

  const dispatch = useDispatch()

  const [sessionLoading, setSessionsLoading] = useState(true)

  const getAllSessions = () => {
    axios
      .get(`${Url}/${apiVersion}/sessions/all/${selectedTeams}`, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json; charset=utf8",
        },
      })
      .then(res => {
        dispatch(userSessionNumbers(res.data.data.usersSessions))
        dispatch(teamSessionNumbers(res.data.data.teamSessions))

        setSessionsLoading(false)
      })
      .catch(err => {
        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          if (
            err.response?.status == 401 ||
            err.response?.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(err)

            const errorMessage =
              err.response?.data?.message || "Failed to load sessions."
            toast.error(errorMessage)
          }

          setSessionsLoading(false)
        }
      })
  }

  useEffect(() => {
    selectedTeams && getAllSessions()
  }, [selectedTeams])

  useEffect(() => {
    if (socket) {
      // Handle socket events related to team sessions
      const handleUpdating = () => {
        if (selectedTeams && selectedTeams.split(",").length > 0) {
          socket.emit("client_to_server", {
            sessions: true,
            teamsIDs: selectedTeams.split(","),
          })
        }
      }

      const handleServerToClient = response => {
        if (response.teamSessions || response.userSessions) {
          if (response.userSessions) {
            response.userSessions.all &&
              dispatch(userSessionNumbers(response.userSessions))
          }

          if (response.teamSessions) {
            response.teamSessions.all &&
              dispatch(teamSessionNumbers(response.teamSessions))
          }
        }
      }

      // Set up socket event listeners
      socket.on("updating", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      // Clean up socket event listeners on component unmount or dependency change
      return () => {
        socket.off("updating", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [selectedTeams, socket])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="col-4 row d-flex flex-column flex-nowrap justify-content-between justify-content-xl-start p-0 performance-filters-section">
      <div className="col-10 offset-1 mb-3">
        <PerformanceContainer sessionLoading={sessionLoading} />

        <Overview overview="My Overview" sessionLoading={sessionLoading} />
        <div style={{ height: "32px" }}></div>
        <Overview overview="Team Overview" sessionLoading={sessionLoading} />
      </div>

      {userData.role === "admin" && (
        <div className="p-0 mt-xl-2 team-members-container">
          <TeamMember />
        </div>
      )}
    </div>
  )
}

export default PerformanceFiltersTeamMembers
