import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListImageMessageContent({
  messageContent,
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
}) {
  return (
    <div title={messageContent}>
      <CorrectMessageMark
        fromWho={fromWho}
        sentMessage={sentMessage}
        deliveredMessage={deliveredMessage}
        seenMessage={seenMessage}
      />
      <svg
        viewBox="0 0 16 20"
        height="20"
        width="16"
        preserveAspectRatio="xMidYMid meet"
        className=""
        version="1.1"
        x="0px"
        y="0px"
        enableBackground="new 0 0 16 20"
        xmlSpace="preserve"
      >
        <path
          fill="#8797A1"
          d="M13.822,4.668H7.14l-1.068-1.09C5.922,3.425,5.624,3.3,5.409,3.3H3.531 c-0.214,0-0.51,0.128-0.656,0.285L1.276,5.296C1.13,5.453,1.01,5.756,1.01,5.971v1.06c0,0.001-0.001,0.002-0.001,0.003v6.983 c0,0.646,0.524,1.17,1.17,1.17h11.643c0.646,0,1.17-0.524,1.17-1.17v-8.18C14.992,5.191,14.468,4.668,13.822,4.668z M7.84,13.298 c-1.875,0-3.395-1.52-3.395-3.396c0-1.875,1.52-3.395,3.395-3.395s3.396,1.52,3.396,3.395C11.236,11.778,9.716,13.298,7.84,13.298z  M7.84,7.511c-1.321,0-2.392,1.071-2.392,2.392s1.071,2.392,2.392,2.392s2.392-1.071,2.392-2.392S9.161,7.511,7.84,7.511z"
        ></path>
      </svg>{" "}
      <span>
        {messageContent
          ? messageContent?.length > 50
            ? messageContent.substring(0, 50) + "..."
            : messageContent
          : "Image"}
      </span>
    </div>
  )
}

export default ChatListImageMessageContent
