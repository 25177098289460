import React, { useEffect, useRef, useState } from "react"
import PerformanceReportsFilters from "./PerformanceReportsComponents/PerformanceReportsFilters"
import PerformanceReportsTable from "./PerformanceReportsComponents/PerformanceReportsTable"
import PaginationComponent from "./BroadcastReportsComponents/PaginationComponent"
import axios from "axios"
import moment from "moment"

import configs from "config"
const { Url, type, version, apiVersion } = configs.client

import { useSelector, useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import PerformanceReportsActions from "./PerformanceReportsComponents/PerformanceReportsActions"
import { unAuthUser } from "store/actions"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function PerformanceReports() {
  const { access, authorized, selectedTeams } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    selectedTeams: state.Login.selectedTeams,
  }))

  const dispatch = useDispatch()

  const [isloading, setIsLoading] = useState(false)
  const [PerformancesData, setPerformancesData] = useState([])

  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const cancelTokenSource = useRef(null)

  const fetchPerformanceTableData = () => {
    let getBody = {}

    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to a new request."
      )
    }

    cancelTokenSource.current = axios.CancelToken.source()

    if (endDate) getBody.startDate = startDate
    if (endDate) getBody.endDate = endDate
    if (selectedTeam)
      getBody.selectedTeams = selectedTeam?.map(obj => obj.value).join(",")
    if (selectedUser)
      getBody.selectedUsers = selectedUser?.map(obj => obj.value).join(",")

    const fetchPerformanceURL = `${Url}/${apiVersion}/performance`

    setIsLoading(true)

    axios
      .get(fetchPerformanceURL, {
        params: getBody,
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
        cancelToken: cancelTokenSource.current.token,
      })
      .then(res => {
        setIsLoading(false)
        setPerformancesData(res.data.data.performances)

        toast.success("Performance data loaded successfully!")
      })
      .catch(err => {
        setIsLoading(false)

        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message)
        } else {
          const status = err.response?.status

          if (
            status === 401 || //
            status === 0 ||
            !status
          ) {
            dispatch(unAuthUser())
          } else {
            const errorMessage =
              err.response?.data?.message || "Failed to load performance data."
            toast.error(errorMessage)
            console.error("Error fetching performance data:", err)
          }
        }
      })
  }

  useEffect(() => {
    if (selectedTeam && selectedTeam.length > 0) {
      setSelectedUser(null)
    }
  }, [selectedTeam])

  useEffect(() => {
    fetchPerformanceTableData()
  }, [selectedUser, selectedTeam, startDate, endDate])

  const handleResetFilters = () => {
    setSelectedTeam([])
    setSelectedUser([])
    setStartDate(null)
    setEndDate(null)

    toast.info("Filters reset.")
  }

  const isFiltered =
    selectedTeam?.length > 0 ||
    selectedUser?.length > 0 ||
    startDate !== null ||
    endDate !== null

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="user-setting-page">
      <div className="performance-page">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">Performance Reports</h4>
          <PerformanceReportsActions
            isFiltered={isFiltered}
            handleResetFilters={handleResetFilters}
            PerformancesData={PerformancesData}
            selectedTeam={selectedTeam}
            selectedUser={selectedUser}
            startDate={startDate}
            endDate={endDate}
            access={access}
          />
        </div>

        <PerformanceReportsFilters
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
        />

        <PerformanceReportsTable
          isloading={isloading}
          PerformancesData={PerformancesData}
        />
      </div>
    </div>
  )
}

export default PerformanceReports
