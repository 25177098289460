import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"

export default function EditSuggestedAnswer({
  isOpen,
  toggle,
  fetchSuggestedAnswersData,
  setShowEditToast,
  Answer,
}) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  // validateYupSchema
  const AddAnswerSchema = Yup.object().shape({
    name: Yup.string()
      // .min(2, "Too Short!")
      // .max(50, "Too Long!")
      .required(" required")
      .matches(/^(?!\s+$).*/, "invalid"),
  })

  useEffect(() => {
    setFieldValue("name", Answer?.name)
  }, [Answer])

  // Formik
  const {
    handleChange,
    values,
    values: { Signature },
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: Answer?.name || "",
    },
    validationSchema: AddAnswerSchema,
    onSubmit: values => {
      const formData = new FormData()

      setIsLoading(true)
      axios
        .patch(`${Url}/${apiVersion}/answers-sets/${Answer._id}`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          toggle()
          setIsLoading(false)
          setShowEditToast(true)
          setTimeout(() => setShowEditToast(false), 2500)
          fetchSuggestedAnswersData()
          resetForm()
        })
        .catch(error => {
          setIsLoading(false)
          if (
            error.response.status === 401 ||
            error.response.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())

            setErrorMessage(error?.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            setIsLoading(false)
          } else {
            setErrorMessage(error?.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            setIsLoading(false)
          }
        })
    },
  })

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>Edit suggested Answer </ModalHeader>
      <ModalBody>
        {errorMessage && (
          <div className="" aria-live="polite" aria-atomic="true">
            <div className="">
              <Toast isOpen={errorMessage} fade={true}>
                <ToastBody className="delete-message-bg">
                  <h5 className="m-0 mb-2">Error</h5>
                  <p className="m-0">{errorMessage}</p>
                </ToastBody>
              </Toast>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <FormGroup className="m-0 m-mb-2">
            <Label className="mb-1">suggested Answer Name</Label>
            <Input
              className=" p-5px m-0"
              id="team-name"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              // placeholder="Email"
              type="text"
            />
            {errors.name && touched.name && (
              <div className="error mb-0">{errors.name}</div>
            )}
          </FormGroup>

          {isLoading && (
            <div className="loading-overlay">
              <Spinner />
            </div>
          )}

          <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
            <Button type="submit" className="edit-button">
              Edit
            </Button>{" "}
            <Button onClick={toggle} className="edit-button cancel-delete ">
              Cancel
            </Button>
          </FormGroup>
        </form>
      </ModalBody>
    </Modal>
  )
}
