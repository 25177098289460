import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import configs from "config"
const { Url, apiVersion } = configs.client
import { useParams } from "react-router-dom"
import axios from "axios"
import moment from "moment"
import CustomSpinner from "components/Common/CustomSpinner"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"

function TicketClientLog({ ticketLogData, loading }) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
  }))

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    // fetchTicketLog()
  }, [])

  const formatLog = log => {
    const userFullName = `${log.user.firstName} ${log.user.lastName}`
    const formattedDate = moment(log.createdAt).format("DD MMMM YYYY hh:mm A")

    switch (log.log) {
      case "create":
        return {
          title: "New Ticket",
          description: `${userFullName} created the ticket`,
          date: formattedDate,
        }
      case "assign":
        return {
          title: "Assign Ticket",
          description: `${userFullName} assigned the ticket to ${log.assignee.firstName} ${log.assignee.lastName}`,
          date: formattedDate,
        }
      case "transfer":
        return {
          title: "Transfer Ticket",
          description: `${userFullName} transferred the ticket from ${log.transfer?.from?.user?.firstName} ${log.transfer?.from?.user?.lastName} to ${log.transfer?.to?.user.firstName} ${log.transfer.to?.user?.lastName}`,
          date: formattedDate,
        }
      case "close":
        return {
          title: "Close Ticket",
          description: `${userFullName} closed the ticket`,
          date: formattedDate,
        }
      case "priority":
        return {
          title: "Update Ticket Priority",
          description: `${userFullName} updated the ticket priority to ${log.priority}`,
          date: formattedDate,
        }
      case "status":
        return {
          title: "Update Ticket Status",
          description: `${userFullName} updated the ticket status to ${log.status.name}`,
          date: formattedDate,
        }
      case "client":
        return {
          title: "Update Client Data",
          description: `${userFullName} updated client data`,
          date: formattedDate,
        }
      case "comment":
        return {
          title: "New Comment",
          description: `${userFullName} added a new comment`,
          date: formattedDate,
        }
      case "takeOwnership":
        return {
          title: "Ticket Ownership",
          description: `${userFullName} took ownership of the ticket`,
          date: formattedDate,
        }
      default:
        return null
    }
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div
      style={{
        maxHeight: "500px",
        overflow: "auto",
      }}
    >
      {loading ? (
        <CustomSpinner />
      ) : (
        ticketLogData.map((log, index) => {
          const formattedLog = formatLog(log)
          return (
            formattedLog && (
              <div key={index} className="mb-4">
                <div className="d-flex justify-content-between">
                  <h6
                    className="font-size-14 mb-1"
                    style={{ fontWeight: "500" }}
                  >
                    {formattedLog.title}
                  </h6>
                  <small className="font-size-10 text-muted">
                    {formattedLog.date}
                  </small>
                </div>
                <p className="font-size-12 text-muted mb-1">
                  {formattedLog.description}
                </p>
                <hr className="my-3" />
              </div>
            )
          )
        })
      )}
    </div>
  )
}

export default TicketClientLog
