import React, { useEffect, useState } from "react"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import ConversationTable from "./ConversationComponents/ConversationTable"
import AddConversation from "./ConversationComponents/AddConversation"
import DeleteConversation from "./ConversationComponents/DeleteConversation"
import EditConversation from "./ConversationComponents/EditConversation"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

function Conversation() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [isloading, setIsLoading] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [showEditToast, setShowEditToast] = useState(false)

  const [conversations, setConversations] = useState([])
  const fetchAllConversationsData = () => {
    setIsLoading(true)
    axios
      .get(`${Url}/${apiVersion}/conversations/`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const ConversationsData = res.data.data.conversations
        setConversations(ConversationsData)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)

        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }
  useEffect(() => {
    fetchAllConversationsData()
  }, [])

  const [addConversation, setAddConversation] = useState(false)
  const toggleAddConversation = () => {
    setAddConversation(!addConversation)
  }

  const [modalDeleteConversation, setModalDeleteConversation] = useState(false)
  const [deleteConversation, setDeleteConversation] = useState(null)
  const toggleDelete = Conversation => {
    setDeleteConversation(Conversation)
    setModalDeleteConversation(!modalDeleteConversation)
  }

  const [modalEditConversation, setModalEditConversation] = useState(false)
  const [editConversation, setEditConversation] = useState(null)
  const toggleEdit = Conversation => {
    setEditConversation(Conversation)
    setModalEditConversation(!modalEditConversation)
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div>
      <div className="user-setting-page">
        {/* add new Conversation */}
        <div className="users-setting d-flex justify-content-between align-items-center mb-3">
          <p className="mb-0">
            Settings <span className="mx-1">&#62;</span>{" "}
            <span className="users-setting-span"> Conversation </span>
          </p>
          <button
            onClick={toggleAddConversation}
            className="py-1 font-size-16 mb-0 rounded-2"
          >
            + Add New Conversation
          </button>
        </div>

        {/* success message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showSuccessToast} fade={true}>
              <ToastBody className="success-message-bg">
                <h5 className="m-0 mb-2">Success</h5>
                <p className="m-0">Conversation Added Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Edit Message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showEditToast} fade={true}>
              <ToastBody className="edit-message-bg">
                <h5 className="m-0 mb-2">Edit</h5>
                <p className="m-0">Conversation edited Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Delete message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showDeleteToast} fade={true}>
              <ToastBody className="delete-message-bg">
                <h5 className="m-0 mb-2">Delete</h5>
                <p className="m-0">Conversation Deleted Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        <ConversationTable
          conversations={conversations}
          isloading={isloading}
          toggleDelete={toggleDelete}
          toggleEdit={toggleEdit}
        />

        <AddConversation
          toggle={toggleAddConversation}
          isOpen={addConversation}
          fetchAllConversationsData={fetchAllConversationsData}
          setShowSuccessToast={setShowSuccessToast}
        />

        <DeleteConversation
          isOpen={modalDeleteConversation}
          toggle={toggleDelete}
          conversation={deleteConversation}
          fetchAllConversationsData={fetchAllConversationsData}
          setShowDeleteToast={setShowDeleteToast}
        />

        <EditConversation
          isOpen={modalEditConversation}
          toggle={toggleEdit}
          conversation={editConversation}
          fetchAllConversationsData={fetchAllConversationsData}
          setShowEditToast={setShowEditToast}
        />
      </div>
    </div>
  )
}

export default Conversation
