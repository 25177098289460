import React from "react"
import { Col, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import AverageTickets from "./AverageTickets"
import TicketPriority from "./ TicketPriority"
import CustomerSatisfaction from "./CustomerSatisfaction"
import NatureRequest from "./ NatureRequest"
import TypeRequest from "./TypeRequest"

export default function TicketCharts({ startDate, endDate }) {
  return (
    <>
      <Row className="gap-4 ticket-overview">
        {/* ********* Average Tickets ******** */}
        <Col md={7} className="ticket-overview-item ">
          <AverageTickets />
        </Col>

        {/* ********* ticket Priority ******** */}
        <Col className="ticket-overview-item ">
          <Row className="d-flex align-items-center heading-border">
            <Col>
              <p className="charts-heading m-0  p-0 mb-2">Ticket Priority</p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center h-75">
            <TicketPriority startDate={startDate} endDate={endDate} />
          </Row>
        </Col>
      </Row>

      <Row className="ticket-overview ticket-overview-g">
        {/* ********* nature type ******** */}
        <Col className="ticket-overview-item">
          <Row className="d-flex align-items-center heading-border">
            <Col>
              <p className="charts-heading m-0  p-0 mb-2">Nature Of Request</p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center h-75 py-3 pt-5">
            <NatureRequest startDate={startDate} endDate={endDate} />
          </Row>
        </Col>

        {/* ********* request type ******** */}
        <Col className="ticket-overview-item">
          <Row className="d-flex align-items-center heading-border">
            <Col>
              <p className="charts-heading m-0 p-0 mb-2">Type Of Request</p>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center align-items-center h-75 py-3">
            <TypeRequest startDate={startDate} endDate={endDate} />
          </Row>
        </Col>

        {/* ********* CustomerS atisfaction ********* */}
        <Col className="ticket-overview-item">
          <Row className="d-flex align-items-center heading-border mb-2">
            <Col>
              <p className="charts-heading m-0  p-0 mb-2">
                Customer Satisfaction
              </p>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center align-items-center h-75">
            <CustomerSatisfaction startDate={startDate} endDate={endDate} />
          </Row>
        </Col>
      </Row>
    </>
  )
}
