import React, { useEffect, useState } from "react"

import AddNewUser from "./UsersComponents/AddNewUser"
import EditUser from "./UsersComponents/EditUser"
import TotalUsers from "./UsersComponents/TotalUsers"
import UserTable from "./UsersComponents/UserTable"
import PaginationComponent from "pages/Reports/BroadcastReportsComponents/PaginationComponent"
import DeleteUser from "./UsersComponents/DeleteUser"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"
import ResetFiltersIcon from "components/Common/Icons/SettingIcons/ResetFiltersIcon"
import RestoreUser from "./UsersComponents/RestoreUser"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import ViewUserTicket from "./UsersComponents/ViewUserTicket"

function Users() {
  const [isloading, setIsLoading] = useState(false)
  const [modalEditUser, setModalEditUser] = useState(false)
  const [editUser, setEditUser] = useState(null)
  const [modalAddNewUser, setModalAddNewUser] = useState(false)
  const [modalDeleteUser, setModalDeleteUser] = useState(false)
  const [deleteUser, setDeleteUser] = useState(null)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)

  const dispatch = useDispatch()

  const toggleAdd = () => {
    // setEditUser(null)
    setModalAddNewUser(!modalAddNewUser)
  }

  const toggleDelete = user => {
    setDeleteUser(user)
    setModalDeleteUser(!modalDeleteUser)
  }

  const toggleEdit = user => {
    setEditUser(user)
    setModalEditUser(!modalEditUser)
  }

  const [viewUser, setViewUser] = useState(null)
  const [modalViewUser, setModalViewUser] = useState(false)
  const toggleView = user => {
    setViewUser(user)
    setModalViewUser(!modalViewUser)
  }

  const [moddleRestore, setModlerestore] = useState(false)
  const [restoreUser, setRestoreUser] = useState(null)
  const toggleRestore = user => {
    setModlerestore(!moddleRestore)
    setRestoreUser(user)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [showEditToast, setShowEditToast] = useState(false)
  const [showRestoreToast, setShowRestoreToast] = useState(false)
  const [users, setUsers] = useState([])
  const [allUserData, setAllUserData] = useState([])
  const [roleFilter, setRoleFilter] = useState("")
  const [searchName, setSearchName] = useState("")
  const [isActive, setIsActive] = useState("")

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      try {
        axios
          .get(`${Url}/${apiVersion}/users`, {
            headers: { Authorization: `Bearer ${access}` },
          })
          .then(response => {
            const AllUsers = response.data.data.users
            console.log(
              "AllUsersssssssssssssssssssssDataaaaaaaaaaaaaaaaaaaaaa",
              response.data.data
            )
            setUsers(AllUsers)
            setFilteredUsers(AllUsers)
            setIsLoading(false)
            setAllUserData(response.data.data)
          })
          .catch(err => {
            if (
              err.response?.status == 401 ||
              err.response?.status == 0 ||
              !err.response.status
            ) {
              // dispatch(unAuthUser())
              UnAuthorized()
            } else {
              console.log(err)
            }
          })
      } catch (error) {
        console.error("Unexpected error:", error)
      }
    }
    // fetchData()
  }, [])
  const [loadingActiveFilter, setLoadingActiveFilter] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const totalPages = allUserData.totalPages
  const fetchFilteredUsers = () => {
    let filters = []

    if (roleFilter) {
      if (roleFilter && roleFilter !== "All") {
        filters.push(`role=${roleFilter}`)
      }
    }
    if (selectedTeam) {
      filters.push(`team=${selectedTeam.value}`)
    }
    if (searchName) {
      filters.push(`name=${searchName}`)
    }
    if (isActive !== "") {
      filters.push(`active=${isActive}`)
    }

    if (currentPage) {
      filters.push(`page=${currentPage}`)
    }

    // setCurrentPage(1)

    const filtersQueryString = filters.length > 0 ? `?${filters.join("&")}` : ""
    setIsLoading(true)
    setLoadingActiveFilter(true)

    axios
      .get(`${Url}/${apiVersion}/users${filtersQueryString}`, {
        headers: { Authorization: `Bearer ${access}` },
        // params: { page: currentPage },
      })
      .then(res => {
        const Filteredusers = res.data.data.users.map(user => ({
          id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone ? user.phone : "No Phone",
          email: user.email,
          role: user.role,
          status: user?.status,
          team: user.team ? user.team.name : "No team",
          photo: user.photo || null,
          deleted: user?.deleted,
          ticketRequests: user?.ticketRequests || [],
          tasks: user?.tasks,
          // ticketRequests: Array.isArray(user.ticketRequests) ? user.ticketRequests : []
        }))
        // const Filteredusers = res.data.data
        setFilteredUsers(Filteredusers)
        setIsLoading(false)
        setLoadingActiveFilter(false)
        setAllUserData(res.data.data)
        setInitialLoading(false)
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [roleFilter, selectedTeam, searchName, isActive])

  const handleResetFilters = () => {
    setIsActive("")
    setRoleFilter("")
    setSelectedTeam(null)
    setSearchName("")
  }
  const handleShowResetFiters =
    searchName !== "" ||
    isActive !== "" ||
    roleFilter !== "" ||
    selectedTeam !== null

  useEffect(() => {
    fetchFilteredUsers()
  }, [roleFilter, selectedTeam, searchName, isActive, currentPage])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      <div className=" user-setting-page ">
        {/* add new user */}
        <div className="users-setting d-flex justify-content-between align-items-center mb-3">
          <p className="mb-0">
            Settings <span className="mx-1">&#62;</span>{" "}
            <span className="users-setting-span"> Users</span>
          </p>
          <div className="d-flex justify-content-center gap-2 align-items-center">
            {/* {handleShowResetFiters ? (
              <button onClick={handleResetFilters} className="reset-filters">
                <ResetFiltersIcon />
                &nbsp; Reset Filters
              </button>
            ) : null} */}

            <button
              onClick={toggleAdd}
              className="py-1 font-size-16 mb-0 rounded-2"
            >
              {" "}
              + Add New User
            </button>
          </div>
        </div>
        {/* success message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showSuccessToast} fade={true}>
              <ToastBody className="success-message-bg">
                <h5 className="m-0 mb-2">Success</h5>
                <p className="m-0">User Added Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>
        {/* Edit Message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showEditToast} fade={true}>
              <ToastBody className="edit-message-bg">
                <h5 className="m-0 mb-2">Edit</h5>
                <p className="m-0">User edited Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>
        {/* Delete message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showDeleteToast} fade={true}>
              <ToastBody className="delete-message-bg">
                <h5 className="m-0 mb-2">Delete</h5>
                <p className="m-0">User Deleted Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* restore message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showRestoreToast} fade={true}>
              <ToastBody className="edit-message-bg">
                <h5 className="m-0 mb-2">restored</h5>
                <p className="m-0">User restored Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* users status */}
        <TotalUsers
          allUserData={allUserData}
          isActive={isActive}
          setIsActive={setIsActive}
          // isloading={isloading}
          setLoadingActiveFilter={setLoadingActiveFilter}
          loadingActiveFilter={loadingActiveFilter}
          initialLoading={initialLoading}
          setInitialLoading={setInitialLoading}
        />
        {/* table */}
        <UserTable
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          isloading={isloading}
          setIsLoading={setIsLoading}
          toggleEdit={toggleEdit}
          toggleDelete={toggleDelete}
          toggleRestore={toggleRestore}
          toggleView={toggleView}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          roleFilter={roleFilter}
          setRoleFilter={setRoleFilter}
          searchName={searchName}
          setSearchName={setSearchName}
          allUserData={allUserData}
          handleResetFilters={handleResetFilters}
          handleShowResetFiters={handleShowResetFiters}
        />
        {/* pagination */}
        <PaginationComponent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
        <AddNewUser
          fetchFilteredUsers={fetchFilteredUsers}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          isOpen={modalAddNewUser}
          toggle={toggleAdd}
          setShowSuccessToast={setShowSuccessToast}
        />
        <EditUser
          fetchFilteredUsers={fetchFilteredUsers}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          isOpen={modalEditUser}
          toggle={toggleEdit}
          user={editUser}
          setFilteredUsers={setFilteredUsers}
          setShowEditToast={setShowEditToast}
        />
        <ViewUserTicket
          isOpen={modalViewUser}
          toggle={toggleView}
          user={viewUser}
        />
        <DeleteUser
          isOpen={modalDeleteUser}
          toggle={toggleDelete}
          user={deleteUser}
          setFilteredUsers={setFilteredUsers}
          filteredUsers={filteredUsers}
          setShowDeleteToast={setShowDeleteToast}
          fetchFilteredUsers={fetchFilteredUsers}
        />

        <RestoreUser
          isOpen={moddleRestore}
          toggle={toggleRestore}
          user={restoreUser}
          setShowRestoreToast={setShowRestoreToast}
          fetchFilteredUsers={fetchFilteredUsers}
        />
      </div>
    </>
  )
}

export default Users
