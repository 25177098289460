import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import deleteIcon from "assets/images/setting/deleteIcon.png"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import RestorePopUp from "components/Common/Icons/SettingIcons/RestorePopUp"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

export default function RestoreUser({
  toggle,
  isOpen,
  user,
  setShowRestoreToast,
  fetchFilteredUsers,
}) {
  const [isLoading, setIsLoading] = useState(false)

  //   const { access } = useSelector(state => ({
  //     access: state.Login.userAccess,
  //   }))
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  // Restore request
  const handleRestore = () => {
    setIsLoading(true)
    axios
      .patch(
        `${Url}/${apiVersion}/users/recovery/${user.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        fetchFilteredUsers()
        setShowRestoreToast(true)
        setTimeout(() => setShowRestoreToast(false), 2500)

        toggle()
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)

        if (
          error.response.status === 401 ||
          error.response.status === 0 ||
          !error.response?.status
        ) {
          dispatch(unAuthUser())
        } else if (error.response?.status === 403) {
          console.log("error response", error)
        } else {
          console.log(error)
        }
      })
  }
  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="delete-popup">
      <ModalHeader className="border-0" toggle={toggle}></ModalHeader>
      <ModalBody
        className="border-0 d-flex flex-column align-items-center justify-content-center"
        toggle={toggle}
      >
        <RestorePopUp />
        <p className="m-0 p-0 mt-3  delete-message text center d-flex  align-items-center justify-content-center">
          You are now restoring :
          <span className="fw-bold m-0 p-0">
            &nbsp; "{user?.firstName} {user?.lastName}"
          </span>
        </p>
        <p className="delete-message m-0"> Are you sure ?</p>
      </ModalBody>
      {isLoading && (
        <div className="loading-overlay">
          <Spinner />
        </div>
      )}
      <ModalFooter className="border-0 d-flex align-items-center  justify-content-center mb-4">
        <Button onClick={handleRestore} className="edit-button">
          Restore
        </Button>
        <Button className="edit-button cancel-delete" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
