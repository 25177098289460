import React from "react"
import Select from "react-select"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { Formik, Field, ErrorMessage } from "formik"

function TransferToAnotherTeam({
  conversationActions,
  selectAnotherTeamModal,
  toggleSelectAnotherTeamModal,
  anotherTeams,
}) {
  const teamOptions = anotherTeams?.teams?.map(team => ({
    value: team._id,
    label: team.name,
  }))

  return (
    <Modal
      isOpen={selectAnotherTeamModal}
      toggle={toggleSelectAnotherTeamModal}
      centered
    >
      <Formik
        initialValues={{ selectedTeam: "" }}
        validationSchema={Yup.object().shape({
          selectedTeam: Yup.string().required("You need to select a team"),
        })}
        onSubmit={values => {
          conversationActions("transferToTeam", "", values.selectedTeam)
          toggleSelectAnotherTeamModal()
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader toggle={toggleSelectAnotherTeamModal}>
              Select Another Team
            </ModalHeader>
            <ModalBody
              style={{
                maxHeight: "40vh",
                overflow: "auto",
                minHeight: "40vh",
              }}
            >
              <Select
                options={teamOptions}
                value={teamOptions.find(
                  option => option.value === values.selectedTeam
                )}
                onChange={selectedOption => {
                  setFieldValue("selectedTeam", selectedOption.value)
                }}
                placeholder="Select Another Team"
              />
              <ErrorMessage
                name="selectedTeam"
                component="div"
                className="text-danger"
              />
            </ModalBody>
            <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
              <Button
                color="secondary"
                outline
                onClick={toggleSelectAnotherTeamModal}
                style={{ padding: "0.1875rem 0.875rem" }}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                type="submit"
                style={{ padding: "0.1875rem 0.875rem" }}
              >
                Submit
              </Button>{" "}
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default TransferToAnotherTeam
