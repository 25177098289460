import React, { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
  Container,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import * as Yup from "yup"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { PhoneInput } from "react-international-phone"
import UploadProfilePhoto from "./UploadProfilePhoto"
import { unAuthUser } from "store/actions"

export default function ChangeUserName({
  userData,
  setShowSuccessToast,
  photoView,
  setPhotoView,
  setUserDataProfile,
  fetchTeamsData,
}) {
  const dispatch = useDispatch()

  const AddUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    lastName: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    email: Yup.string()
      .email("Invalid email")
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
  })
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))
  useEffect(() => {
    setFieldValue("firstName", values.firstName)
    setFieldValue("lastName", values.lastName)
    setFieldValue("email", values.email)
    // setFieldValue("photo", userData?.photo)
  }, [userData])

  // formik
  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
    },
    validationSchema: AddUserSchema,

    onSubmit: values => {
      const formData = new FormData()
      formData.append("firstName", values.firstName)
      formData.append("lastName", values.lastName)
      formData.append("email", values.email)
      if (photoView) {
        formData.append("photo", photoView)
      } else if (userData.photo) {
        formData.append("photo", userData.photo)
      } else {
        formData.append("removePhoto", true)
      }
      setIsLoading(true)
      axios
        .patch(`${Url}/${apiVersion}/users/updateMe`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          if (res.status === 200) {
            setIsLoading(false)
            setShowSuccessToast(true)
            setTimeout(() => setShowSuccessToast(false), 2500)
            fetchTeamsData()
            setUserDataProfile(res.data.data.user)
          } else {
            console.error("Unexpected status code:", res.status, res.data)
          }
        })
        .catch(error => {
          setIsLoading(false)

          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else if (error.response?.status === 403) {
            setErrorMessage(error?.response?.data?.message)
            setTimeout(() => setErrorMessage(false), 3500)
          } else {
            setErrorMessage(error?.response?.data?.message)
            setTimeout(() => setErrorMessage(false), 3500)
            console.log(error)
          }
        })
    },
  })
  // useEffect(() => {
  //   setFieldValue("photo", userData?.photo)
  // }, [userData])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="change-user-name">
      <UploadProfilePhoto
        userData={userData}
        photoView={photoView}
        setPhotoView={setPhotoView}
        setFieldValue={setFieldValue}
      />

      {isLoading && (
        <div className="loading-overlay ">
          <Spinner />
        </div>
      )}
      {/* <h4 className="m-0 mb-4">Change User Name</h4> */}
      {errorMessage && (
        <div className="" aria-live="polite" aria-atomic="true">
          <div className="">
            <Toast isOpen={errorMessage} fade={true}>
              <ToastBody className="delete-message-bg">
                <h5 className="m-0 mb-2">Error</h5>
                <p className="m-0">{errorMessage}</p>
              </ToastBody>
            </Toast>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">First Name</Label>
              <Input
                className=" p-5px m-0 "
                id="firstName"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                //   placeholder="FirstName"
                type="text"
              />
              {errors.firstName && touched.firstName && (
                <div className="error mb-0">{errors.firstName}</div>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Last Name</Label>
              <Input
                className=" p-5px m-0"
                id="lastName"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                //   placeholder="LastName"
                type="text"
              />
              {errors.lastName && touched.lastName && (
                <div className="error mb-0">{errors.lastName}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="m-0 m-mb-2">
          <Label className="mb-1">Email</Label>
          <Input
            className=" p-5px m-0"
            id="email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            // placeholder="Email"
            type="email"
          />
          {errors.email && touched.email && (
            <div className="error mb-0">{errors.email}</div>
          )}
        </FormGroup>

        {/* {isLoading && (
          <div className="loading-overlay ">
            <Spinner />
          </div>
        )} */}

        <FormGroup className=" d-flex justify-content-end gap-3 edit-popup mt-2">
          <Button type="submit" className="edit-button">
            Update
          </Button>
        </FormGroup>
      </form>
    </div>
  )
}
