import React, { useEffect, useState } from "react"
import axios from "axios"
import InfiniteScroll from "react-infinite-scroll-component"

import ChatItem from "./ChatItem"
import { useSelector, useDispatch } from "react-redux"
import {
  unAuthUser,
  updateArhciveEndDate,
  updateArhciveStartDate,
  updateArhcivedUserId,
  updateChatStatus,
} from "store/actions"
import socket from "socket"
import CustomSpinner from "components/Common/CustomSpinner"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

import configs from "../../../config"
const { Url, apiVersion } = configs.client

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function ChatListContainer({
  searchInChatsValue,
  selectedUserArchived,
  userIDArchived,
  startDateArchived,
  endDateArchived,
}) {
  const {
    access,
    chatsType,
    chatStatus,
    currentOverView,
    selectedTeams,
    chatsFilter,
    archivedUserId,
    archivedStartDate,
    archivedEndDate,
    selectedTeamMember,
    authorized,
  } = useSelector(state => ({
    access: state.Login.userAccess,
    chatsType: state.Login.chatsType,
    chatStatus: state.Login.chatStatus,
    currentOverView: state.Login.currentOverView,
    selectedTeams: state.Login.selectedTeams,
    chatsFilter: state.Login.chatsFilter,
    archivedUserId: state.Login.archivedUserId,
    archivedStartDate: state.Login.archivedStartDate,
    archivedEndDate: state.Login.archivedEndDate,
    selectedTeamMember: state.Login.selectedTeamMember,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [chats, setChats] = useState([])
  const [filteredChats, setFilteredChats] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [chatsLoading, setChatsLoading] = useState(true)

  const fetchChatList = () => {
    if (chatsType === "teamUserChats") {
      axios
        .get(`${Url}/${apiVersion}/chats/${chatsType}/${selectedTeamMember}`, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setChatsLoading(false)
          setChats(res.data.data.chats)
        })
        .catch(err => {
          if (
            err.response.status == 401 ||
            err.response.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            setChatsLoading(false)
            console.log(err)
            toast.error("Failed to load chats.")
          }
        })
    }

    if (chatsType === "userChats") {
      setChatsLoading(true)

      dispatch(updateArhcivedUserId(""))
      dispatch(updateArhciveStartDate(""))
      dispatch(updateArhciveEndDate(""))
      // dispatch(changeSelectedOverview(currentOverView, chatStatus, "userChats"))

      setPage(1)

      axios
        .get(`${Url}/${apiVersion}/chats/${chatsType}?status=${chatStatus}`, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setChatsLoading(false)
          setChats(res.data.data.chats)
        })
        .catch(err => {
          if (
            err.response.status == 401 ||
            err.response.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            setChatsLoading(false)
            console.log(err)
            toast.error("Failed to load chats.")
          }
        })
    }

    if (chatsType === "teamChats") {
      setChatsLoading(true)

      dispatch(updateArhcivedUserId(""))
      dispatch(updateArhciveStartDate(""))
      dispatch(updateArhciveEndDate(""))
      // dispatch(changeSelectedOverview(currentOverView, chatStatus, "teamChats"))

      setPage(1)

      axios
        .get(
          `${Url}/${apiVersion}/chats/${chatsType}?status=${chatStatus}&teams=${selectedTeams}`,
          {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          }
        )
        .then(res => {
          setChatsLoading(false)
          setChats(res.data.data.chats)
        })
        .catch(err => {
          if (
            err.response.status == 401 ||
            err.response.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            setChatsLoading(false)
            console.log(err)
            toast.error("Failed to load chats.")
          }
        })
    }

    if (chatsType === "archivedChats") {
      page === 1 && setChatsLoading(true)
      // dispatch(updateChatStatus(""))

      let params = {}

      if (archivedUserId) params.userID = archivedUserId
      if (archivedStartDate) params.startDate = archivedStartDate
      if (archivedEndDate) params.endDate = archivedEndDate

      if (archivedUserId || archivedStartDate || archivedEndDate)
        setChatsLoading(true)

      axios
        .get(`${Url}/${apiVersion}/chats/archivedChats?page=${page}`, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
          params,
        })
        .then(res => {
          setChatsLoading(false)
          setChats(res.data.data.chats)
          setTotalPages(res.data.data.totalPages)

          setPage(prevPage => prevPage + 1)
        })
        .catch(err => {
          if (
            err.response.status == 401 ||
            err.response.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            setChatsLoading(false)
            console.log(err)

            toast.error("Failed to load chats.")
          }
        })
    }
  }

  useEffect(() => {
    selectedTeams && fetchChatList()
  }, [
    chatsType,
    chatStatus,
    selectedTeams,
    archivedUserId,
    archivedStartDate,
    archivedEndDate,
    selectedTeamMember,
  ])

  useEffect(() => {
    let filtered = chats

    if (chatsFilter === "read") {
      filtered = chats.filter(chat => chat.notification === false)
    } else if (chatsFilter === "unread") {
      filtered = chats.filter(chat => chat.notification === true)
    }

    // if (searchInChatsValue && chatsType !== "archivedChats") {
    if (searchInChatsValue) {
      filtered = filtered.filter(
        chat =>
          chat.client?.includes(searchInChatsValue) ||
          chat.contactName?.name?.includes(searchInChatsValue)
      )
    }

    setFilteredChats(filtered)
  }, [chats, chatsFilter, searchInChatsValue])

  useEffect(() => {
    let socketEmitBody = {}
    if (chatsType === "userChats") socketEmitBody.chatsType = "user"
    if (chatsType === "teamChats") socketEmitBody.chatsType = "team"
    if (chatsType !== "archivedChats") socketEmitBody.status = chatStatus
    if (chatsType === "teamChats") {
      socketEmitBody.teamsIDs = selectedTeams?.split(",")
    }

    if (chatsType === "archivedChats") {
      socketEmitBody.chatsType = "archived"
      socketEmitBody.page = page

      if (userIDArchived) {
        socketEmitBody.userID = userIDArchived
      }
      if (startDateArchived) {
        socketEmitBody.startDate = startDateArchived
      }
      if (endDateArchived) {
        socketEmitBody.endDate = endDateArchived
      }
    }
    if (chatsType === "teamUserChats") socketEmitBody.chatsType = "teamUser"
    if (chatsType === "teamUserChats")
      socketEmitBody.teamUserID = selectedTeamMember

    if (socket) {
      const handleUpdating = () => {
        socket.emit("client_to_server", socketEmitBody)
      }

      const handleServerToClient = response => {
        response.chats && setChats(response.chats)
      }

      socket.on("updating", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      return () => {
        socket.off("updating", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [chatStatus, chatsType, selectedTeams, page, selectedTeamMember, socket])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div id="scrollableDiv" className="chat-list d-flex flex-column">
      {chatsLoading ? (
        <CustomSpinner />
      ) : chatsType === "archivedChats" ? (
        filteredChats.length > 0 &&
        !chatsLoading && (
          <InfiniteScroll
            dataLength={filteredChats.length}
            next={fetchChatList}
            hasMore={page <= totalPages}
            loader={<h4 className="text-center">Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {filteredChats.map((chat, i) => (
              <ChatItem key={i} chat={chat} />
            ))}
          </InfiniteScroll>
        )
      ) : (
        filteredChats.map((chat, i) => <ChatItem key={i} chat={chat} />)
      )}

      {!chatsLoading && filteredChats.length === 0 && (
        <h5 className="text-center">No chats found</h5>
      )}
    </div>
  )
}

export default ChatListContainer
