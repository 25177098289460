import React, { useEffect, useState } from "react"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
import AwayIcon from "components/Common/Icons/SidebarIcons/AwayIcon"
import OnlineIcon from "components/Common/Icons/SidebarIcons/OnlineIcon"
import OfflineIcon from "components/Common/Icons/SidebarIcons/OfflineIcon"
import ServiceHoursIcon from "components/Common/Icons/SidebarIcons/ServiceHoursIcon"
import FilterUsers from "./FilterUsers"
import FilterIcon from "components/Common/Icons/SettingIcons/FilterIcon"
import PaginationComponent from "pages/Reports/BroadcastReportsComponents/PaginationComponent"
import ResetFiltersIcon from "components/Common/Icons/SettingIcons/ResetFiltersIcon"
import RestoreIcone from "components/Common/Icons/SettingIcons/RestoreIcone"
import DefaultPhoto from "../../../assets/images/default.jpg"

export default function UserTable({
  setIsLoading,
  toggleEdit,
  isloading,
  toggleDelete,
  filteredUsers,
  setFilteredUsers,
  selectedTeam,
  setSelectedTeam,
  users,
  roleFilter,
  setRoleFilter,
  setSearchName,
  allUserData,
  searchName,
  handleShowResetFiters,
  handleResetFilters,
  toggleRestore,
  toggleView,
}) {
  const handleRoleFilterChange = role => {
    setRoleFilter(role)
  }
  return (
    <div>
      {/*   users Role */}
      <div className="d-flex justify-content-between align-items-center my-3">
        <div className="d-flex gap-4 align-items-center">
          <button
            onClick={() => handleRoleFilterChange("")}
            disabled={roleFilter === "All"}
            className={`filter-button ${
              roleFilter === "All" || roleFilter === ""
                ? "active-user-role-filter"
                : ""
            } mb-0`}
          >
            All
          </button>
          <button
            onClick={() => handleRoleFilterChange("admin")}
            disabled={roleFilter === "admin"}
            className={`filter-button ${
              roleFilter === "admin" ? "active-user-role-filter" : ""
            } mb-0`}
          >
            Admin
          </button>
          <button
            onClick={() => handleRoleFilterChange("user")}
            disabled={roleFilter === "user"}
            className={`filter-button ${
              roleFilter === "user" ? "active-user-role-filter" : ""
            } mb-0`}
          >
            Users
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <FilterUsers
            setFilteredUsers={setFilteredUsers}
            setIsLoading={setIsLoading}
            users={users}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            roleFilter={roleFilter}
            setRoleFilter={setRoleFilter}
            setSearchName={setSearchName}
            searchName={searchName}
            handleResetFilters={handleResetFilters}
            handleShowResetFiters={handleShowResetFiters}
          />
        </div>
      </div>
      <div className="table-container table-container-user m-0 p-0">
        {isloading ? (
          <div className="  loading">
            <div className="spinner-border "></div>
          </div>
        ) : (
          <table>
            <thead className="text-center">
              <tr>
                <th>Name </th>
                <th> WhatsApp Number</th>
                <th>Team </th>
                <th> Role </th>
                <th> Activity </th>
                <th>Status </th>
                <th>Action </th>
              </tr>
            </thead>
            {filteredUsers && filteredUsers.length > 0 ? (
              <tbody>
                {filteredUsers.map(user => (
                  <tr
                    key={user.id}
                    className="tr"
                    onClick={() => toggleView(user)}
                  >
                    <td>
                      <div className="d-flex  justify-content-center ">
                        <div className="d-flex justify-content-between align-items-center gap-3 ">
                          <div>
                            {user.photo ? (
                              <img
                                className="empty-avatar"
                                // src={user?.photo}
                                src={`${Url}/${user?.photo}`}
                                // alt={`${user.firstName} ${user.lastName}`}
                              />
                            ) : (
                              // <div className="empty-avatar d-flex justify-content-center align-items-center"></div>
                              <img
                                className="empty-avatar"
                                // src={user?.photo}
                                src={DefaultPhoto}
                                // alt={`${user.firstName} ${user.lastName}`}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              width: "8rem",
                            }}
                          >
                            <p className="m-0 user-name">
                              {user.firstName + " " + user.lastName}
                            </p>
                            <p className="m-0 user-email">{user.email}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-center"> {user.phone} </td>

                    <td className="text-center">{user.team}</td>
                    <td className="text-center text-capitalize">{user.role}</td>
                    <td className="text-center">
                      <button
                        disabled
                        className={`border-0  user-status ${
                          user.deleted === false ? "online-status" : ""
                        } ${user.deleted === true ? "offline-status" : ""}`}
                      >
                        {user.deleted === false ? "Active" : ""}
                        {user.deleted === true ? "Inactive" : ""}
                      </button>
                    </td>

                    <td className="text-center  ">
                      <button
                        disabled
                        className={`border-0 user-status ${
                          user.status === "Away" ? "away-status" : ""
                        } ${user.status === "Online" ? "online-status" : ""} ${
                          user.status === "Offline" ? "offline-status" : ""
                        } ${
                          user.status === "Service hours"
                            ? "service-hours-status"
                            : ""
                        }`}
                      >
                        {user.status === "Away" && <AwayIcon />}
                        {user.status === "Online" && <OnlineIcon />}
                        {user.status === "Offline" && <OfflineIcon />}
                        {user.status === "Service hours" && (
                          <ServiceHoursIcon />
                        )}
                        <span className="m-0"> {user.status}</span>
                      </button>
                    </td>

                    {/* <td className="text-center">{user.status}</td> */}
                    <td className="text-center">
                      <button
                        className="border-0 bg-transparent"
                        onClick={e => {
                          e.stopPropagation()
                          toggleEdit(user)
                        }}
                        title="Edit"
                      >
                        <TableEditIcon />
                      </button>

                      {user.deleted === false && (
                        <button
                          // onClick={() => toggleDelete(user)}
                          className="border-0 bg-transparent"
                          title="Delete"
                          onClick={e => {
                            e.stopPropagation()
                            toggleDelete(user)
                          }}
                        >
                          <TableDeleteIcon filteredUsers={filteredUsers} />
                        </button>
                      )}
                      {user.deleted === true && (
                        <button
                          // onClick={() => toggleRestore(user)}
                          className="border-0 bg-transparent"
                          title="Restore"
                          onClick={e => {
                            e.stopPropagation()
                            toggleRestore(user)
                          }}
                        >
                          <RestoreIcone />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="6" className="text-center p-2">
                    No user found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        )}
      </div>
    </div>
  )
}
