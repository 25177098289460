import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import deleteIcon from "assets/images/setting/deleteIcon.png"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

export default function DeleteTeam({
  toggle,
  isOpen,
  setShowDeleteToast,
  setTeams,
  team,
  fetchTeamsData,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  // delete request
  const handleDelete = () => {
    setIsLoading(true)
    axios
      .delete(`${Url}/${apiVersion}/teams/${team._id}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        // setTeams(prevTeams => prevTeams.filter(t => t.id !== team._id))
        setIsLoading(false)
        toggle()
        fetchTeamsData()
        setShowDeleteToast(true)
        setTimeout(() => setShowDeleteToast(false), 2500)
      })
      .catch(error => {
        setIsLoading(false)
        if (
          error.response?.status === 401 ||
          error.response?.status === 0 ||
          !error.response.status
        ) {
          dispatch(unAuthUser())
        } else if (error.response?.status === 403) {
          setErrorMessage(error?.response?.data.message)
          setTimeout(() => setErrorMessage(false), 3500)
          setIsLoading(false)
        } else {
          setErrorMessage(error?.response?.data.message)
          setTimeout(() => setErrorMessage(false), 3500)
          setIsLoading(false)
          console.log(error)
        }
      })
  }
  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="delete-popup">
      <ModalHeader className="border-0" toggle={toggle}></ModalHeader>
      <ModalBody
        className="border-0 d-flex flex-column align-items-center justify-content-center"
        toggle={toggle}
      >
        <img src={deleteIcon} alt="deleteIcon" />
        <p className="m-0 delete-message">Are you sure you want to delete ?</p>
      </ModalBody>
      {isLoading && (
        <div className="loading-overlay">
          <Spinner />
        </div>
      )}
      <ModalFooter className="border-0 d-flex align-items-center justify-content-center">
        <Button onClick={handleDelete} className="delete-button  delete">
          Delete
        </Button>
        <Button className="delete-button cancel-delete" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
