import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import AdminTeamsDropdown from "./AdminTeamsDropdown"
import { logoutUser, unAuthUser, updateSelectedTeams } from "store/actions"

import configs from "../../../config"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import UnAuthorized from "store/auth/logout/UnAuthorized"
const { Url, apiVersion } = configs.client

function AdminTeamsDropdownContainer({ overview }) {
  const { userData, access, authorized } = useSelector(state => ({
    userData: state.Login.userData,
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [teams, setTeams] = useState([])
  const [selectedTeams, setSelectedTeams] = useState([])

  const getTeamsData = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const fetchedTeams = res.data.data.teams
        setTeams(fetchedTeams)

        const teamIds = fetchedTeams.map(team => team._id)
        setSelectedTeams(teamIds)
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    overview === "Team Overview" && getTeamsData()
  }, [])

  const handleSelectAllTeams = () => {
    setSelectedTeams(teams.map(team => team._id))
  }

  useEffect(() => {
    if (selectedTeams.length === 0 && teams.length > 0) {
      handleSelectAllTeams()
    }

    if (userData.role === "admin") {
      selectedTeams.length > 0 && dispatch(updateSelectedTeams(selectedTeams))
    } else {
      dispatch(updateSelectedTeams([userData.team._id]))
    }
  }, [selectedTeams, teams])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      {overview === "Team Overview" && userData.role === "admin" && (
        <AdminTeamsDropdown
          teams={teams}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
        />
      )}
    </>
  )
}

export default AdminTeamsDropdownContainer
