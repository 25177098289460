import React from "react"
import Select from "react-select"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { Formik, ErrorMessage } from "formik"

function TransferToTeamMember({
  conversationActions,
  selectTeamMemberModal,
  toggleSelectTeamMemeberModal,
  teamMembers,
}) {
  const userOptions = teamMembers?.users?.map(user => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`,
  }))

  return (
    <Modal
      isOpen={selectTeamMemberModal}
      toggle={toggleSelectTeamMemeberModal}
      centered
    >
      <Formik
        initialValues={{ selectedUser: "" }}
        validationSchema={Yup.object().shape({
          selectedUser: Yup.string().required("You need to select a user"),
        })}
        onSubmit={values => {
          conversationActions("transferToUser", values.selectedUser)
          toggleSelectTeamMemeberModal()
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader toggle={toggleSelectTeamMemeberModal}>
              Select A Team Member
            </ModalHeader>
            <ModalBody
              style={{
                maxHeight: "40vh",
                overflow: "auto",
                minHeight: `${userOptions && "40vh"}`,
              }}
            >
              <Select
                options={userOptions}
                value={userOptions.find(
                  option => option.value === values.selectedUser
                )}
                onChange={selectedOption => {
                  setFieldValue("selectedUser", selectedOption.value)
                }}
                placeholder="Select A Team Member"
              />
              <ErrorMessage
                name="selectedUser"
                component="div"
                className="text-danger"
              />
            </ModalBody>
            <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
              <Button
                color="secondary"
                outline
                onClick={toggleSelectTeamMemeberModal}
                style={{ padding: "0.1875rem 0.875rem" }}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                type="submit"
                style={{ padding: "0.1875rem 0.875rem" }}
              >
                Submit
              </Button>{" "}
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default TransferToTeamMember
