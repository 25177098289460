import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListTextMessageContent({
  idx,
  messageContent,
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
  emojiReaction,
  fullTextData,
}) {
  return (
    <div title={fullTextData}>
      {emojiReaction() ? (
        emojiReaction()
      ) : (
        <>
          <CorrectMessageMark
            idx={idx}
            fromWho={fromWho}
            sentMessage={sentMessage}
            deliveredMessage={deliveredMessage}
            seenMessage={seenMessage}
          />{" "}
          {messageContent}
        </>
      )}
    </div>
  )
}

export default ChatListTextMessageContent
