import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
  Container,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"

import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import Select from "react-select"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
// import { PhoneInput, defaultCountries } from "react-international-phone"
// import "react-international-phone/style.css"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { RowExpanding } from "@tanstack/react-table"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

export default function AddNewUser({
  toggle,
  isOpen,
  setFilteredUsers,
  // selectedTeam,
  // setSelectedTeam,
  selectedRole,
  setSelectedRole,
  setShowSuccessToast,
  fetchFilteredUsers,
}) {
  const [teams, setTeams] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(null)

  const dispatch = useDispatch()

  // users role
  const roleOptions = [
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
  ]
  const handleRoleChange = selectedOption => {
    setSelectedRole(selectedOption)
    setFieldValue("role", selectedOption.value)
  }

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  //  fetch team
  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/teams`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          const Allteams = res.data.data.teams.map(team => ({
            value: team._id,
            label: team.name,
          }))
          // setTeams(res.data.data.teams)
          setTeams(Allteams)
        })
        .catch(error => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0 ||
            !error.response.status
          ) {
            UnAuthorized()
          } else {
            console.log(error)
          }
        })
    }
  }, [isOpen])
  const handleTeamChange = selectedOption => {
    setSelectedTeam(selectedOption)
    setFieldValue("team", selectedOption.value)
  }
  const AddUserSchema = Yup.object().shape({
    firstName: Yup.string()

      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    lastName: Yup.string()

      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),

    email: Yup.string()
      .email("Invalid email")
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),

    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    phone: Yup.string().required("required"),
    tasks: Yup.array().min(1, "required").required("required"),
  })

  //ticket requests
  const ticketRequestsOptions = [
    { value: "RD0", label: "RD0" },
    { value: "Edit RD0", label: "Edit RD0" },
    { value: "Missing Data", label: "Missing Data" },
    { value: "Design Review", label: "Design Review" },
    { value: "RD6", label: "RD6" },
    { value: "RD7", label: "RD7" },
    { value: "Finance", label: "Finance" },
    { value: "Inspection", label: "Inspection" },
    { value: "MALATH Issue", label: "MALATH Issue" },
    { value: "MALATH Complaint", label: "MALATH Complaint" },
    { value: "Other", label: "Other" },
  ]
  const [selectedTicket, setSelectedTicket] = useState(null)

  const handleTicketChange = selectedOption => {
    setSelectedTicket(selectedOption)
    const selectedTickets = selectedOption
      ? selectedOption.map(option => option.value)
      : []
    setFieldValue("ticketRequests", selectedTickets)
  }

  // tasks:
  const [selectedTasks, setSelectedTasks] = useState(null)
  const tasksOptions = [
    { value: "tickets", label: "tickets" },
    { value: "messages", label: "messages" },
  ]
  const handleTasksChange = selectedOptions => {
    setSelectedTasks(selectedOptions)
    const selectedTasks = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setFieldValue("tasks", selectedTasks)
  }

  // formik
  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      ticketRequests: [],
      tasks: [],
    },
    validationSchema: AddUserSchema,
    onSubmit: values => {
      setIsLoading(true)
      axios
        .post(`${Url}/${apiVersion}/users`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          if (res.status === 201) {
            const newUser = res.data.data.user
            const teamName =
              teams.find(team => team.value === newUser.team)?.label ||
              "No Team"
            toggle()
            setIsLoading(false)
            fetchFilteredUsers()
            setShowSuccessToast(true)
            setTimeout(() => setShowSuccessToast(false), 2500)
          } else {
            console.error("Unexpected status code:", res.status, res.data)
          }
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Error:", error)
          if (
            error.response.status === 401 ||
            error.response.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else if (error.response?.status === 403) {
            setErrorMessage(error.response.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
          } else {
            setErrorMessage(error.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            console.log(error)
          }
        })
    },
  })
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "white",
      backgroundColor: state.isFocused ? "#EBEDF0" : "white",
      color: state.isSelected ? "gray" : "black",
    }),
    menu: provided => ({
      ...provided,
      zIndex: 10000,
      height: "1rem",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: "10rem",
      overflowY: "auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }),
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  const handleModalClosed = () => {
    resetForm()
    setSelectedRole("")
    setSelectedTeam("")
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={handleModalClosed}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>Add New User</ModalHeader>
      <ModalBody>
        {errorMessage && (
          <div className="" aria-live="polite" aria-atomic="true">
            <div className="">
              <Toast isOpen={errorMessage} fade={true}>
                <ToastBody className="delete-message-bg">
                  <h5 className="m-0 mb-2">Error</h5>
                  <p className="m-0">{errorMessage}</p>
                </ToastBody>
              </Toast>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">First Name</Label>
                <Input
                  className="m-0 "
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   placeholder="FirstName"
                  type="text"
                />
                {errors.firstName && touched.firstName && (
                  <div className="error mb-0">{errors.firstName}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">Last Name</Label>
                <Input
                  className="m-0"
                  id="lastName"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  //   placeholder="LastName"
                  type="text"
                />
                {errors.lastName && touched.lastName && (
                  <div className="error mb-0">{errors.lastName}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="m-0 m-mb-2">
            <Label className="mb-1 font-size-15">Email</Label>
            <Input
              className="m-0"
              id="email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              // placeholder="Email"
              type="email"
            />
            {errors.email && touched.email && (
              <div className="error mb-0">{errors.email}</div>
            )}
          </FormGroup>
          <FormGroup className="m-0 m-mb-2 phone-add">
            <Label className="mb-1 font-size-15">WhatsApp Number</Label>
            <Row>
              <PhoneInput
                value={values.phone?.replace("+", "")}
                onChange={phone =>
                  setFieldValue("phone", phone?.replace("+", ""))
                }
                onBlur={handleBlur}
                id="phone"
                name="phone"
                country="sa"
                inputProps={{
                  value: values.phone ? values.phone?.replace("+", "") : "",
                }}
                enableSearch
                placeholder=""
              />
            </Row>

            {errors.phone && touched.phone && (
              <div className="error mb-0">{errors.phone}</div>
            )}
          </FormGroup>
          <Row>
            <Row>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">Password</Label>
                <Input
                  className="m-0"
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                />
                {errors.password && touched.password && (
                  <div className="error mb-0">{errors.password}</div>
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">Confirm Password</Label>
                <Input
                  className="m-0"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.passwordConfirm}
                />
                {errors.passwordConfirm && touched.passwordConfirm && (
                  <div className="error mb-0">{errors.passwordConfirm}</div>
                )}
              </FormGroup>
            </Row>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">Team</Label>
                <Select
                  className="m-0"
                  id="user-team"
                  name="team"
                  value={selectedTeam}
                  onChange={handleTeamChange}
                  options={teams}
                  styles={customStyles}
                ></Select>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">User Role</Label>
                <Select
                  className="m-0"
                  id="user-role"
                  name="role"
                  value={selectedRole}
                  options={roleOptions}
                  onChange={handleRoleChange}
                  styles={customStyles}
                ></Select>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">Ticket Requests</Label>
                <Select
                  isMulti
                  className="m-0"
                  id="ticketRequests"
                  name="ticketRequests"
                  value={selectedTicket}
                  options={ticketRequestsOptions}
                  onChange={handleTicketChange}
                  styles={customStyles}
                ></Select>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup className="m-0 m-mb-2">
                <Label className="mb-1 font-size-15">Tasks</Label>
                <Select
                  isMulti
                  className="m-0"
                  id="tasks"
                  name="tasks"
                  value={selectedTasks}
                  options={tasksOptions}
                  onChange={handleTasksChange}
                  styles={customStyles}
                ></Select>
              </FormGroup>
              {errors.tasks && touched.tasks && (
                <div className="error mb-0">{errors.tasks}</div>
              )}
            </Col>
          </Row>

          {isLoading && (
            <div className="loading-overlay">
              <Spinner />
            </div>
          )}
          <FormGroup className=" d-flex justify-content-end gap-3 mt-2">
            <Button
              type="submit"
              color="secondary"
              className="py-1 px-4 font-size-16 rounded-2"
            >
              Save
            </Button>
            <Button
              onClick={toggle}
              color="outline-secondary"
              className="py-1 px-4 font-size-16 rounded-2"
            >
              Cancel
            </Button>
          </FormGroup>
        </form>
      </ModalBody>
    </Modal>
  )
}
