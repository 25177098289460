import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import DefaultPhoto from "../../../../assets/images/default.jpg"
import { useSelector } from "react-redux"
import { formatTimestamp } from "helpers/helper_functions"
import configs from "config"
const { Url, apiVersion } = configs.client

function SingleComment({ comment }) {
  const { userData } = useSelector(state => ({
    userData: state.Login.userData,
  }))

  const [showModal, setShowModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageClick = file => {
    setSelectedImage(`${Url}/${file.file}`)
    setShowModal(true)
  }

  const renderAttachments = () => {
    if (comment.attachments && comment.attachments.length > 0) {
      if (comment.attachments.length === 1) {
        const file = comment.attachments[0]
        return renderSingleAttachment(file)
      } else {
        return renderMultipleAttachments(comment.attachments)
      }
    }
    return null
  }

  const getFileTypeIcon = filename => {
    if (filename.endsWith(".pdf")) return "mdi-file-pdf-box"
    if (filename.endsWith(".xlsx") || filename.endsWith(".xls"))
      return "mdi-file-excel-box"
    if (filename.endsWith(".docx") || filename.endsWith(".doc"))
      return "mdi-file-word-box"
    return "mdi-file-document-box"
  }

  const getFileType = filename => {
    if (filename.endsWith(".pdf")) return "PDF"
    if (filename.endsWith(".xlsx") || filename.endsWith(".xls")) return "Excel"
    if (filename.endsWith(".docx") || filename.endsWith(".doc")) return "Word"
    return "Document"
  }

  const renderSingleAttachment = file => {
    if (isImage(file.filename)) {
      return (
        <div
          className="d-flex align-items-center p-3"
          style={{ backgroundColor: "#E5EAF2", borderRadius: "10px" }}
        >
          <img
            src={`${Url}/${file.file}`}
            alt={file.filename}
            style={{
              width: "200px",
              height: "auto",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => handleImageClick(file)}
          />
        </div>
      )
    } else {
      const fileTypeIcon = getFileTypeIcon(file.filename)

      return (
        <div
          className="d-flex align-items-center p-3"
          style={{ backgroundColor: "#E5EAF2", borderRadius: "10px" }}
        >
          <div className="me-3">
            <i
              className={`mdi ${fileTypeIcon} mdi-36px`}
              style={{ color: "#107C41" }}
            />
          </div>
          <div className="flex-grow-1">
            <p
              className="mb-0"
              style={{ fontWeight: "bold", color: "#202020" }}
            >
              {file.filename}
            </p>
            <p className="mb-0 text-muted" style={{ fontSize: "12px" }}>
              {getFileType(file.filename)}
            </p>
          </div>
          <div>
            <a
              href={`${Url}/${file.file}`}
              target="_blank"
              rel="noreferrer"
              title="Download this file"
              className="text-decoration-none text-secondary"
            >
              <i className="mdi mdi-download mdi-24px" />
            </a>
          </div>
        </div>
      )
    }
  }

  const renderMultipleAttachments = files => {
    return (
      <div
        className="d-flex flex-wrap p-2"
        style={{
          backgroundColor: "#F2F2F7",
          borderRadius: "10px",
        }}
      >
        {files.map((file, index) => (
          <div
            key={index}
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              width: "50px",
              height: "50px",
              margin: "5px",
              padding: "5px",
              backgroundColor: "#FFF",
              borderRadius: "5px",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          >
            {isImage(file.filename) ? (
              <img
                src={`${Url}/${file.file}`}
                alt={file.filename}
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "2px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(file)}
              />
            ) : (
              <a
                href={`${Url}/${file.file}`}
                rel="noreferrer"
                target="_blank"
                title={file.filename}
                className="text-decoration-none text-secondary d-flex flex-column align-items-center"
              >
                <i
                  className={`mdi ${getFileTypeIcon(file.filename)} mdi-24px`}
                />
                <small style={{ fontSize: "10px", textAlign: "center" }}>
                  {getFileType(file.filename)}
                </small>
              </a>
            )}
          </div>
        ))}
      </div>
    )
  }

  const isImage = filename => {
    return /\.(jpg|jpeg|png|gif)$/.test(filename)
  }

  return (
    <>
      <div
        className={`d-flex align-items-start ${
          userData?._id === comment.user?._id ? "justify-content-end" : ""
        } mb-3`}
      >
        {userData?._id !== comment.user?._id && (
          <img
            src={DefaultPhoto}
            alt="Avatar"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
        )}

        <div style={{ width: "60%" }}>
          <div
            className="chat-bubble"
            style={{
              backgroundColor:
                comment.type === "note" ? "rgb(255 221 164)" : "#F2F2F7",
              color: "#202020",
              padding: "10px",
              borderRadius:
                userData?._id === comment.user?._id
                  ? "10px 0 10px 10px"
                  : "0 10px 10px 10px",
              width: "100%",
            }}
          >
            {renderAttachments()}
            <p className="mb-0 mt-2">{comment?.text}</p>
          </div>
          <div
            className="d-flex justify-content-between align-items-center mt-1"
            style={{
              width: "60%",
              marginLeft: userData?._id === comment.user?._id ? "auto" : "0",
              marginRight: userData?._id !== comment.user?._id ? "auto" : "0",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                marginBottom: "0",
                marginRight: "10px",
              }}
            >
              {comment.type === "user"
                ? "Client"
                : `${comment?.user?.firstName} ${comment?.user?.lastName}`}
            </p>

            <small
              style={{
                float: "right",
                fontWeight: "normal",
                color: "#999",
              }}
            >
              {formatTimestamp(comment.updatedAt)}
            </small>
          </div>
        </div>

        {userData?._id === comment.user?._id && (
          <img
            src={DefaultPhoto}
            alt="Avatar"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
          />
        )}
      </div>

      {/* Modal for showing full-sized image */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Body className="text-center">
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", height: "auto", borderRadius: "10px" }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SingleComment
