import React, { useState, useRef, useEffect } from "react"
import {
  AttachmentChatIcon,
  CloseSuggestedAnswers,
  SelectEmojiChatIcon,
  SendAudioFileIcon,
  SendContactIcon,
  SendDocFileIcon,
  SendImgFileIcon,
  SendTemplateIcon,
  SendVideoFileIcon,
  SuggestedAnswersChatIcon,
} from "components/Common/Icons/Chat/Conversation/ConversationIcons"
import axios from "axios"
import SendButtonIcon from "components/Common/Icons/Chat/Conversation/SendButtonIcon"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Dropdown,
  UncontrolledAccordion,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import { useSelector } from "react-redux"

import configs from "config"
import CustomSpinner from "components/Common/CustomSpinner"
import { chunkArray } from "helpers/helper_functions"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
const { Url, apiVersion } = configs.client

function CreateMessage({
  onSession,
  isChatOwner,
  messageText,
  setMessageText,
  sendFilesPopup,
  setSendFilesPopup,
  handleFileChange,
  sendNewMessage,
  selectedVoice,
  fireShowingTemplates,
  toggleShowContacts,
  showContactsSelector,
  isChatArchived,
  lastSession,
}) {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const mostUsedEmojis = [
    "😂",
    "❤️",
    "🥺",
    "🤣",
    "😍",
    "😊",
    "🙏",
    "😘",
    "🥰",
    "😢",
    "👍",
    "😭",
    "💕",
    "👏",
    "🤔",
    "🙌",
    "😎",
    "👌",
    "💪",
    "😉",
    "🎉",
    "😅",
    "🖤",
    "💔",
    "😋",
    "💖",
    "🤗",
    "😔",
    "😄",
    "🤩",
    "😆",
    "✨",
    "😌",
    "💞",
    "😁",
    "😳",
    "🎂",
    "😇",
    "😜",
    "😱",
    "🤤",
    "😡",
    "👀",
    "💗",
    "😋",
    "😏",
    "👊",
    "🔥",
    "🌹",
    "🎶",
    "🌟",
    "💐",
    "💤",
    "💙",
    "💚",
    "💛",
    "💜",
    "😒",
    "😴",
    "🤨",
    "🤯",
    "🤐",
    "🤓",
    "🥳",
    "😷",
    "🤡",
    "👑",
    "🥇",
    "🎁",
    "🎈",
    "⚽",
    "🏀",
    "🏈",
    "⚾",
    "🏐",
    "🎾",
    "🎳",
    "🎮",
    "🎲",
    "🎻",
    "🎸",
    "🥁",
    "🎬",
    "🎤",
    "🎧",
    "🎷",
    "🎹",
    "📝",
    "📚",
    "📅",
    "📈",
    "📉",
    "📊",
    "📆",
    "📋",
    "📌",
    "📍",
    "📎",
    "📐",
    "📏",
    "📑",
    "📓",
    "📔",
    "📕",
    "📖",
    "📗",
    "📘",
    "📙",
    "📚",
    "🌍",
    "🌎",
    "🌏",
    "🌐",
    "🗣",
    "👤",
    "👥",
    "👩‍💻",
    "👨‍💻",
    "💼",
    "📇",
  ]
  const emojiRows = chunkArray(mostUsedEmojis, 6)
  const handleClickOnEmoji = emoji => {
    setMessageText(curMsg => curMsg + emoji)
  }

  const inputRef = useRef(null)
  const dispatch = useDispatch()

  const [suggestedAnswersSets, setSuggestedAnswersSets] = useState([])
  const [suggestedAnswers, setSuggestedAnswers] = useState(false)
  const [suggestedAnswersLoading, setSuggestedAnswersLoading] = useState(true)
  const [addEmoji, setAddEmoji] = useState(false)

  const toggleAddEmoji = () => {
    onSession && setAddEmoji(!addEmoji)
  }

  const toggleSuggestedAnswers = () => setSuggestedAnswers(!suggestedAnswers)

  const fireFetchingSuggestedAnswers = () => {
    !suggestedAnswers && getSuggestedAnswers()
    toggleSuggestedAnswers()
  }

  const getSuggestedAnswers = () => {
    axios
      .get(`${Url}/${apiVersion}/answers-sets?type=chatting`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setSuggestedAnswersSets(res.data.data.answersSets)
        setSuggestedAnswersLoading(false)
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }

        setSuggestedAnswersLoading(false)
      })
  }

  const clickOnSuggestedAnswer = answer => {
    setMessageText(answer.body)
    inputRef.current.focus()
    toggleSuggestedAnswers()
  }

  const toggleSendAttachment = () => {
    if (isChatArchived) {
      setSendFilesPopup(!sendFilesPopup)
    } else {
      if (onSession) {
        setSendFilesPopup(!sendFilesPopup)
      } else {
        !showContactsSelector && setSendFilesPopup(!sendFilesPopup)
      }
    }
  }

  // useEffect(() => {
  //   console.log("isChatOnwer", isChatOwner)
  //   console.log("isChatArchived", isChatArchived)
  // }, [isChatOwner, isChatArchived])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="d-flex justify-content-between align-items-center create-message position-relative">
      <div
        className="suggested-answers"
        style={{ display: suggestedAnswers ? "block" : "none" }}
      >
        <div className="d-flex justify-content-between mb-0 py-3 px-4 suggested-answers-header">
          <h3 className="mb-0">Suggested Answers</h3>

          <span
            className="icon-container"
            onClick={() => toggleSuggestedAnswers()}
          >
            <CloseSuggestedAnswers />
          </span>
        </div>

        <div className="suggested-answers-body">
          {suggestedAnswersLoading ? (
            <div className="d-flex align-items-center h-100">
              <CustomSpinner />
            </div>
          ) : (
            <UncontrolledAccordion defaultOpen="0">
              {suggestedAnswersSets.map((suggestedAnswer, idx) => (
                <AccordionItem key={suggestedAnswer._id}>
                  <AccordionHeader targetId={`${idx}`}>
                    {suggestedAnswer.name}
                  </AccordionHeader>
                  <AccordionBody accordionId={`${idx}`}>
                    {suggestedAnswer.answers.map(answer => {
                      return (
                        <div
                          className="mb-1 suggested-answer-item"
                          key={answer._id}
                          onClick={() => clickOnSuggestedAnswer(answer)}
                        >
                          <h6 className="mb-0">{answer.name}</h6>
                          <p className="mb-0">{answer.body}</p>
                        </div>
                      )
                    })}
                  </AccordionBody>
                </AccordionItem>
              ))}
            </UncontrolledAccordion>
          )}
        </div>
      </div>

      {!isChatOwner && !isChatArchived ? (
        <div className="d-flex justify-content-center w-100 text-center">
          <p className="mb-0">
            Only the owner of this conversation can communicate with the client.
          </p>
        </div>
      ) : (
        <>
          <Dropdown
            isOpen={sendFilesPopup}
            toggle={toggleSendAttachment}
            className="position-relative"
          >
            <DropdownToggle
              caret
              className={`icon-container send-attachemnt-toggler ${
                isChatArchived
                  ? ""
                  : !onSession ||
                    (showContactsSelector && "not-allowed-to-send")
              }`}
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            >
              <AttachmentChatIcon />
            </DropdownToggle>

            {sendFilesPopup && (
              <DropdownMenu className="send-messages-popup">
                <label
                  id="docInput"
                  className="d-flex flex-column gap-2 align-items-center justify-content-center mb-0 icon-container"
                  style={{ cursor: !onSession ? "not-allowed" : "pointer" }}
                >
                  {onSession && (
                    <input
                      type="file"
                      id="docInput"
                      className="d-none"
                      onChange={() => {
                        handleFileChange(event, "document")
                        setSendFilesPopup(false)
                      }}
                      accept=".pdf,.doc,.docx,.txt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/csv,text/csv,application/vnd.ms-excel"
                      multiple
                    />
                  )}

                  <span className="d-flex align-items-center justify-content-center icon">
                    <SendDocFileIcon />
                  </span>

                  <span>Document</span>
                </label>

                <label
                  id="videoInput"
                  className="d-flex flex-column gap-2 align-items-center justify-content-center mb-0 icon-container"
                  style={{ cursor: !onSession ? "not-allowed" : "pointer" }}
                >
                  {onSession && (
                    <input
                      type="file"
                      id="videoInput"
                      className="d-none"
                      onChange={() => {
                        handleFileChange(event, "video")
                        setSendFilesPopup(false)
                      }}
                      accept="video/*"
                    />
                  )}

                  <span className="d-flex align-items-center justify-content-center icon">
                    <SendVideoFileIcon />
                  </span>

                  <span>Video</span>
                </label>

                <label
                  className="d-flex flex-column gap-2 align-items-center justify-content-center mb-0 icon-container"
                  onClick={() => {
                    fireShowingTemplates()
                    setSendFilesPopup(false)
                  }}
                >
                  <span className="d-flex align-items-center justify-content-center icon">
                    <SendTemplateIcon />
                  </span>

                  <span>Template</span>
                </label>

                <label
                  id="imageInput"
                  className="d-flex flex-column gap-2 align-items-center justify-content-center mb-0 icon-container"
                  style={{ cursor: !onSession ? "not-allowed" : "pointer" }}
                >
                  {onSession && (
                    <input
                      type="file"
                      id="imageInput"
                      className="file-input"
                      onChange={() => {
                        handleFileChange(event, "image")
                        setSendFilesPopup(false)
                      }}
                      accept="image/*"
                      multiple
                    />
                  )}

                  <span className="d-flex align-items-center justify-content-center icon">
                    <SendImgFileIcon />
                  </span>

                  <span>Image</span>
                </label>

                <label
                  id="voiceInput"
                  className="d-flex flex-column gap-2 align-items-center justify-content-center mb-0 icon-container"
                  style={{ cursor: !onSession ? "not-allowed" : "pointer" }}
                >
                  {onSession && (
                    <input
                      type="file"
                      id="voiceInput"
                      className="d-none"
                      onChange={() => {
                        handleFileChange(event, "voice")
                        setSendFilesPopup(false)
                      }}
                      accept=".mp3,.wav,.ogg,.flac"
                    />
                  )}

                  <span className="d-flex align-items-center justify-content-center icon">
                    <SendAudioFileIcon />
                  </span>

                  <span>Audio</span>
                </label>

                <label
                  className="d-flex flex-column gap-2 mb-0 align-items-center justify-content-center icon-container"
                  style={{ cursor: !onSession ? "not-allowed" : "pointer" }}
                  onClick={() => {
                    if (onSession) {
                      setSendFilesPopup(false)
                      toggleShowContacts()
                    }
                  }}
                >
                  <span className="d-flex align-items-center justify-content-center icon">
                    <SendContactIcon />
                  </span>

                  <span>Contact</span>
                </label>
              </DropdownMenu>
            )}
          </Dropdown>

          <Dropdown isOpen={addEmoji} toggle={toggleAddEmoji} direction="up">
            <DropdownToggle
              caret
              className={`icon-container ${
                (!onSession || showContactsSelector) && "not-allowed-to-send"
              }`}
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            >
              <SelectEmojiChatIcon />
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "12rem",
                padding: "0.5rem",
                overflow: "auto",
                borderRadius: "0.625rem",
              }}
            >
              {emojiRows.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.25rem",
                  }}
                >
                  {row.map((emoji, emojiIndex) => (
                    <span
                      onClick={() => handleClickOnEmoji(emoji)}
                      key={emojiIndex}
                      style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    >
                      {emoji}
                    </span>
                  ))}
                </div>
              ))}
            </DropdownMenu>
          </Dropdown>

          <div className="position-relative d-flex align-items-center flex-grow-1">
            <textarea
              value={messageText}
              disabled={!onSession || selectedVoice || showContactsSelector}
              onChange={e => setMessageText(e.target.value)}
              className={`w-100 ${lastSession?.secret ? "secretMSG" : ""}`}
              placeholder={
                !onSession ? `You need to send a template` : `Type a message...`
              }
              rows={1}
              ref={inputRef}
            ></textarea>
            <div
              className={`suggested-answers-icon-container ${
                !onSession && "not-allowed-to-send"
              }`}
              onClick={() => {
                onSession && fireFetchingSuggestedAnswers()
              }}
            >
              <SuggestedAnswersChatIcon />
            </div>
          </div>

          <div
            className={`d-flex justify-content-center align-items-center send-btn ${
              (!onSession || showContactsSelector) && "not-allowed-to-send"
            }`}
            onClick={() => {
              onSession && sendNewMessage()
            }}
          >
            <SendButtonIcon />
          </div>
        </>
      )}
    </div>
  )
}

export default CreateMessage
