import React from "react"
import ProjectDetails from "pages/Chat/ProjectInfo/ProjectDetails"

function TicketProjectInfo() {
  return (
    <div
      style={{
        height: "calc(-6px + 77vh)",
        maxHeight: "calc(-6px + 77vh)",
        overflow: "auto",
        padding: "16px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <div className="d-flex flex-column flex-grow-1 pt-1 tab-content-notes project-info bg-white">
        <ProjectDetails sessionIndicator={true} />
      </div>
    </div>
  )
}

export default TicketProjectInfo
