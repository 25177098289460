import React from "react"
import "./ReplyMessageComponent.css"
import { filterMessageType } from "helpers/helper_functions"

import configs from "config"
const { Url, apiVersion, businessNumber } = configs.client

function ReplyMessageComponent({ message }) {
  return (
    <div
      className={`replied-message ${
        message.from !== businessNumber && "users-replyed-message"
      }`}
      style={message.from === businessNumber ? { color: "black" } : {}}
    >
      <div
        className="sender-name"
        style={message.from === businessNumber ? { color: "#717171" } : {}}
      >
        {message.reply?.from !== businessNumber
          ? ""
          : message.reply
          ? `${message.reply?.user?.firstName} ${message.reply?.user?.lastName}`
          : `${message.user?.firstName} ${message.user?.lastName}`}
      </div>
      <div className={``}>{filterMessageType(message.reply)}</div>
      {/* <div className={`message-text`}>{filterMessageType(message.reply)}</div> */}
    </div>
  )
}

export default ReplyMessageComponent
