import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import { Toast, ToastBody, ToastHeader } from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"

export default function EditConversation({
  setShowEditToast,
  fetchAllConversationsData,
  conversation,
  toggle,
  isOpen,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const dispatch = useDispatch()

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const AddConversationSchema = Yup.object().shape({
    name: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    description: Yup.string().matches(/^(?!\s+$).*/, "invalid"),
    bodyOn: Yup.string()
      .required(" required")
      .matches(/^(?!\s+$).*/, "invalid"),
    bodyOff: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
  })

  useEffect(() => {
    setFieldValue("name", conversation?.name)
    setFieldValue("description", conversation?.description)
    setFieldValue("bodyOn", conversation?.bodyOn)
    setFieldValue("bodyOff", conversation?.bodyOff)
  }, [conversation])

  // Formik
  const {
    handleChange,
    values,
    values: { Signature },
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: conversation?.name || "",
      description: conversation?.description || "",
      bodyOn: conversation?.bodyOn || "",
      bodyOff: conversation?.bodyOff || "",
    },
    validationSchema: AddConversationSchema,
    onSubmit: values => {
      setIsLoading(true)
      axios
        .patch(
          `${Url}/${apiVersion}/conversations/${conversation._id}`,
          values,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              Authorization: `Bearer ${access}`,
            },
          }
        )
        .then(res => {
          toggle()
          setIsLoading(false)
          setShowEditToast(true)
          setTimeout(() => setShowEditToast(false), 2500)
          fetchAllConversationsData()
          resetForm()
        })
        .catch(error => {
          setIsLoading(false)
          if (
            error.response.status === 401 ||
            error.response.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())

            setErrorMessage(error.response.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            setIsLoading(false)
          } else {
            setErrorMessage(error?.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            setIsLoading(false)
          }
        })
    },
  })

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Edit Conversation </ModalHeader>
        <ModalBody>
          {errorMessage && (
            <div className="" aria-live="polite" aria-atomic="true">
              <div className="">
                <Toast isOpen={errorMessage} fade={true}>
                  <ToastBody className="delete-message-bg">
                    <h5 className="m-0 mb-2">Error</h5>
                    <p className="m-0">{errorMessage}</p>
                  </ToastBody>
                </Toast>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Conversation Name</Label>
              <Input
                className=" p-5px m-0"
                id="team-name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                // placeholder="Email"
                type="text"
              />
              {errors.name &&
                touched.name &&
                // !values?.name &&
                values?.name?.trim() === "" && (
                  <div className="error mb-0">{errors.name}</div>
                )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Conversation Description</Label>
              <Input
                className=" p-5px m-0"
                id="Conv-description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                type="text"
              />
              {errors.description &&
                touched.description &&
                values?.description?.trim() === "" && (
                  <div className="error mb-0">{errors.description}</div>
                )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Inside Business Hour </Label>
              <Input
                className=" p-5px m-0"
                id="bodyOn"
                name="bodyOn"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bodyOn}
                type="text"
              />
              {errors.bodyOn &&
                touched.bodyOn &&
                values?.bodyOn?.trim() === "" && (
                  <div className="error mb-0">{errors.bodyOn}</div>
                )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Outside Business Hour </Label>
              <Input
                className=" p-5px m-0"
                id="bodyOff"
                name="bodyOff"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bodyOff}
                // placeholder="Email"
                type="text"
              />
              {errors.bodyOff &&
                touched.bodyOff &&
                values?.bodyOff?.trim() === "" && (
                  <div className="error mb-0">{errors.bodyOff}</div>
                )}
            </FormGroup>

            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}

            <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
              <Button type="submit" className="edit-button">
                Edit
              </Button>{" "}
              <Button onClick={toggle} className="edit-button cancel-delete ">
                Cancel
              </Button>
            </FormGroup>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}
