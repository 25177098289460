import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Badge,
  Spinner,
} from "reactstrap"
import InfiniteScroll from "react-infinite-scroll-component"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  setTeamMemberUserId,
  unAuthUser,
  updateSelectedTeams,
} from "store/actions"
import CustomSpinner from "components/Common/CustomSpinner"
import configs from "config"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import TicketsTeamMembers from "./TicketHome/TicketsTeamMembers"
import AdminTeamsDropdownContainer from "pages/Home/PerformanceFiltersTeamMemeber/AdminTeamsDropdownContainer"
import "./TicketHome/TeamMembersStyle.css"
import "./TicketForms/TicketFormsList.css"
import { isUserSupervisor } from "helpers/helper_functions"
import socket from "socket"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const { Url, apiVersion } = configs.client

function TicketHome() {
  const { access, authorized, selectedTeams, selectedTeamMember, userData } =
    useSelector(state => ({
      access: state.Login.userAccess,
      authorized: state.Login.authorized,
      selectedTeams: state.Login.selectedTeams,
      selectedTeamMember: state.Login.selectedTeamMember,
      userData: state.Login.userData,
    }))

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedFilters, setSelectedFilters] = useState(["all"])
  const [isTeamFilters, setIsTeamFilters] = useState(false)
  const [ticketsFilterNumbers, setTicketsFilterNumbers] = useState(null)
  const [page, setPage] = useState(2)
  const [loadingFiltersNumbers, setLoadingFiltersNumbers] = useState(true)
  const [noTicketsFound, setNoTicketsFound] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [teams, setTeams] = useState([])
  const [defaultTeamId, setDefaultTeamId] = useState("")
  const [isUserDefaultTeamSupervisor, setIsUserDefaultTeamSupervisor] =
    useState(false)

  const handleAddNew = () => {
    navigate("/ticket/new")
  }

  const fetchFiltersNumbers = () => {
    axios
      .get(`${Url}/${apiVersion}/ticket-filters/filters/${selectedTeams}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setTicketsFilterNumbers(res.data.data)
        setLoadingFiltersNumbers(false)
      })
      .catch(err => {
        if (
          err?.response?.status == 401 ||
          err?.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to load filter numbers. Please try again.")
        }
        setLoadingFiltersNumbers(false)
      })
  }

  const generateTicketsUrl = () => {
    let url = `${Url}/${apiVersion}/ticket-filters/tickets/`

    if (selectedTeamMember) {
      // If a team member is selected, use the API for fetching team member tickets
      url += `team-users/${selectedTeamMember}?page=${page}`
    } else if (isTeamFilters) {
      // Handle team filter URL
      const status =
        selectedFilters.length > 0 ? selectedFilters.join(",") : "all"
      url += `teams/${selectedTeams}?status=${status}&page=${page}`
    } else {
      // Handle user filter URL
      const status =
        selectedFilters.length > 0 ? selectedFilters.join(",") : "all"
      url += `user?status=${status}&page=${page}`
    }

    return url
  }

  // Fetch tickets data
  const handleFilterClick = (filter, isTeamFilter) => {
    setPage(2)
    dispatch(setTeamMemberUserId(""))
    // If switching between User and Team filters, reset the filters accordingly
    if (isTeamFilter !== isTeamFilters) {
      setIsTeamFilters(isTeamFilter)
      setSelectedFilters([filter]) // Apply the selected filter and reset others
      return
    }

    // If "all" is clicked, reset all other filters
    if (filter === "all") {
      setSelectedFilters(["all"]) // Reset to "all" filter
      return
    }

    // If "all" is in the array, remove it when any other filter is clicked
    setSelectedFilters(prevFilters => {
      // Remove "all" if it's currently selected
      if (prevFilters.includes("all")) {
        return [filter]
      }

      // Toggle the selected filter (add/remove)
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(item => item !== filter)
      } else {
        return [...prevFilters, filter]
      }
    })
  }

  const handleTicketClick = id => {
    const ticketUrl = `/ticket/list/${id}`
    window.open(ticketUrl, "_blank")
  }

  const fetchTeams = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setTeams(res.data.data.teams)
        setDefaultTeamId(res.data.data.defaultTeam._id)
      })
      .catch(err => {
        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to fetch teams. Please try again.")
        }
      })
  }

  const fetchTickets = () => {
    const url = generateTicketsUrl()

    // Set loading state true before the request
    page <= 2 && setLoading(true)

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        // Just replace the tickets with the response since it contains all previous pages' data
        setTickets(res.data.data.tickets)

        setTotalPages(res.data.data.totalPages)

        if (page < res.data.data.totalPages) {
          setPage(prevPage => prevPage + 1) // Increase the page number
        }

        // Check if no tickets were returned
        if (res.data.data.tickets.length === 0) {
          setNoTicketsFound(true)
        } else {
          setNoTicketsFound(false)
        }

        setLoading(false)
      })
      .catch(err => {
        setLoading(false)

        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.error(err)
          toast.error("Failed to load tickets. Please try again.")
        }
      })
  }

  useEffect(() => {
    fetchTeams()
    setIsUserDefaultTeamSupervisor(
      isUserSupervisor(userData, teams, defaultTeamId)
    )
  }, [])

  useEffect(() => {
    fetchTickets()
    selectedTeams && fetchFiltersNumbers()
  }, [
    selectedFilters,
    isTeamFilters,
    selectedTeams,
    selectedTeamMember,
    isUserDefaultTeamSupervisor,
  ])

  // clear the selected filter if there's a team member is selected
  useEffect(() => {
    if (selectedTeamMember) {
      setSelectedFilters([])
      setIsTeamFilters(false)
    }
  }, [selectedTeamMember])

  useEffect(() => {
    if (socket && selectedTeams) {
      const handleUpdating = () => {
        socket.emit("client_to_server", {
          type: "ticketFilters",
          teamsIDs: selectedTeams.split(","),
        })
      }

      const handleServerToClient = response => {
        if (response.teamTicketsfilters || response.userTicketsfilters) {
          setTicketsFilterNumbers(response)
        }
      }

      socket.on("updatingTickets", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      // Cleanup function to remove listeners on component unmount or dependencies change
      return () => {
        socket.off("updatingTickets", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [selectedTeams, socket])

  useEffect(() => {
    if (socket) {
      const handleUpdating = () => {
        let data = {}

        if (selectedTeamMember) {
          // Case where a specific team member is selected
          data = {
            type: "teamUsersTickets",
            page,
            teamUserID: selectedTeamMember,
          }
        } else {
          // Case for userTickets or teamTickets
          const status =
            selectedFilters.length > 0 ? selectedFilters.join(",") : "all"
          data = {
            type: isTeamFilters ? "teamTickets" : "userTickets",
            page,
            status,
          }

          // If it's a team filter, add the team IDs as an array
          if (isTeamFilters) {
            data.teamsIDs = selectedTeams.split(",") // Convert team IDs to an array
          }
        }

        socket.emit("client_to_server", data)
      }

      const handleServerToClient = response => {
        response.tickets && setTickets(response.tickets)
        response.totalPages && setTotalPages(response.totalPages)

        // Check if no tickets were returned
        if (response.tickets?.length === 0) {
          setNoTicketsFound(true)
        } else {
          setNoTicketsFound(false)
        }
      }

      socket.on("updatingTickets", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      return () => {
        socket.off("updatingTickets", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [
    socket,
    isTeamFilters,
    selectedFilters,
    selectedTeams,
    selectedTeamMember,
    page,
  ])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="px-1">
      <Container fluid className="px-2 pt-0 ps-0 position-relative">
        <Row className="gx-3">
          <Col
            md="8"
            style={{
              height: "calc(100vh - 5rem)",
              maxHeight: "calc(100vh - 5rem)",
              overflow: "auto",
              backgroundColor: "#ebedf0",
            }}
            className="pt-4 px-5"
            id="tikcetsScrollTBody"
          >
            <InfiniteScroll
              dataLength={tickets?.length}
              next={fetchTickets}
              // next={fetchMoreTickets} // to be created
              hasMore={page <= totalPages}
              loader={
                page < totalPages && !loading ? (
                  <h4 className="text-center">Loading...</h4>
                ) : null
              }
              scrollableTarget="tikcetsScrollTBody"
            >
              <Card>
                <CardBody>
                  {loading ? (
                    <CustomSpinner />
                  ) : (
                    <Table bordered className="mt-3">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Ref No</th>
                          <th>Nature of Request</th>
                          <th>Request Type</th>
                          <th>Assignee</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {noTicketsFound ? (
                        <td
                          className="text-center py-4 font-size-16"
                          colSpan={6}
                        >
                          No tickets are found
                        </td>
                      ) : (
                        <tbody>
                          {tickets?.map((ticket, index) => (
                            <tr
                              onClick={() => handleTicketClick(ticket._id)}
                              style={{ cursor: "pointer" }}
                              className="hover-form-row"
                              key={ticket._id}
                            >
                              <td>{ticket.order}</td>
                              <td>{ticket.refNo}</td>
                              <td>{ticket.requestNature}</td>
                              <td>{ticket.requestType}</td>
                              <td>{`${ticket.assignee?.firstName} ${ticket.assignee?.lastName}`}</td>
                              <td>
                                {ticket.status?.category?.toLowerCase() ===
                                  "pending" && (
                                  <Badge
                                    pill
                                    className="badge-soft-warning ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    Pending
                                  </Badge>
                                )}

                                {ticket.status?.category?.toLowerCase() ===
                                  "solved" && (
                                  <Badge
                                    pill
                                    className="badge-soft-secondary ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    Solved
                                  </Badge>
                                )}

                                {ticket.status?.category?.toLowerCase() ===
                                  "new" && (
                                  <Badge
                                    pill
                                    className="badge-soft-danger ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    New
                                  </Badge>
                                )}

                                {ticket.status?.category?.toLowerCase() ===
                                  "open" && (
                                  <Badge
                                    pill
                                    className="badge-soft-success ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    Open
                                  </Badge>
                                )}

                                {(!ticket.status?.category ||
                                  (ticket.status?.category?.toLowerCase() !==
                                    "new" &&
                                    ticket.status?.category?.toLowerCase() !==
                                      "solved" &&
                                    ticket.status?.category?.toLowerCase() !==
                                      "open" &&
                                    ticket.status?.category?.toLowerCase() !==
                                      "pending")) && (
                                  <Badge
                                    pill
                                    className="badge-soft-primary ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    {ticket.status?.category || "Unknown"}
                                  </Badge>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </Table>
                  )}
                </CardBody>
              </Card>
            </InfiniteScroll>
          </Col>

          <Col
            md="4"
            style={{
              height: "calc(100vh - 5rem)",
              maxHeight: "calc(100vh - 5rem)",
              overflow: "auto",
            }}
            className="pt-3"
          >
            <Row>
              <Col md="10" className="offset-1">
                <div className="my-filters mb-4">
                  <div className="mb-3 d-flex justify-content-between align-items-center">
                    <h4 className="font-size-20 filter-heading">My Tickets</h4>

                    <Button
                      color="secondary"
                      className="py-1"
                      onClick={handleAddNew}
                    >
                      <i className="bx bx-plus"></i> Add Ticket
                    </Button>
                  </div>

                  <div>
                    <Row className="gx-4">
                      <Col md="12" className="mb-2">
                        <button
                          className={`btn w-100 mb-2 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("all") && !isTeamFilters
                              ? "btn-dark"
                              : "btn-outline-dark"
                          } rounded-4`}
                          onClick={() => handleFilterClick("all", false)}
                        >
                          <span>All tickets</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("all") && !isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-dark text-light"
                            } fitlers-number-all ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.userTicketsfilters?.all
                            )}
                          </span>
                        </button>
                      </Col>

                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("new") && !isTeamFilters
                              ? "btn-danger"
                              : "btn-outline-danger"
                          } rounded-4`}
                          onClick={() => handleFilterClick("new", false)}
                        >
                          <span>New</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("new") && !isTeamFilters
                                ? "bg-light text-danger"
                                : "border bg-danger text-light"
                            } fitlers-number-danger ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.userTicketsfilters?.new
                            )}
                          </span>
                        </button>
                      </Col>
                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("pending") &&
                            !isTeamFilters
                              ? "btn-warning"
                              : "btn-outline-warning"
                          } rounded-4`}
                          onClick={() => handleFilterClick("pending", false)}
                        >
                          <span>Pending</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("pending") &&
                              !isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-warning text-light"
                            } fitlers-number-warning ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.userTicketsfilters?.pending
                            )}
                          </span>
                        </button>
                      </Col>

                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("solved") && !isTeamFilters
                              ? "btn-secondary"
                              : "btn-outline-secondary"
                          } rounded-4`}
                          onClick={() => handleFilterClick("solved", false)}
                        >
                          <span>Solved</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("solved") &&
                              !isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-secondary text-light"
                            } fitlers-number-secondary ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.userTicketsfilters?.solved
                            )}
                          </span>
                        </button>
                      </Col>
                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("open") && !isTeamFilters
                              ? "btn-success"
                              : "btn-outline-success"
                          } rounded-4`}
                          onClick={() => handleFilterClick("open", false)}
                        >
                          <span>Open</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("open") && !isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-success text-light"
                            } fitlers-number-success ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.userTicketsfilters?.open
                            )}
                          </span>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="all-filters my-2">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="mb-0 font-size-20 filter-heading">
                      All Tickets
                    </h4>

                    <AdminTeamsDropdownContainer
                      overview="Team Overview"
                      isUserDefaultTeamSupervisor={isUserSupervisor(
                        userData,
                        teams,
                        defaultTeamId
                      )}
                    />
                  </div>

                  <div>
                    <Row className="gx-4">
                      <Col md="12" className="mb-2">
                        <button
                          className={`btn w-100 mb-2 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("all") && isTeamFilters
                              ? "btn-dark"
                              : "btn-outline-dark"
                          } rounded-4`}
                          onClick={() => handleFilterClick("all", true)}
                        >
                          <span>All tickets</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("all") && isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-dark text-light"
                            } fitlers-number-all ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.teamTicketsfilters?.all
                            )}
                          </span>
                        </button>
                      </Col>
                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("new") && isTeamFilters
                              ? "btn-danger"
                              : "btn-outline-danger"
                          } rounded-4`}
                          onClick={() => handleFilterClick("new", true)}
                        >
                          <span>New</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("new") && isTeamFilters
                                ? "bg-light text-danger"
                                : "border bg-danger text-light"
                            } fitlers-number-danger ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.teamTicketsfilters?.new
                            )}
                          </span>
                        </button>
                      </Col>
                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("pending") && isTeamFilters
                              ? "btn-warning"
                              : "btn-outline-warning"
                          } rounded-4`}
                          onClick={() => handleFilterClick("pending", true)}
                        >
                          <span>Pending</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("pending") &&
                              isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-warning text-light"
                            } fitlers-number-warning ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.teamTicketsfilters?.pending
                            )}
                          </span>
                        </button>
                      </Col>
                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("solved") && isTeamFilters
                              ? "btn-secondary"
                              : "btn-outline-secondary"
                          } rounded-4`}
                          onClick={() => handleFilterClick("solved", true)}
                        >
                          <span>Solved</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("solved") &&
                              isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-secondary text-light"
                            } fitlers-number-secondary ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.teamTicketsfilters?.solved
                            )}
                          </span>
                        </button>
                      </Col>
                      <Col md="6" className="mb-2">
                        <button
                          className={`btn w-100 d-flex justify-content-between align-items-center px-3 fitlers-number ${
                            selectedFilters.includes("open") && isTeamFilters
                              ? "btn-success"
                              : "btn-outline-success"
                          } rounded-4`}
                          onClick={() => handleFilterClick("open", true)}
                        >
                          <span>Open</span>
                          <span
                            className={`badge rounded-circle d-flex justify-content-center align-items-center ${
                              selectedFilters.includes("open") && isTeamFilters
                                ? "bg-light text-dark"
                                : "border bg-success text-light"
                            } fitlers-number-success ${
                              loadingFiltersNumbers && "loading"
                            }`}
                          >
                            {loadingFiltersNumbers ? (
                              <Spinner color="secondary" size="sm" type="grow">
                                Loading...
                              </Spinner>
                            ) : (
                              ticketsFilterNumbers?.teamTicketsfilters?.open
                            )}
                          </span>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            {userData.role === "admin" ? (
              <div className="p-0 mt-xl-2 team-members-container">
                <TicketsTeamMembers ticketFeature={true} />
              </div>
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TicketHome
