import React, { useEffect, useState } from "react"
import UploadProfilePhoto from "./ProfileComponents/UploadProfilePhoto"
import ChangeUserName from "./ProfileComponents/ChangeUserName"
import ChangeUserPassword from "./ProfileComponents/ChangeUserPassword"
import { useSelector } from "react-redux"
import { Row, Toast, ToastBody, ToastHeader, Col } from "reactstrap"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

export default function UserProfile() {
  let { access, authorized, userData } = useSelector(state => ({
    userData: state.Login.userData,
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)

  const dispatch = useDispatch()

  const [userDataProfile, setUserDataProfile] = useState([])
  const fetchTeamsData = () => {
    setIsLoading(true)
    axios
      .get(`${Url}/${apiVersion}/users/${userData._id}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setIsLoading(false)
        setUserDataProfile(res.data.data.user)
        // console.log("first , ", res.data.data.user)
      })
      .catch(error => {
        setIsLoading(false)

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 0 ||
          !error.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          setErrorMessage(error?.response?.data?.message)
          setTimeout(() => setErrorMessage(false), 3500)
          setIsLoading(false)
        }
      })
  }
  useEffect(() => {
    fetchTeamsData()
  }, [userData])

  const [photoView, setPhotoView] = useState()
  // console.log("userData", userData)

  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <div className=" user-setting-page  ">
      <div className="user-profile-page">
        {/* <h5>Porofile</h5> */}

        {/* success message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showSuccessToast} fade={true}>
              <ToastBody className="success-message-bg">
                <h5 className="m-0 mb-2">Success</h5>
                <p className="m-0">Profile Updated Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>
        {isLoading ? (
          <div className="  loading d-flex justify-content-center align-items-center p-5">
            <div className="spinner-border "></div>
          </div>
        ) : (
          <Row className="profile d-flex  justify-content-center gap-3">
            {/* <div className="profile w-75"> */}
            <Col md={12} lg={11} sm={12}>
              <div className=" profile-item p-3 mb-2">
                {/* <UploadProfilePhoto
                  userData={userDataProfile}
                  photoView={photoView}
                  setPhotoView={setPhotoView}
                /> */}

                {/* <div className="profile-item p-3"> */}
                <ChangeUserName
                  setUserDataProfile={setUserDataProfile}
                  setShowSuccessToast={setShowSuccessToast}
                  userData={userDataProfile}
                  photoView={photoView}
                  setPhotoView={setPhotoView}
                  fetchTeamsData={fetchTeamsData}
                />
              </div>
            </Col>

            {/* </div> */}
            <Col md={12} lg={11} sm={12}>
              <div className="profile-item p-3">
                <ChangeUserPassword setShowSuccessToast={setShowSuccessToast} />
              </div>
            </Col>

            {/* </div> */}
          </Row>
        )}
      </div>
    </div>
  )
}
