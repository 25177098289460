import React, { useEffect, useState } from "react"
import "./fileSteps.css"
import PerviousAction from "../PerviousAction"
import ActionOpation from "../ActionOpation"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import configs from "../../../config"
const { Url, apiVersion } = configs.client
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { unAuthUser } from "store/actions"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function ChoseFileTemp({
  nextStep,
  prevStep,
  selectedTemplate,
  setSelectedTemplate,
  templates,
  setTemplates,
  setSelectedTemplateData,
}) {
  let { access } = useSelector(state => ({
    access: state.Login?.userAccess,
  }))
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [issearching, setIssearching] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    if (searchTerm) {
      setIssearching(true)
      const timeoutId = setTimeout(() => {
        setIssearching(false)
      }, 1000)
      return () => clearTimeout(timeoutId)
    } else {
      setIssearching(false)
    }
  }, [searchTerm])

  const handleSelectTemplate = templateId => {
    setSelectedTemplate(templateId)
  }

  // GET all Template from api:
  useEffect(() => {
    const getTemplates = () => {
      axios
        .get(`${Url}/${apiVersion}/whatsapp-templates?status=approved `, {
          headers: { Authorization: `Bearer ${access}` },
        })
        .then(res => {
          const templateFromApi = res.data.data.templates.map(template => ({
            id: template.id,
            name: template.name,
            components: template.components,
            language: template.language,
            format: template.format,
            category: template.category,
          }))
          setTemplates(templateFromApi)
          setIsLoading(false)

          toast.success("Templates loaded successfully!")
        })
        .catch(error => {
          if (
            error?.response?.status == 401 ||
            error?.response?.status == 0 ||
            !error.response?.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)

            const errorMessage =
              error.response?.data?.message || "Failed to load templates."
            toast.error(errorMessage)
          }
        })
    }
    getTemplates()
  }, [])

  if (isLoading) {
    return (
      <div
        style={{
          height: "350px",
          overflowY: "auto",
        }}
        className=" p-4 gap-3 rounded shadow-sm d-flex justify-content-center align-items-center"
      >
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>{" "}
      </div>
    )
  }

  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div>
      <Row className="d-flex">
        <Col md={8}>
          <h3>Choose a Template</h3>
        </Col>
        <Col
          style={{
            padding: "2px 0px 2px 5px",
            border: "1px solid #2a304280",
          }}
          md={4}
          className="d-flex align-items-center rounded-2 gap-1 bg-white mb-3 text-left"
        >
          <input
            className="p-1 border-0 flex-grow-1"
            type="search"
            placeholder="search"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      {issearching && (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        style={{
          height: "350px",
          overflowY: "auto",
        }}
        className="d-flex flex-column p-4 gap-3 rounded shadow-sm"
      >
        {filteredTemplates?.length > 0 && !issearching ? (
          filteredTemplates?.map(template => (
            <Row
              style={{
                padding: "10px 20px",
                border: "1px solid #2a304280",
                transition: "all 0.2s",
              }}
              onClick={() => {
                setSelectedTemplateData(template)
                handleSelectTemplate(template.id)
              }}
              key={template.id}
              className="d-flex  justify-content-between align-items-center bg-white rounded-3 temp-name "
            >
              <Col
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  color: "#2a3042",
                  cursor: "pointer",
                }}
                className="font-weight-500 text-capitalize "
              >
                {template.name.replace(/_/g, " ")}
              </Col>
              {template.id === selectedTemplate ? (
                <Container
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                  className="m-0 p-0 position-relative rounded-circle border border-1 border-success temp-mark-border"
                >
                  <Container
                    style={{
                      width: "85%",
                      height: "85%",
                    }}
                    className="position-absolute top-50 start-50 translate-middle bg-success rounded-circle "
                  ></Container>
                </Container>
              ) : (
                <div
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    border: "1px solid #2a3042",
                  }}
                  className=" m-0 p-0 rounded-circle "
                ></div>
              )}
            </Row>
          ))
        ) : !issearching ? (
          <div className="text-center mt-4">No templates found</div>
        ) : (
          ""
        )}
      </div>

      {/* action button */}
      <Container className="d-flex button-action justify-content-end mt-3 gap-2">
        <Link to="/broadcast">
          {" "}
          <PerviousAction />
        </Link>
        {selectedTemplate ? (
          <div onClick={nextStep}>
            <ActionOpation />
          </div>
        ) : (
          <button
            className="next-page-option-button-disabled position-relative"
            disabled
          >
            Next
          </button>
        )}
      </Container>
    </div>
  )
}
