import ViewTeam from "pages/Settings/TeamsComponents/ViewTeam"
import React, { useEffect, useState } from "react"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector, useDispatch } from "react-redux"
import { Col, Row } from "reactstrap"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import ViewConversations from "pages/Settings/ConversationComponents/ViewConversations"
import ViewTeamAnswer from "pages/Settings/TeamsComponents/ViewTeamAnswer"
import ViewTeamServiceHours from "pages/Settings/UsersComponents/ViewTeamServiceHours"
import ViewTeamconversation from "pages/Settings/TeamsComponents/ViewTeamconversation"
import ArrowBackIcon from "components/Common/Icons/SettingIcons/ArrowBackIcon"
import { unAuthUser } from "store/actions"
import ViewTeamTicketRequest from "pages/Settings/TeamsComponents/ViewTeamTicketRequest"
import DefaultPhoto from "../../assets/images/default.jpg"

export default function UserTeamProfile() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [teams, setTeams] = useState([])
  const [isloading, setIsLoading] = useState(false)

  const fetchAllTems = () => {
    setIsLoading(true)

    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const Allteams = res.data.data
        setTeams(Allteams)
        setIsLoading(false)
      })
      .catch(error => {
        // setIsLoading(false)
        if (
          error.response?.status === 401 ||
          error.response?.status === 0 ||
          !error.response.status
        ) {
          dispatch(unAuthUser())
        } else if (error.response?.status === 403) {
          console.log("error response", error)
        } else {
          console.log(error)
        }
      })
  }

  useEffect(() => {
    fetchAllTems()
  }, [])

  const UserTeam = teams?.userTeam?._id
  const [viewUserTeam, setViewUserTeam] = useState([])

  const fetchUserTeamData = () => {
    setIsLoading(true)

    axios
      .get(`${Url}/${apiVersion}/teams/${UserTeam}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setViewUserTeam(res.data.data.team)
        // toggleViewTeamComponents(res.data.data.team)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)

        console.log(error)

        if (
          error.response?.status === 401 ||
          error.response?.status === 0 ||
          !error.response?.status
        ) {
          dispatch(unAuthUser())
        } else if (error.response?.status === 403) {
          console.log("error response", error)
        } else {
          console.log(error)
        }
      })
  }

  useEffect(() => {
    if (UserTeam) {
      fetchUserTeamData()
    }
  }, [UserTeam])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className=" user-setting-page ">
      <>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2 align-items-center mb-3">
            {/* <button
              className="bg-transparent border-0 "
              onClick={() => window.history.back()}

              // onClick={toggleCloseView}
            >
              <ArrowBackIcon />
            </button> */}
            {isloading ? (
              <h3>loading....</h3>
            ) : (
              <h3 className="m-0 text-capitalize h3">
                {viewUserTeam.name} Team
              </h3>
            )}
          </div>

          {/* <button className="edit-view" onClick={handleEditButtonClick}>
            Edit Team
          </button> */}
        </div>

        {isloading ? (
          <div className="right-side d-flex justify-content-center align-items-center">
            <div className="spinner-border d-flex justify-content-center align-items-center"></div>
          </div>
        ) : (
          <Row className="view-team-height  ">
            <>
              <div className=" left-side ">
                <Row className="row-md-4 Supervisor-team">
                  <div className=" d-flex justify-content-center mb-2">
                    {viewUserTeam?.supervisor?.photo ? (
                      <img
                        className="Supervisor-photo d-flex justify-content-center align-items-center"
                        src={`${Url}/${viewUserTeam?.supervisor?.photo}`}
                        // src={viewSelectedTeam.supervisor.photo}
                        alt={`${viewUserTeam?.supervisor?.firstName} ${viewUserTeam?.supervisor?.lastName}`}
                      />
                    ) : (
                      <img
                        className="Supervisor-photo d-flex justify-content-center align-items-center"
                        src={DefaultPhoto}
                        // src={viewSelectedTeam.supervisor.photo}
                        alt={`${viewUserTeam?.supervisor?.firstName} ${viewUserTeam?.supervisor?.lastName}`}
                      />
                      // <div className="Supervisor-photo d-flex justify-content-center align-items-center"></div>
                    )}
                  </div>
                  <h5 className="m-0">
                    {viewUserTeam?.supervisor?.firstName +
                      " " +
                      viewUserTeam?.supervisor?.lastName}
                  </h5>
                  <p className="m-0">Supervisor</p>
                </Row>

                <div className="row-md-8 p-3 overflow ">
                  <h5 className="mb-3">Team Members</h5>

                  {viewUserTeam?.users?.map(user => (
                    <div key={user.id} className="mb-4">
                      <div className="d-flex align-items-center gap-3 ">
                        <div>
                          {user.photo ? (
                            <img
                              className="empty-avatar"
                              // src={user.photo}

                              src={`${Url}/${user?.photo}`}
                              // alt={`${user.firstName} ${user.lastName}`}
                            />
                          ) : (
                            <img
                              className="empty-avatar"
                              src={DefaultPhoto}
                              // alt={`${user.firstName} ${user.lastName}`}
                            />
                            // <div className="empty-avatar d-flex justify-content-center align-items-center"></div>
                          )}
                        </div>
                        <div>
                          <p className="m-0">
                            {user.firstName + " " + user.lastName}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" right-side">
                <ViewTeamconversation viewSelectedTeam={viewUserTeam} />
                <ViewTeamServiceHours viewSelectedTeam={viewUserTeam} />
                <ViewTeamAnswer viewSelectedTeam={viewUserTeam} />
                <ViewTeamTicketRequest viewSelectedTeam={viewUserTeam} />
                {/* <ViewTeamServiceHours viewSelectedTeam={viewUserTeam} />
                <ViewTeamAnswer viewSelectedTeam={viewUserTeam} /> */}
              </div>
            </>
          </Row>
        )}
      </>
    </div>
  )
}
