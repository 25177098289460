import React, { useEffect, useState, useRef } from "react"
import axios from "axios"

import ChatHeader from "./ChatHeader"
import AllMessages from "./AllMessages"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { filterMessageType, processDataType } from "helpers/helper_functions"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import configs from "config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import CreateMessage from "./CreateMessage"
import AudioSingleMessage from "./SingleMessageTypes/AudioSingleMessage"
import TemplateDataPreview from "./TemplateDataPreview"
import CustomSpinner from "components/Common/CustomSpinner"
import SendContacts from "./SendContacts"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
const { Url, apiVersion } = configs.client

function Conversation({ setSessionIndicator }) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const params = useParams()
  const dispatch = useDispatch()

  let headerVar
  let bodyVars

  const [scrollValue, setScrollValue] = useState(0)

  const [loadingMessages, setLoadingMessages] = useState(true)
  const [clientName, setClientName] = useState("")
  const [messageText, setMessageText] = useState("")
  const [sendFilesPopup, setSendFilesPopup] = useState(false)

  const [isChatOwner, setIsChatOwner] = useState(false)
  const [isChatArchived, setIsChatArchived] = useState(false)
  const [isUserShareTeamChat, setIsUserShareTeamChat] = useState(false)
  const [onSession, setOnSession] = useState(false)

  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [selectedVoice, setSelectedVoice] = useState(null)
  const [selectedReplyMessage, setSelectedReplyMessage] = useState(null)

  const [showTemplates, setShowTemplates] = useState(false)
  const [templatesData, setTemplatesData] = useState([])
  const [templatesNames, setTemplatesNames] = useState([])
  const [singleTemplateData, setSingleTemplateData] = useState(null)
  const [selectedTemplateFile, setSelectedTemplateFile] = useState(null)
  const [showContactsSelector, setShowContactsSelector] = useState(false)
  const toggleShowContacts = () =>
    setShowContactsSelector(!showContactsSelector)

  const [lastSession, setLastSession] = useState({})

  const [imgFile, setImgFile] = useState("")
  const [videoFile, setVideoFile] = useState("")
  const [docFile, setDocFile] = useState("")
  const [voiceFile, setVoiceFile] = useState("")

  const [sendingTemplateLoading, setSendingTemplateLoading] = useState(false)

  const toggleShowTemplates = () => setShowTemplates(!showTemplates)

  const fireShowingTemplates = () => {
    toggleShowTemplates()
    fetchingTemplatesData()
  }

  const handleSelectTemplate = template => {
    setSingleTemplateData(
      templatesData.filter(item => item.id === template.id)[0]
    )
  }

  const [loadingTemplates, setLoadingTemplates] = useState(true)

  const fetchingTemplatesData = () => {
    // setIsLoading(true)

    axios
      .get(`${Url}/${apiVersion}/whatsapp-templates?status=approved`, {
        headers: { Authorization: `Bearer ${access}` },
      })
      .then(res => {
        setTemplatesData(res.data.data.templates)

        const templateFromApi = res.data.data.templates.map(template => ({
          id: template.id,
          name: template.name,
        }))

        setTemplatesNames(templateFromApi)
        setLoadingTemplates(false)
        // setIsLoading(false)
      })
      .catch(err => {
        setLoadingTemplates(false)
        // setIsLoading(false)
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)

          toast.error("Error loading templates.")
        }
      })
  }

  const sendNewMessage = async () => {
    // setDisableSendMessageBtn(true)

    setMessageText("")
    // setDisable(false)
    // setEmoji(false)
    setSelectedImage(null)
    setSelectedVideo(null)
    setSelectedDoc(null)
    setSelectedVoice(null)
    setImgFile("")
    setVideoFile("")
    setDocFile("")
    setVoiceFile("")

    // let newMessageObj = {
    //   from: businessNumber,
    //   status: "pending",
    //   type: "text",
    //   user: {
    //     firstName: userData?.firstName,
    //     lastName: userData?.lastName,
    //   },
    //   text: curMessage,
    // }

    // messages.push(newMessageObj)

    const url = `${Url}/${apiVersion}/chats/${params.id}/messages`

    const requestOptions = processDataType(
      access,
      imgFile,
      videoFile,
      docFile,
      voiceFile,
      selectedReplyMessage,
      messageText
    )

    axios
      .post(url, requestOptions.data, requestOptions)
      .then(response => {
        if (response.status === 200) {
          messages[messages.length - 1] = response.data.message
        }

        if (response.status === 201) {
          // setDisableSendMessageBtn(false)
          setSelectedReplyMessage(null)
        }

        // if (response.status === 401) {
        //   dispatch(authorized())
        // }
      })
      .catch(err => {
        // setDisableSendMessageBtn(false)
        setSelectedReplyMessage(null)

        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)

          toast.error("Error sending message")
        }
      })
      .finally(() => {
        // setDisableSendMessageBtn(true)

        setMessageText("")
        // setDisable(false)
        // setEmoji(false)
        setSelectedImage(null)
        setSelectedVideo(null)
        setSelectedDoc(null)
        setSelectedVoice(null)
        setImgFile("")
        setVideoFile("")
        setDocFile("")
        setVoiceFile("")
      })

    setMessageText("")
    // setDisable(false)
    // setEmoji(false)
    setSelectedImage(null)
    setSelectedVideo(null)
    setSelectedDoc(null)
    setSelectedVoice(null)
    setImgFile("")
    setVideoFile("")
    setDocFile("")
    setVoiceFile("")
  }

  const handleFileChange = (event, fileType) => {
    event.preventDefault()
    let files = event.target.files

    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader()

      reader.onloadend = () => {
        switch (fileType) {
          case "image":
            setImgFile(prevFiles => [...prevFiles, files[i]])
            setSelectedImage(prevImages =>
              prevImages ? [...prevImages, reader.result] : [reader.result]
            )
            break
          case "video":
            setVideoFile(prevFiles => [...prevFiles, files[i]])
            setSelectedVideo(prevVideos =>
              prevVideos ? [...prevVideos, reader.result] : [reader.result]
            )
            break
          case "document":
            setDocFile(prevFiles => [...prevFiles, files[i]])
            setSelectedDoc(prevDocs =>
              prevDocs ? [...prevDocs, reader.result] : [reader.result]
            )
            break
          case "voice":
            setVoiceFile(prevFiles => [...prevFiles, files[i]])
            setSelectedVoice(prevVoices =>
              prevVoices ? [...prevVoices, reader.result] : [reader.result]
            )
            break
          default:
            break
        }

        // setDisable(true)
      }

      reader.readAsDataURL(files[i])
    }
  }

  useEffect(() => {
    setSessionIndicator(onSession)
  }, [onSession])

  useEffect(() => {
    setSendFilesPopup(false)
    setIsChatOwner(false)
    setIsChatArchived(false)
    setOnSession(false)
    setIsUserShareTeamChat(false)
    setClientName("")
  }, [params.id])

  const handleRemoveImage = index => {
    const updatedSelectedImage = [...selectedImage]
    updatedSelectedImage.splice(index, 1)
    setImgFile(prevImgFile => prevImgFile.filter((_, i) => i !== index))
    setSelectedImage(updatedSelectedImage)
    if (updatedSelectedImage.length === 0) {
      setSelectedImage(null)
    }
  }

  const handleAddNewImage = event => {
    const images = event.target.files
    if (images && images.length > 0) {
      const imageList = Array.from(images)
      const newImages = imageList.filter(
        image => !imgFile.some(selected => selected.name === image.name)
      )
      const updatedImages = newImages.map(image => URL.createObjectURL(image))

      setImgFile(prevImages => [...prevImages, ...newImages])

      setSelectedImage(prevSelectedImages => [
        ...prevSelectedImages,
        ...updatedImages,
      ])
    }
  }

  const handleCloseDocument = index => {
    const updatedDocFile = [...docFile]
    updatedDocFile.splice(index, 1)
    setDocFile(updatedDocFile)

    if (updatedDocFile.length === 0) {
      setSelectedDoc(null)
    }
  }

  const handleAddFile = event => {
    const files = event.target.files
    if (files && files.length > 0) {
      const fileList = Array.from(files)

      const newFiles = fileList.filter(
        file => !docFile.some(existingFile => existingFile.name === file.name)
      )

      setDocFile(prevFiles => [...prevFiles, ...newFiles])
    }
  }

  const checkHeight = () => {
    const scrollableDiv = document.getElementById("scrollableDiv")
    if (scrollableDiv) {
      const height = scrollableDiv.scrollHeight
      // console.log("scrollableDiv.scrollHeight:", height)
    }
  }

  const logScrollPosition = () => {
    const scrollableDiv = document.getElementById("scrollableDiv")
    if (scrollableDiv) {
      const scrollTop = scrollableDiv.scrollTop
      // console.log("scrollableDiv.scrollTop:", scrollTop)
      setScrollValue(-scrollTop)
    }
  }

  useEffect(() => {
    checkHeight()

    const scrollableDiv = document.getElementById("scrollableDiv")
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", logScrollPosition)
    }

    window.addEventListener("resize", checkHeight)

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", logScrollPosition)
      }
      window.removeEventListener("resize", checkHeight)
    }
  }, [])

  useEffect(() => {
    const observer = new MutationObserver(checkHeight)
    const scrollableDiv = document.getElementById("scrollableDiv")
    if (scrollableDiv) {
      observer.observe(scrollableDiv, { childList: true, subtree: true })
    }

    return () => {
      if (scrollableDiv) {
        observer.disconnect()
      }
    }
  }, [])

  const scrollToEnd = () => {
    const scrollableDiv = document.getElementById("scrollableDiv")
    if (scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight
    }
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div
      className={`d-flex flex-column flex-grow-1 position-relative conversation-box`}
    >
      {sendingTemplateLoading && (
        <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
          <div>
            <CustomSpinner />
          </div>
        </div>
      )}

      {scrollValue > 2000 && (
        <button
          className="btn btn-secondary p-1 px-2 text-cneter btb"
          onClick={() => scrollToEnd()}
        >
          <i className="fas fa-angle-down"></i>
        </button>
      )}

      <ChatHeader
        loadingMessages={loadingMessages}
        clientName={clientName}
        isChatOwner={isChatOwner}
        setIsChatOwner={setIsChatOwner}
        isChatArchived={isChatArchived}
        isUserShareTeamChat={isUserShareTeamChat}
        setClientName={setClientName}
        setSendingTemplateLoading={setSendingTemplateLoading}
        lastSession={lastSession}
      />

      <AllMessages
        loadingMessages={loadingMessages}
        setLoadingMessages={setLoadingMessages}
        setClientName={setClientName}
        setIsChatOwner={setIsChatOwner}
        setOnSession={setOnSession}
        setSelectedReplyMessage={setSelectedReplyMessage}
        setIsChatArchived={setIsChatArchived}
        setIsUserShareTeamChat={setIsUserShareTeamChat}
        setLastSession={setLastSession}
      />

      <CreateMessage
        onSession={onSession}
        isChatOwner={isChatOwner}
        messageText={messageText}
        setMessageText={setMessageText}
        sendFilesPopup={sendFilesPopup}
        setSendFilesPopup={setSendFilesPopup}
        handleFileChange={handleFileChange}
        sendNewMessage={sendNewMessage}
        selectedVoice={selectedVoice}
        fireShowingTemplates={fireShowingTemplates}
        toggleShowContacts={toggleShowContacts}
        showContactsSelector={showContactsSelector}
        isChatArchived={isChatArchived}
        lastSession={lastSession}
      />

      {selectedReplyMessage && (
        <div className="message-reply">
          <div className="message-reply-content">
            <div className="d-flex flex-column">
              <button
                className="align-self-end btn-close btn-sm"
                aria-label="Close"
                onClick={() => setSelectedReplyMessage(null)}
              ></button>
            </div>

            <p className="mb-0">{filterMessageType(selectedReplyMessage)}</p>
          </div>
        </div>
      )}

      {selectedImage && (
        <div className="sending-files-preview">
          <div className="d-flex flex-row justify-content-between align-items-center sending-files-preview-header">
            <label className="mb-0">
              <i className="bx bx-plus add-icon"></i>
              <input
                multiple
                type="file"
                className="d-none"
                placeholder="+"
                accept="image/*"
                onChange={handleAddNewImage}
              />
            </label>
            <button
              className="align-self-end btn-close btn-sm"
              aria-label="Close"
              onClick={() => {
                setImgFile("")
                setSelectedImage(null)
              }}
            ></button>
          </div>

          <div className="d-flex gap-1 text-center imgs">
            {selectedImage.map((img, idx) => (
              <div key={idx} className="position-relative img-container">
                <button
                  className="align-self-end btn-close btn-sm delete-message"
                  aria-label="Close"
                  onClick={() => {
                    handleRemoveImage(idx)
                  }}
                ></button>

                <img src={img} alt={`img ${idx}`} />
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedVideo && (
        <div className="sending-files-preview">
          <div className="d-flex flex-row justify-content-between align-items-center sending-files-preview-header">
            <button
              className="align-self-end ms-auto btn-close btn-sm"
              aria-label="Close"
              onClick={() => {
                setVideoFile("")
                setSelectedVideo(null)
              }}
            ></button>
          </div>

          <div className="text-center">
            <div className="position-relative video-container">
              <video className="video-preview" controls>
                <source src={selectedVideo} />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}

      {selectedVoice && (
        <div className="sending-files-preview">
          <div className="d-flex flex-row justify-content-between align-items-center sending-files-preview-header">
            <button
              className="align-self-end ms-auto btn-close btn-sm"
              aria-label="Close"
              onClick={() => {
                setVoiceFile("")
                setSelectedVoice(null)
              }}
            ></button>
          </div>

          <div className="text-center">
            <div className="w-100 d-flex justify-content-center">
              <AudioSingleMessage
                audioSource={selectedVoice}
                dataSource="user"
              />
            </div>
          </div>
        </div>
      )}

      {selectedDoc && (
        <div className="sending-files-preview">
          <div className="d-flex flex-row justify-content-between align-items-center sending-files-preview-header">
            <label className="mb-0">
              <i className="bx bx-plus add-icon"></i>
              <input
                multiple
                type="file"
                className="d-none"
                placeholder="+"
                onChange={handleAddFile}
              />
            </label>

            <button
              className="align-self-end btn-close btn-sm"
              aria-label="Close"
              onClick={() => {
                setDocFile("")
                setSelectedDoc(null)
              }}
            ></button>
          </div>

          <div className="d-flex gap-1 text-center imgs">
            {docFile?.map((docFile, idx) => (
              <div key={idx} className="position-relative file-container">
                <button
                  className="align-self-end btn-close btn-sm delete-message"
                  aria-label="Close"
                  onClick={() => {
                    handleCloseDocument(idx)
                  }}
                ></button>

                <div className="d-flex flex-column justify-content-center align-items-center file-container-data">
                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-24 file-container-icon-container">
                    <i className="bx bxs-file-doc" />
                  </span>
                  <span>
                    Size: {(Number(docFile.size) / (1024 * 1024)).toFixed(2)} MB
                  </span>
                  <h6>
                    {docFile.name.length > 10
                      ? docFile.name.substring(0, 10) + "_"
                      : docFile.name}
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {showTemplates && (
        <div className="template-layout">
          {loadingTemplates ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <CustomSpinner />
            </div>
          ) : singleTemplateData?.length === 0 ? (
            <div className="d-flex flex-column gap-4 show-templates">
              <button
                className="align-self-start btn-close btn-sm"
                aria-label="Close"
                onClick={() => toggleShowTemplates()}
              ></button>

              <div className="d-flex flex-column gap-2 templates-container text-center">
                No chats are found
              </div>
            </div>
          ) : singleTemplateData ? (
            <TemplateDataPreview
              setSingleTemplateData={setSingleTemplateData}
              singleTemplateData={singleTemplateData}
              headerVar={headerVar}
              bodyVars={bodyVars}
              setSelectedTemplateFile={setSelectedTemplateFile}
              selectedTemplateFile={selectedTemplateFile}
              chatId={params.id}
              setShowTemplates={setShowTemplates}
              sendingTemplateLoading={sendingTemplateLoading}
              setSendingTemplateLoading={setSendingTemplateLoading}
            />
          ) : (
            <div className="d-flex flex-column gap-4 show-templates">
              <button
                className="align-self-start btn-close btn-sm"
                aria-label="Close"
                onClick={() => toggleShowTemplates()}
              ></button>

              <div className="d-flex flex-column gap-2 templates-container">
                {templatesNames.map(template => (
                  <h3
                    key={template.id}
                    className="template"
                    onClick={() => handleSelectTemplate(template)}
                  >
                    {template.name
                      .replace(/_/g, " ")
                      .replace(/\w\S*/g, function (txt) {
                        return (
                          txt.charAt(0).toUpperCase() +
                          txt.substr(1).toLowerCase()
                        )
                      })}
                  </h3>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {showContactsSelector && (
        <div className="template-layout">
          <div className="d-flex flex-column gap-4 h-100 show-templates">
            <button
              className="align-self-start btn-close btn-sm"
              aria-label="Close"
              onClick={() => toggleShowContacts()}
            ></button>

            <SendContacts
              roomId={params.id}
              access={access}
              showContactsSelector={showContactsSelector}
              setShowContactsSelector={setShowContactsSelector}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Conversation
