import React, { useEffect, useState } from "react"
import axios from "axios"
import { useFormik } from "formik"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import configs from "../../../config"
import { Spinner } from "reactstrap"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
const { Url, apiVersion } = configs.client

const animatedComponents = makeAnimated()

function SendContacts({
  roomId,
  access,
  showContactsSelector,
  setShowContactsSelector,
}) {
  const [contacts, setContacts] = useState([])
  const [selectedContacts, setSelectedContacts] = useState([])
  const [sendingContactsLoading, setSendingContactsLoading] = useState(false)

  const dispatch = useDispatch()

  const fetchContacts = async () => {
    axios
      .get(
        `https://rd0.cpvarabia.com/api/Care/users.php?Token=OKRJ_R85rkn9nrgg`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        setContacts(res.data)
      })
      .catch(err => {
        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Error fetching contacts.")
        }
      })
  }

  useEffect(() => {
    fetchContacts()
  }, [])

  const handleSumbitContacts = data => {
    setSendingContactsLoading(true)
    axios
      .post(
        `${Url}/${apiVersion}/chats/${roomId}/messages`,
        { type: "contacts", contacts: data },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        setSendingContactsLoading(false)
        toast.success("Contacts sent successfully!")
      })
      .catch(err => {
        setSendingContactsLoading(false)

        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Error sending contacts.")
        }
      })
      .finally(() => {
        setShowContactsSelector(!showContactsSelector)
      })
  }

  const contactOptions = contacts
    .map((contact, index) => {
      if (contact.name) {
        return {
          value: contact,
          label: contact.name,
        }
      }
      return null
    })
    .filter(Boolean)

  const formik = useFormik({
    initialValues: {
      formikContacts: [],
    },
    onSubmit: values => {
      handleSumbitContacts(values.formikContacts)
      setShowContactsSelector(!showContactsSelector)
    },
  })

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      <form className="h-100" onSubmit={formik.handleSubmit}>
        <div className="d-flex flex-column h-100 justify-content-between gap-2 h-100 templates-container text-center">
          <div className="flex-grow-1 select-container">
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              isMulti
              options={contactOptions}
              placeholder="Select contacts"
              onChange={selected => {
                const selectedValues = selected.map(option => option.value)
                formik.setFieldValue("formikContacts", selectedValues)
                setSelectedContacts(selected)
              }}
              value={selectedContacts}
            />
          </div>

          <button
            disabled={sendingContactsLoading}
            type="submit"
            className="btn btn-secondary mt-auto"
          >
            {sendingContactsLoading ? `Submit ${(<Spinner />)}` : `Submit`}
          </button>
        </div>
      </form>
    </>
  )
}

export default SendContacts
