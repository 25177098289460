import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import InfiniteScroll from "react-infinite-scroll-component"
import socket from "socket"

import configs from "config"
const { Url, apiVersion } = configs.client

import "./notifications.css"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"
import axios from "axios"
import Spinner from "../../../../node_modules/react-bootstrap/esm/Spinner"
import CustomSpinner from "components/Common/CustomSpinner"

import moment from "moment"

const NotificationDropdown = props => {
  let { access, userData, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    userData: state.Login.userData,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [menu, setMenu] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  const [notifications, setNotifications] = useState([])
  const [loadingNotifications, setLoadingNotifications] = useState(false)
  const [lastSeenNotificationId, setLastSeenNotificationId] = useState(null)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const fetchNewNotificationsNumber = () => {
    axios
      .get(`${Url}/${apiVersion}/notifications/new`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setNotificationCount(res.data.data.newNotifications)
      })
      .catch(err => {
        console.error("Failed to fetch notifications", err)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        }
      })
  }

  const handleMarkAllAsRead = () => {
    axios
      .patch(
        `${Url}/${apiVersion}/notifications`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        setNotificationCount(0)
        console.log("All notifications marked as read")
      })
      .catch(err => {
        console.error("Failed to mark all notifications as read", err)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        }
      })
  }

  const fetchNotifications = () => {
    setLoadingNotifications(true)

    axios
      .get(`${Url}/${apiVersion}/notifications?page=${page}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setLoadingNotifications(false)
        setNotifications(res.data.data.notifications)
        setTotalPages(res.data.data.totalPages)
      })
      .catch(err => {
        setLoadingNotifications(false)
        console.error("Failed to fetch notifications", err)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        }
      })
  }

  const handleSingleNotificationClick = notification => {
    setMenu(false)

    axios
      .patch(
        `${Url}/${apiVersion}/notifications/${notification._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        console.log("updated")
      })
      .catch(err => {
        console.error("Failed to mark notification as read", err)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        }
      })
  }

  useEffect(() => {
    fetchNewNotificationsNumber()
  }, [])

  useEffect(() => {
    menu && fetchNotifications()
  }, [menu])

  const fetchMoreNotifications = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1)
    }
  }

  useEffect(() => {
    if (page > 1) {
      fetchNotifications()
    }
  }, [page])

  useEffect(() => {
    let socketEmitBody = { type: "newNotifications" }

    if (socket) {
      const handleUpdating = () => {
        socket.emit("client_to_server", socketEmitBody)
      }

      const handleServerToClient = response => {
        if (response.newNotifications) {
          setNotificationCount(response.newNotifications)
        }
      }

      socket.on("updatingNotifications", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      return () => {
        socket.off("updatingNotifications", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [socket])

  useEffect(() => {
    let socketEmitBody = { type: "notifications", notificationPage: page }

    if (socket) {
      const handleUpdating = () => {
        socket.emit("client_to_server", socketEmitBody)
      }

      const handleServerToClient = response => {
        if (response.notifications) {
          setNotifications(response.notifications)
          setNotificationCount(response.newNotifications)

          if (
            lastSeenNotificationId !== response.newNotifications &&
            response.newNotifications > lastSeenNotificationId
          ) {
            toast.info(
              `New ${
                response.notifications[0].event === "newMessages"
                  ? "message"
                  : "ticket"
              } notification: ${response.notifications[0].message}`
            )
          }

          setLastSeenNotificationId(response.newNotifications)
        }
      }

      socket.on("updatingNotifications", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      return () => {
        socket.off("updatingNotifications", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [page, socket])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          style={{ height: "2.5rem" }}
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell ${notificationCount > 0 && "bx-tada"}`} />
          {notificationCount > 0 && (
            <span
              className="badge bg-danger rounded-pill"
              style={{ right: "5px", top: "17px" }}
            >
              {notificationCount ? notificationCount : <Spinner size="sm" />}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small" onClick={handleMarkAllAsRead}>
                  Mark All as Read
                </a>
              </div>
            </Row>
          </div>

          <div
            id="scrollableNotificationsDiv"
            style={{ height: "350px", overflow: "auto" }}
          >
            {loadingNotifications && page === 1 ? (
              <CustomSpinner />
            ) : (
              <InfiniteScroll
                dataLength={notifications.length}
                next={fetchMoreNotifications}
                hasMore={page < totalPages}
                loader={
                  <h4 className="text-center">
                    <i className="bx bx-loader bx-spin bx-md"></i>
                  </h4>
                }
                scrollableTarget="scrollableNotificationsDiv"
              >
                {notifications.map(notification => (
                  <Link
                    key={notification._id}
                    to={
                      notification?.type === "messages"
                        ? `/chat/${notification?.chat?.client}`
                        : `/ticket/list/${notification.ticket?._id}`
                    }
                    className={`text-reset notification-item ${
                      !notification.read && "unread-notification"
                    }`}
                    onClick={() => handleSingleNotificationClick(notification)}
                  >
                    <div className="d-flex gap-3">
                      <div className="avatar-xs" style={{ minWidth: "2rem" }}>
                        <span className="avatar-title bg-secondary rounded-circle font-size-16">
                          {notification.event === "newMessages" ? (
                            <i className="bx bx-message" />
                          ) : (
                            <i className="mdi mdi-ticket" />
                          )}
                        </span>
                      </div>

                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">
                          {notification.event === "newMessages"
                            ? "Message Notification"
                            : "Ticket Notification"}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notification.message}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />{" "}
                            {moment(notification.sortingDate).fromNow()}
                          </p>
                        </div>
                      </div>

                      {notification.numbers > 1 && (
                        <div
                          className="avatar-xs align-self-center"
                          style={{
                            height: "1rem",
                            width: "1rem",
                            minWidth: "1rem",
                          }}
                        >
                          <span className="avatar-title bg-danger rounded-circle font-size-12">
                            {notification.numbers}
                          </span>
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>

      {/* <ToastContainer position="bottom-right" autoClose={5000} /> */}
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
