import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { unAuthUser } from "store/actions"
import configs from "config"

const { Url, apiVersion } = configs.client

function ControlSession({
  controlSessionModal,
  toggleControlSessionModal,
  session,
  setSendingTemplateLoading,
}) {
  const { access } = useSelector(state => ({
    access: state.Login.userAccess,
  }))
  const dispatch = useDispatch()
  const params = useParams()

  const handleToggleSession = () => {
    setSendingTemplateLoading(true)
    toggleControlSessionModal()

    const requestBody = {
      secret: !session?.secret, // Toggle the current session's secret value
    }

    axios
      .patch(`${Url}/${apiVersion}/sessions/${session._id}`, requestBody, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json; charset=utf8",
        },
      })
      .then(res => {
        setSendingTemplateLoading(false)

        const successMessage = requestBody.secret
          ? "Session is now secret. You can send secret messages."
          : "Session is now public. You can send public messages."

        toast.success(successMessage)
      })
      .catch(err => {
        setSendingTemplateLoading(false)

        if (err.response?.status === 401 || !err.response?.status) {
          dispatch(unAuthUser())
        } else {
          const errorMessage = requestBody.secret
            ? "Failed to set the session to secret."
            : "Failed to set the session to public."

          toast.error(errorMessage)
        }
      })
  }

  const isSessionSecret = session?.secret
  const buttonLabel = isSessionSecret
    ? "Send Public Messages"
    : "Send Secret Messages"
  const buttonColor = isSessionSecret ? "success" : "danger"

  // Dynamically change header and body based on session state
  const modalHeaderText = isSessionSecret
    ? "Confirm Send Public Messages"
    : "Confirm Send Secret Messages"
  const modalBodyText = isSessionSecret
    ? "Are you sure you want to send public messages? The session will no longer be secret."
    : "Are you sure you want to send secret messages? The session will become private."

  return (
    <Modal
      isOpen={controlSessionModal}
      toggle={toggleControlSessionModal}
      centered
    >
      <ModalHeader toggle={toggleControlSessionModal}>
        {modalHeaderText}
      </ModalHeader>

      <ModalBody>{modalBodyText}</ModalBody>

      <ModalFooter>
        <Button
          color="secondary"
          outline
          onClick={toggleControlSessionModal}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          Cancel
        </Button>{" "}
        <Button
          color={buttonColor}
          onClick={handleToggleSession}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          {buttonLabel}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ControlSession
