import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListStickerMessageContent({
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
}) {
  return (
    <div>
      <CorrectMessageMark
        fromWho={fromWho}
        sentMessage={sentMessage}
        deliveredMessage={deliveredMessage}
        seenMessage={seenMessage}
      />
      <i className="mdi mdi-sticker-emoji"></i> <span>Sticker</span>
    </div>
  )
}

export default ChatListStickerMessageContent
