import React, { useState } from "react"

export default function ViewTeamAnswer({ viewSelectedTeam }) {
  const [expandedIndex, setExpandedIndex] = useState(null)

  const toggleAccordion = index => {
    if (expandedIndex === index) {
      setExpandedIndex(null)
    } else {
      setExpandedIndex(index)
    }
  }

  return (
    <div className="item">
      <div className="team-conversation">
        <h5>Team&apos;s Suggested Answers</h5>
      </div>
      <div className="team-conversation-data">
        {viewSelectedTeam?.answersSets?.length > 0 ? (
          viewSelectedTeam.answersSets.map((set, index) => (
            <div key={set._id} className="accordion-item">
              <div
                className="answer d-flex justify-content-between mb-2"
                onClick={() => toggleAccordion(index)}
              >
                <p className="answer-name m-0">{set.name}</p>
                <p className="accordion-icon m-0  ">
                  {expandedIndex === index ? "\u25B2" : "\u25BC"}
                </p>
              </div>

              {expandedIndex === index && (
                <div className="accordion-content">
                  {set.answers?.length > 0 ? (
                    set.answers.map((answer, answerIndex) => (
                      <div key={answer._id} className="answer-details m-2 mb-3">
                        <p className="m-0 fw-bold">{answer.name}</p>
                        <p className="m-0">{answer.body}</p>
                      </div>
                    ))
                  ) : (
                    <p className="text-center">No answers available</p>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-center">No data available</p>
        )}
      </div>
    </div>
  )
}
