import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import {
  Badge,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Toast,
  ToastHeader,
  ToastBody,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import configs from "config"
import { useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"
import CustomSpinner from "components/Common/CustomSpinner"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format } from "date-fns"
import Select from "react-select"

const { Url, apiVersion } = configs.client
import "./TicketForms/TicketFormsList.css"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import "react-datepicker/dist/react-datepicker.css"
import "./TicketList/CustomPaginationStyle.css"
import "./TicketList/TicketListStyle.css"
import ResetFiltersIcon from "components/Common/Icons/SettingIcons/ResetFiltersIcon"
import { generateUserLocalDateFromTimestamp } from "helpers/helper_functions"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TicketList = () => {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const [exportingData, setExportingData] = useState(false)
  const [forms, setForms] = useState([])
  const [teams, setTeams] = useState([]) // State to store teams data
  const [users, setUsers] = useState([]) // State to store teams data
  const [filters, setFilters] = useState({
    refNo: "",
    order: "",
    dateRange: [null, null], // One state for date range
    natureOfRequest: "", // New filter for nature of request
    typeOfRequest: "", // New filter for type of request
    responsibleDepartment: "", // New filter for responsible department
    assignee: [],
    statuses: [],
    creator: [],
  })
  const [loadingForms, setLoadingForms] = useState(true)
  const [noTicketsFound, setNoTicketsFound] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1) // Assuming you will get this value from the API
  const [userOrAllTickets, setUserOrAllTickets] = useState("all")
  const [defaultTeamId, setDefaultTeamId] = useState("")
  const [statuses, setStatuses] = useState([])
  const itemsPerPage = 20

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cancelTokenRef = useRef(null)

  const [startDate, endDate] = filters.dateRange

  const generateExportUrl = () => {
    const params = {
      type: "download",
    }

    // Only include filters that have values
    if (filters.refNo) {
      params.refNo = filters.refNo
    }

    if (filters.order) {
      params.order = filters.order
    }

    if (startDate) {
      params.startDate = format(startDate, "MM-dd-yyyy")
    }

    if (endDate) {
      params.endDate = format(endDate, "MM-dd-yyyy")
    }

    if (filters.natureOfRequest) {
      params.requestNature = filters.natureOfRequest
    }

    if (filters.typeOfRequest) {
      params.requestType = filters.typeOfRequest
    }

    if (filters.assignee.length > 0) {
      params.assignee = filters.assignee.join(",")
    }

    if (filters.statuses.length > 0) {
      params.status = filters.statuses.join(",")
    }

    if (filters.creator.length > 0) {
      params.creator = filters.creator.join(",") // Pass creators as a comma-separated string
    }

    if (filters.responsibleDepartment) {
      const selectedTeam = teams.find(
        team => team.name === filters.responsibleDepartment
      )
      if (selectedTeam) {
        params.team = selectedTeam._id
      }
    }

    // Construct query string
    const queryString = new URLSearchParams(params).toString()
    return `${Url}/${apiVersion}/tickets?${queryString}`
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Highlight focused option, otherwise white
      color: state.isSelected ? "#333" : "#000", // Change text color if selected
    }),
    control: provided => ({
      ...provided,
      borderColor: "#ddd", // Custom border color for the select input
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#fff", // Make sure the dropdown menu is white
    }),
  }

  const natureOfRequestOptions = [
    { value: "Request", label: "Request" },
    { value: "Complaint", label: "Complaint" },
    { value: "Inquiry", label: "Inquiry" },
  ]

  const typeOfRequestOptions = [
    { value: "RD0", label: "RD0" },
    { value: "Edit RD0", label: "Edit RD0" },
    { value: "Missing Data", label: "Missing Data" },
    { value: "Design Review", label: "Design Review" },
    { value: "RD6", label: "RD6" },
    { value: "RD7", label: "RD7" },
    { value: "Finance", label: "Finance" },
    { value: "Inspection", label: "Inspection" },
    { value: "MALATH Issue", label: "MALATH Issue" },
    { value: "MALATH Complaint", label: "MALATH Complaint" },
    { value: "Other", label: "Other" },
  ]

  // Generate options for Responsible Department from the teams array
  const responsibleDepartmentOptions = teams.map(team => ({
    value: team.name,
    label: team.name,
  }))

  const creatorOptions = users.map(user => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`,
  }))

  // Generate options for Assignees from the users array
  const assigneeOptions = users.map(user => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`,
  }))

  const statusOptions = statuses.map(status => ({
    value: status._id,
    label: status.endUserDisplayName || status.name,
  }))

  // Check if any filter is active (used to conditionally show the reset button)
  const isAnyFilterSelected = () => {
    return (
      filters.refNo ||
      filters.order ||
      filters.assignee.length > 0 ||
      filters.creator.length > 0 ||
      filters.natureOfRequest ||
      filters.typeOfRequest ||
      filters.responsibleDepartment ||
      filters.statuses.length > 0 ||
      filters.dateRange[0] !== null ||
      filters.dateRange[1] !== null
    )
  }

  // Fetch the teams data
  const fetchTeams = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setTeams(res.data.data.teams)
        setDefaultTeamId(res.data.data.defaultTeam._id)
      })
      .catch(err => {
        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Error fetching teams")
        }
      })
  }

  const fetchTicketStatuses = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketStatuses?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setStatuses(res.data.data.statuses)
      })
      .catch(err => {
        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Error fetching statuses")
        }
      })
  }

  function isUserSupervisor(userData, teamsData, supervisorTeamId) {
    const superVisorTeam = teams.find(team => team._id === supervisorTeamId)

    if (superVisorTeam && superVisorTeam.supervisor._id === userData._id) {
      return true
    } else {
      return false
    }
  }

  // Fetch the teams data
  const fetchUsers = () => {
    axios
      .get(`${Url}/${apiVersion}/users`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setUsers(res.data.data.users)
      })
      .catch(err => {
        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Error fetching users")
        }
      })
  }

  const fetchFormsData = () => {
    const params = {
      page: currentPage,
    }

    // Only include filters that have values
    if (filters.refNo) {
      params.refNo = filters.refNo
    }

    if (filters.order) {
      params.order = filters.order
    }

    if (startDate) {
      params.startDate = format(startDate, "MM-dd-yyyy")
    }

    if (endDate) {
      params.endDate = format(endDate, "MM-dd-yyyy")
    }

    if (filters.natureOfRequest) {
      params.requestNature = filters.natureOfRequest
    }

    if (filters.typeOfRequest) {
      params.requestType = filters.typeOfRequest
    }

    if (filters.creator.length > 0) {
      params.creator = filters.creator.join(",") // Pass creators as a comma-separated string
    }

    if (filters.assignee.length > 0) {
      params.assignee = filters.assignee.join(",") // Pass assignees as a comma-separated string
    }

    if (filters.statuses.length > 0) {
      params.status = filters.statuses.join(",")
    }

    // Find the team ID based on the selected responsible department
    if (filters.responsibleDepartment) {
      const selectedTeam = teams.find(
        team => team.name === filters.responsibleDepartment
      )
      if (selectedTeam) {
        params.team = selectedTeam._id // Use the team's ID
      }
    }

    if (cancelTokenRef.current) {
      // Cancel the previous request if one exists
      cancelTokenRef.current.cancel("Operation canceled due to new request.")
    }

    cancelTokenRef.current = axios.CancelToken.source() // Create a new cancel token

    setLoadingForms(true)

    // Determine the URL based on userOrAllTickets
    const url =
      userOrAllTickets === "user"
        ? `${Url}/${apiVersion}/tickets/user-tickets`
        : `${Url}/${apiVersion}/tickets`

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
        params: params, // Pass the filtered params
        cancelToken: cancelTokenRef.current.token, // Pass the cancel token
      })
      .then(res => {
        setForms(res.data.data.tickets)

        if (res.data.data.tickets.length === 0) {
          setNoTicketsFound(true)
        } else {
          setNoTicketsFound(false)
        }

        setTotalPages(res.data.data.totalPages)
        setLoadingForms(false)
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message)
        } else {
          setLoadingForms(false)

          if (
            err.response?.status === 401 ||
            err.response?.status === 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            toast.error("Error fetching tickets")
          }
        }
      })
  }

  const handleToggleView = view => {
    setUserOrAllTickets(view)
    setCurrentPage(1) // Reset to the first page when toggling
  }

  const handleExportTickets = () => {
    setExportingData(true)

    axios
      .get(generateExportUrl(), {
        headers: { Authorization: `Bearer ${access}` },
        responseType: "blob",
      })
      .then(response => {
        setExportingData(false)

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        const contentDisposition = response.headers["content-disposition"]
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]
          : `Tickets_Export_${new Date().toISOString()}.xlsx`

        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)

        toast.success("Tickets exported successfully")
      })
      .catch(err => {
        setExportingData(false)

        if (
          err.response?.status === 401 ||
          err.response?.status === 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log("Error exporting tickets:", err)
          toast.error("Error exporting tickets")
        }
      })
  }

  const renderPagination = () => {
    const maxPagesToShow = 5 // The max number of page links to show at a time
    const ellipsisThreshold = 4 // Number of pages before/after the current page when ellipses are shown

    const paginationItems = []

    // Previous Button
    paginationItems.push(
      <PaginationItem disabled={currentPage <= 1} key="prev">
        <PaginationLink
          previous
          onClick={() => handlePageChange(currentPage - 1)}
          className="custom-page-link"
        />
      </PaginationItem>
    )

    // Pages with ellipses if necessary
    if (totalPages <= maxPagesToShow) {
      // Show all page numbers if total pages are less than maxPagesToShow
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <PaginationItem active={i === currentPage} key={i}>
            <PaginationLink
              className="custom-page-link"
              onClick={() => handlePageChange(i)}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        )
      }
    } else {
      // Only show a few page numbers with ellipses
      let startPage = Math.max(1, currentPage - ellipsisThreshold)
      let endPage = Math.min(totalPages, currentPage + ellipsisThreshold)

      if (startPage > 1) {
        paginationItems.push(
          <PaginationItem key={1}>
            <PaginationLink
              className="custom-page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </PaginationLink>
          </PaginationItem>
        )
        if (startPage > 2) {
          paginationItems.push(
            <PaginationItem disabled key="start-ellipsis">
              <PaginationLink className="custom-page-link">...</PaginationLink>
            </PaginationItem>
          )
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <PaginationItem active={i === currentPage} key={i}>
            <PaginationLink
              className="custom-page-link"
              onClick={() => handlePageChange(i)}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        )
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationItems.push(
            <PaginationItem disabled key="end-ellipsis">
              <PaginationLink className="custom-page-link">...</PaginationLink>
            </PaginationItem>
          )
        }
        paginationItems.push(
          <PaginationItem key={totalPages}>
            <PaginationLink
              className="custom-page-link"
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        )
      }
    }

    // Next Button
    paginationItems.push(
      <PaginationItem disabled={currentPage >= totalPages} key="next">
        <PaginationLink
          next
          onClick={() => handlePageChange(currentPage + 1)}
          className="custom-page-link"
        />
      </PaginationItem>
    )

    return (
      <Pagination
        aria-label="Page navigation example"
        className="mt-3 d-flex justify-content-center custom-pagination gap-2"
      >
        {paginationItems}
      </Pagination>
    )
  }

  // Fetch data when filters or page change
  useEffect(() => {
    fetchFormsData()

    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Component unmounted.")
      }
    }
  }, [filters, currentPage, userOrAllTickets])

  // Fetch teams when the component mounts
  useEffect(() => {
    fetchTeams()
    fetchUsers()
    fetchTicketStatuses()
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [filters])

  const handleFilterChange = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }))
  }

  const handleEdit = id => {
    const ticketUrl = `/ticket/list/${id}`
    window.open(ticketUrl, "_blank") // Open the ticket in a new tab
  }

  const handleAddNew = () => {
    navigate("/ticket/new")
  }

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
    // navigate(`?page=${pageNumber}`)
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <React.Fragment>
      <Container fluid className="p-3 position-relative">
        <Row className="justify-content-between">
          {/* add new ticket */}
          <Col md="4" className="d-flex align-items-center font-size-16">
            {userData?.role === "admin" ||
            userData?.team?.name?.toLowerCase() == "qc" ||
            userData?.team?.name?.toLowerCase() == "customer services" ? (
              <>
                <span
                  className={`me-4 text-ticket-filter ${
                    userOrAllTickets === "all" ? "active" : ""
                  }`}
                  onClick={() => handleToggleView("all")}
                >
                  All Tickets
                </span>
                <span
                  className={`text-ticket-filter ${
                    userOrAllTickets === "user" ? "active" : ""
                  }`}
                  onClick={() => handleToggleView("user")}
                >
                  My Tickets
                </span>
              </>
            ) : (
              ""
            )}
          </Col>

          <Col md="2" className="d-flex align-items-center justify-content-end">
            {isAnyFilterSelected() && (
              <button
                className="btn btn-secondary me-3"
                title="Clear filters"
                onClick={() =>
                  setFilters({
                    refNo: "",
                    order: "",
                    dateRange: [null, null],
                    natureOfRequest: "",
                    typeOfRequest: "",
                    responsibleDepartment: "",
                    statuses: [],
                    assignee: [],
                    creator: [],
                  })
                }
              >
                <ResetFiltersIcon />
              </button>
            )}
            <Button
              color="secondary"
              className="font-size-16 py-1"
              onClick={handleAddNew}
            >
              <i className="bx bx-plus"></i> Add Ticket
            </Button>
          </Col>
        </Row>

        <Row className="m-2">
          <Col md="12 mb-2">
            <Row>
              <Col md="3" className="d-flex align-items-center">
                <Row className="w-100">
                  {/* Order Input */}
                  <Col md="6">
                    <Input
                      type="search"
                      name="order"
                      placeholder="Ticket no."
                      value={filters.order}
                      onChange={e =>
                        handleFilterChange("order", e.target.value)
                      }
                      className="me-2"
                    />
                  </Col>

                  {/* Ref No Input */}
                  <Col md="6">
                    <Input
                      type="search"
                      name="refNo"
                      placeholder="Ref No"
                      value={filters.refNo}
                      onChange={e =>
                        handleFilterChange("refNo", e.target.value)
                      }
                      className="me-2"
                    />
                  </Col>
                </Row>
              </Col>

              <Col md="3" className="d-flex align-items-center">
                <Select
                  options={natureOfRequestOptions}
                  value={
                    filters.natureOfRequest
                      ? natureOfRequestOptions.find(
                          option => option.value === filters.natureOfRequest
                        )
                      : null
                  }
                  onChange={selected =>
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      natureOfRequest: selected ? selected.value : "",
                    }))
                  }
                  isClearable
                  placeholder="Nature of Request"
                  className="me-2 w-100"
                  styles={customStyles}
                />
              </Col>

              <Col md="3" className="d-flex align-items-center">
                <Select
                  options={typeOfRequestOptions}
                  value={
                    filters.typeOfRequest
                      ? typeOfRequestOptions.find(
                          option => option.value === filters.typeOfRequest
                        )
                      : null
                  }
                  onChange={selected =>
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      typeOfRequest: selected ? selected.value : "",
                    }))
                  }
                  isClearable
                  placeholder="Type of Request"
                  className="me-2 w-100"
                  styles={customStyles}
                />
              </Col>

              <Col md="3" className="d-flex align-items-center">
                <DatePicker
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => handleFilterChange("dateRange", update)}
                  isClearable={true}
                  placeholderText="Start date - End date"
                  dateFormat="MM-dd-yyyy"
                  className="me-2 w-100"
                  customInput={
                    <Input
                      style={{
                        display: "inline-block",
                        height: "36px",
                        background: "white",
                        border: "1px solid #ddd",
                        padding: "10px",
                      }}
                    />
                  }
                />
              </Col>
            </Row>
          </Col>

          <Col md="12">
            <Row>
              <Col md="3" className="d-flex align-items-start">
                <Select
                  options={statusOptions}
                  value={filters.statuses.map(statusId =>
                    statusOptions.find(option => option.value === statusId)
                  )}
                  onChange={selectedOptions =>
                    handleFilterChange(
                      "statuses",
                      selectedOptions
                        ? selectedOptions.map(option => option.value)
                        : []
                    )
                  }
                  isMulti
                  isClearable
                  placeholder="Statuses"
                  className="me-2 w-100"
                  styles={customStyles}
                />
              </Col>

              <Col md="3" className="d-flex align-items-start">
                <Select
                  options={creatorOptions}
                  value={filters.creator.map(creatorId =>
                    creatorOptions.find(option => option.value === creatorId)
                  )}
                  onChange={selectedOptions =>
                    handleFilterChange(
                      "creator",
                      selectedOptions
                        ? selectedOptions.map(option => option.value)
                        : []
                    )
                  }
                  isMulti
                  isClearable
                  placeholder="Created by"
                  className="me-2 w-100"
                  styles={customStyles}
                />
              </Col>

              <Col md="3" className="d-flex align-items-start">
                <Select
                  options={assigneeOptions}
                  value={filters.assignee.map(assigneeId =>
                    assigneeOptions.find(option => option.value === assigneeId)
                  )}
                  onChange={selectedOptions =>
                    handleFilterChange(
                      "assignee",
                      selectedOptions
                        ? selectedOptions.map(option => option.value)
                        : []
                    )
                  }
                  isMulti
                  isClearable
                  placeholder="Assignee"
                  className="me-2 w-100"
                  styles={customStyles}
                />
              </Col>

              <Col md="3" className="d-flex align-items-start">
                <Select
                  options={responsibleDepartmentOptions}
                  value={
                    filters.responsibleDepartment
                      ? responsibleDepartmentOptions.find(
                          option =>
                            option.value === filters.responsibleDepartment
                        )
                      : null
                  }
                  onChange={selected =>
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      responsibleDepartment: selected ? selected.value : "",
                    }))
                  }
                  isClearable
                  placeholder="Department"
                  className="w-100"
                  styles={customStyles}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          {loadingForms ? (
            <Col md={4} className="mx-auto">
              <CustomSpinner />
            </Col>
          ) : (
            <Col lg="12">
              <Card className="rounded-3">
                <CardBody>
                  <div
                    className="table-responsive ticketlist-table"
                    style={{ maxHeight: "62vh", overflowY: "auto" }}
                  >
                    <table className="table table-bordered mt-3">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Ref No</th>
                          <th>Nature Of Request</th>
                          <th>Request Type</th>
                          <th>Created by</th>
                          <th>Responsible Department</th>
                          <th>Assignee</th>
                          <th>Priority</th>
                          <th>Category</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      {noTicketsFound ? (
                        <td
                          className="text-center py-4 font-size-16"
                          colSpan={11}
                        >
                          No tickets are found
                        </td>
                      ) : (
                        <tbody>
                          {forms.map(form => (
                            <tr
                              key={form.id}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEdit(form._id)}
                              className="hover-form-row"
                            >
                              <td>{form.order}</td>
                              <td>{form.refNo}</td>
                              <td>{form.requestNature}</td>
                              <td>{form.requestType}</td>
                              <td>{`${form.creator?.firstName} ${form.creator?.lastName}`}</td>
                              <td>{form.team?.name}</td>
                              <td>{`${form.assignee?.firstName} ${form.assignee?.lastName}`}</td>
                              <td>
                                <span
                                  className={`badge rounded-pill py-2 px-3 ${
                                    form.priority === "Low"
                                      ? "badge-soft-success bg-secondary"
                                      : form.priority === "Normal"
                                      ? "badge-soft-primary bg-primary"
                                      : form.priority === "High"
                                      ? "badge-soft-warning bg-warning"
                                      : form.priority === "Urgent"
                                      ? "badge-soft-danger bg-danger"
                                      : ""
                                  }`}
                                >
                                  {form.priority}
                                </span>
                              </td>
                              <td>{form.category?.name}</td>
                              <td>
                                {form?.status?.category
                                  ?.toLowerCase()
                                  .trim() === "pending" && (
                                  <Badge
                                    pill
                                    className="badge-soft-warning ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>{" "}
                                    Pending
                                  </Badge>
                                )}

                                {form?.status?.category
                                  ?.toLowerCase()
                                  .trim() === "solved" && (
                                  <Badge
                                    pill
                                    className="badge-soft-secondary ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    Solved
                                  </Badge>
                                )}

                                {form?.status?.category
                                  ?.toLowerCase()
                                  .trim() === "new" && (
                                  <Badge
                                    pill
                                    className="badge-soft-danger ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    New
                                  </Badge>
                                )}

                                {form?.status?.category
                                  ?.toLowerCase()
                                  .trim() === "open" && (
                                  <Badge
                                    pill
                                    className="badge-soft-success ms-1 font-size-12"
                                  >
                                    <span className="font-size-16 me-1">
                                      &#9679;
                                    </span>
                                    Open
                                  </Badge>
                                )}

                                {(!form?.status?.category ||
                                  (form?.status?.category
                                    ?.toLowerCase()
                                    .trim() !== "new" &&
                                    form?.status?.category
                                      ?.toLowerCase()
                                      .trim() !== "solved" &&
                                    form?.status?.category
                                      ?.toLowerCase()
                                      .trim() !== "pending" &&
                                    form?.status?.category
                                      ?.toLowerCase()
                                      .trim() !== "open")) && (
                                  <Badge
                                    pill
                                    className="badge-soft-dark ms-1 font-size-12"
                                  >
                                    <i className="dripicons-dot me-1"></i>
                                    {form?.status?.category || "Unknown"}
                                  </Badge>
                                )}
                              </td>
                              <td>
                                {generateUserLocalDateFromTimestamp(
                                  form.createdAt
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    {renderPagination()}
                    {(userData.role === "admin" ||
                      isUserSupervisor(userData, teams, defaultTeamId) ||
                      userData?.team?.name?.toLowerCase() == "qc") &&
                    forms?.length > 0 ? (
                      <Button
                        onClick={handleExportTickets}
                        color="outline-secondary"
                        disabled={exportingData}
                        className="font-size-16 py-1"
                      >
                        {exportingData ? (
                          <Spinner size="sm" />
                        ) : (
                          "Export Tickets"
                        )}
                      </Button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>

        <Toast
          isOpen={exportingData}
          fade={true}
          style={{
            position: "fixed",
            top: "1rem",
            right: "1rem",
            backgroundColor: "#28a745", // Success background color
            zIndex: 1050, // Make sure it stays above other content
          }}
        >
          <ToastHeader
            style={{
              backgroundColor: "#28a745", // Match the success color
              color: "white", // White text for better readability
            }}
          >
            Downloading Exported Tickets
          </ToastHeader>
          <ToastBody>
            Your export request has been received. Please wait while the file is
            being prepared for download.
          </ToastBody>
        </Toast>
      </Container>
    </React.Fragment>
  )
}

export default withRouter(TicketList)
