import React, { useState } from "react"
import { useSelector } from "react-redux"

import InboxHeader from "./ChatList/InboxHeader"
import ChatFilters from "./ChatList/ChatFilters"
import SearchAddContainer from "./ChatList/SearchAddContainer"
import ChatListContainer from "./ChatList/ChatListContainer"
import ArchivedChatsFilters from "./ChatList/ArchivedChatsFilters"

function ChatList() {
  const { chatsType } = useSelector(state => ({
    chatsType: state.Login.chatsType,
  }))

  const [selectedUser, setSelectedUser] = useState(null)
  const [userID, setUserID] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [searchInChatsValue, setSearchInChatsValue] = useState("")

  return (
    <div className="inbox-section col-8 d-flex flex-column">
      <InboxHeader />

      <div className="inbox-chat-list d-flex flex-column">
        <div className="d-flex flex-column btn-search-container">
          {chatsType === "archivedChats" ? (
            <ArchivedChatsFilters
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              userID={userID}
              setUserID={setUserID}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          ) : (
            <ChatFilters />
          )}

          <SearchAddContainer setSearchInChatsValue={setSearchInChatsValue} />
        </div>

        <ChatListContainer
          selectedUserArchived={selectedUser}
          userIDArchived={userID}
          startDateArchived={startDate}
          endDateArchived={endDate}
          searchInChatsValue={searchInChatsValue}
        />
      </div>
    </div>
  )
}

export default ChatList
