import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
  teams,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import { Toast, ToastBody, ToastHeader } from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import Select from "react-select"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"

export default function AddNewTeam({
  isOpen,
  toggle,
  setShowSuccessToast,
  setViewTeamComponents,
  fetchTeamsData,
  toggleViewTeamComponents,
  handleEditButtonClick,
  toggleEdit,
  viewSelectedTeam,
}) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  // validateYupSchema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      // .min(2, "Too Short!")
      // .max(50, "Too Long!")
      .required(" required")
      .matches(/^(?!\s+$).*/, "invalid"),
    supervisor: Yup.string().required(" required"),
    conversation: Yup.string().required(" required"),
    serviceHours: Yup.string()
      // .min(2, "Too Short!")
      // .max(50, "Too Long!")
      .required(" required"),
  })
  //style react select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "white",
      backgroundColor: state.isFocused ? "#EBEDF0" : "white",
      color: state.isSelected ? "gray" : "black",
    }),
    menu: provided => ({
      ...provided,
      zIndex: 10000,
      height: "1rem",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: "10rem",
      overflowY: "auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }),
  }

  const [users, setUsers] = useState()
  const [selectedUser, setSelectedUser] = useState(null)
  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/Users?type=team`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          const Allusers = res.data.data.users?.map(user => ({
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
          }))
          // setTeams(res.data.data.teams)
          setUsers(Allusers)
        })
        .catch(error => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0 ||
            !error?.response?.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [isOpen])
  const handleUserChange = selectedOption => {
    setSelectedUser(selectedOption)
    setFieldValue("supervisor", selectedOption.value)
  }

  const [conversations, setConversations] = useState([])
  const [selectConversation, setSelectConversation] = useState([])
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)

      axios
        .get(`${Url}/${apiVersion}/conversations`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setIsLoading(false)
          //   const AllConversations = res.data.data?.conversations.reverse()
          const AllConversations = res.data.data.conversations?.map(
            conversations => ({
              value: conversations._id,
              label: conversations.name,
            })
          )
          setConversations(AllConversations)
        })
        .catch(error => {
          setIsLoading(false)
          if (
            error.response?.status === 401 ||
            error.response?.status === 0 ||
            !error?.response?.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [isOpen])

  const handleConversationChange = selectedOption => {
    setSelectConversation(selectedOption)
    setFieldValue("conversation", selectedOption.value)
  }

  const [serviceHours, setServiceHours] = useState([])
  const [selectedServiceHours, setSelectedServiceHours] = useState([])

  const handleServiceHoursChange = selectedOption => {
    setSelectedServiceHours(selectedOption)
    setFieldValue("serviceHours", selectedOption.value)
  }
  useEffect(() => {
    setIsLoading(true)
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/services`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setIsLoading(false)
          //   const AllConversations = res.data.data?.conversations.reverse()
          const AllServiceHoursData = res.data.data.services?.map(
            serviceHour => ({
              value: serviceHour._id,
              label: serviceHour.name,
            })
          )
          setServiceHours(AllServiceHoursData)
        })
        .catch(error => {
          setIsLoading(false)
          if (
            error.response?.status === 401 ||
            error.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [isOpen])

  const [selectedMember, setSelectedMember] = useState(null)
  const handleMemberChange = selectedOption => {
    setSelectedMember(selectedOption)
    const selectedUserIds = selectedOption.map(option => option.value)
    setFieldValue("users", selectedUserIds)
  }

  // Formik
  const {
    handleChange,
    values,
    values: { Signature },
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      supervisor: "",
      serviceHours: "",
      conversation: "",
      users: [],
      ticketRequests: [],
    },
    validationSchema,
    onSubmit: values => {
      setIsLoading(true)
      axios
        .post(`${Url}/${apiVersion}/teams`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          if (res.status === 201) {
            const newTeam = res.data.data.team
            // toggleEdit(res.data.data.team)
            toggle()
            setIsLoading(false)
            resetForm()
            fetchTeamsData()
            setShowSuccessToast(true)
            setTimeout(() => setShowSuccessToast(false), 2500)
            setViewTeamComponents(true)
            toggleViewTeamComponents(newTeam)
          } else {
            console.error("Unexpected status code:", res.status, res.data)
            setIsLoading(false)
          }
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Error:", error)
          if (
            error.response.status === 401 ||
            error.response.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else if (error.response?.status === 403) {
            setErrorMessage(error.response.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
          } else {
            setErrorMessage(error.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            console.log(error)
          }
        })
    },
  })

  //ticket requests
  const ticketRequestsOptions = [
    { value: "RD0", label: "RD0" },
    { value: "Edit RD0", label: "Edit RD0" },
    { value: "Missing Data", label: "Missing Data" },
    { value: "Design Review", label: "Design Review" },
    { value: "RD6", label: "RD6" },
    { value: "RD7", label: "RD7" },
    { value: "Finance", label: "Finance" },
    { value: "Inspection", label: "Inspection" },
    { value: "MALATH Issue", label: "MALATH Issue" },
    { value: "MALATH Complaint", label: "MALATH Complaint" },
    { value: "Other", label: "Other" },
  ]
  const [selectedTicket, setSelectedTicket] = useState(null)

  const handleTicketChange = selectedOption => {
    setSelectedTicket(selectedOption)
    const selectedTickets = selectedOption
      ? selectedOption.map(option => option.value)
      : []
    setFieldValue("ticketRequests", selectedTickets)
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  const handleModalClosed = () => {
    resetForm()
    setSelectedMember("")
    setSelectedUser("")
    setSelectedServiceHours("")
    setSelectConversation("")
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={handleModalClosed}
        centered
        size="lg"
      >
        <ModalHeader toggle={toggle}>Add New Team</ModalHeader>
        <ModalBody>
          {errorMessage && (
            <div className="" aria-live="polite" aria-atomic="true">
              <div className="">
                <Toast isOpen={errorMessage} fade={true}>
                  <ToastBody className="delete-message-bg">
                    <h5 className="m-0 mb-2">Error</h5>
                    <p className="m-0">{errorMessage}</p>
                  </ToastBody>
                </Toast>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1 font-size-15">Team Name</Label>
              <Input
                className="  m-0"
                id="team-name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                // placeholder="Email"
                type="text"
              />
              {errors.name && touched.name && (
                <div className="error mb-1 font-size-15">{errors.name}</div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1 font-size-15">Supervisor </Label>
              <Select
                className=" m-0 "
                id="supervisor"
                name="supervisor"
                value={selectedUser}
                onChange={handleUserChange}
                options={users}
                styles={customStyles}
              ></Select>

              {errors.supervisor && touched.supervisor && (
                <div className="error mb-1 font-size-15">
                  {errors.supervisor}
                </div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1 font-size-15">Members </Label>
              <Select
                className=" m-0 "
                id="users"
                name="users"
                value={selectedMember}
                onChange={handleMemberChange}
                options={users}
                styles={customStyles}
                isMulti
              ></Select>

              {errors.users && touched.users && (
                <div className="error mb-1 font-size-15">{errors.users}</div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1 font-size-15">Service Hours</Label>
              <Select
                className=" m-0"
                id="serviceHours"
                name="serviceHours"
                value={selectedServiceHours}
                onChange={handleServiceHoursChange}
                options={serviceHours}
                styles={customStyles}
              ></Select>
              {errors.serviceHours && touched.serviceHours && (
                <div className="error mb-1 font-size-15">
                  {errors.serviceHours}
                </div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1 font-size-15">Conversation </Label>
              <Select
                className=" m-0"
                id="conversation"
                name="conversation"
                value={selectConversation}
                options={conversations}
                onChange={handleConversationChange}
                styles={customStyles}
              ></Select>
              {errors.conversation && touched.conversation && (
                <div className="error mb-1 font-size-15">
                  {errors.conversation}
                </div>
              )}
            </FormGroup>

            <Row>
              <Col>
                <FormGroup className="m-0 m-mb-2">
                  <Label className="mb-1 font-size-15">Ticket Requests</Label>
                  <Select
                    isMulti
                    className=" m-0"
                    id="ticketRequests"
                    name="ticketRequests"
                    value={selectedTicket}
                    options={ticketRequestsOptions}
                    onChange={handleTicketChange}
                    styles={customStyles}
                  ></Select>
                </FormGroup>
              </Col>
            </Row>

            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}

            <FormGroup className=" d-flex justify-content-end gap-3 mt-2">
              <Button
                type="submit"
                color="secondary"
                className="py-1 px-4 font-size-16 rounded-2"
              >
                Save
              </Button>{" "}
              <Button
                onClick={toggle}
                color="outline-secondary"
                className="py-1 px-4 font-size-16 rounded-2"
              >
                Cancel
              </Button>
            </FormGroup>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}
