import React, { useEffect, useMemo, useState } from "react"
import { Col, Row } from "reactstrap"
import axios from "axios"
import { useSelector } from "react-redux"
import configs from "../../../config"
const { Url, apiVersion } = configs.client
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"

export default function TicketPerformanceReportsFilters({
  selectedTeam,
  setSelectedTeam,
  selectedUser,
  setSelectedUser,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const [filters, setFilters] = useState({
    teams: [],
    users: [],
    teamUsers: [],
    startDate: null,
    endDate: null,
  })

  const dispatch = useDispatch()

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  //fetch team & user:
  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        const [TeamsResponse, usersResponse] = await Promise.all([
          axios.get(`${Url}/${apiVersion}/teams`, {
            headers: { Authorization: `Bearer ${access}` },
          }),
          axios.get(`${Url}/${apiVersion}/users`, {
            headers: { Authorization: `Bearer ${access}` },
          }),
        ])

        const teams = TeamsResponse.data.data.teams.map(team => ({
          value: team._id,
          label: team.name.replace(/_/g, " "),
          users: team.users.map(user => ({
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
          })),
        }))

        const users = usersResponse.data.data.users.map(user => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
        }))

        setFilters(prevFilters => ({
          ...prevFilters,
          teams: teams,
          users: users,
        }))
      } catch (error) {
        if (
          error?.response?.status == 401 ||
          error?.response?.status == 0 ||
          !error.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(error)
        }
      }
    }

    fetchFiltersData()
  }, [])

  useEffect(() => {
    if (selectedTeam && selectedTeam.length > 0) {
      const selectedTeamUsers = selectedTeam.flatMap(team => team.users)
      setFilters(prevFilters => ({
        ...prevFilters,
        teamUsers: selectedTeamUsers,
      }))
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        users: filters.users,
      }))
    }
  }, [selectedTeam])

  //setDate
  const handleDateChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  //style react select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "white",
      backgroundColor: state.isFocused ? "#EBEDF0" : "white",
      color: state.isSelected ? "white" : "black",
      borderColor: state.isFocused ? "#ddd" : "#ddd",
      boxShadow: state.isFocused ? "none" : "",
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0.5rem",
      padding: "0rem 1rem",
      // height: "3rem",
      minHeight: "3rem",
      maxHeight: "5rem",
      overflow: "auto",
      borderColor: state.isFocused ? "#ddd" : "#ddd",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #ddd",
      },

      boxShadow: state.isFocused ? "none" : "",
    }),
  }
  return (
    <div>
      <Row className="mb-4 align-items-lg-start">
        <Col className="d-flex flex-column justify-content-center">
          <Select
            options={filters.teams}
            value={selectedTeam}
            onChange={setSelectedTeam}
            className="text-capitalize"
            placeholder="Teams"
            isClearable={true}
            isMulti={true}
            styles={customStyles}
          />
        </Col>

        <Col className="d-flex flex-column justify-content-center">
          <Select
            value={selectedUser}
            onChange={setSelectedUser}
            // options={filters.users}
            options={
              selectedTeam && selectedTeam.length > 0
                ? filters.teamUsers
                : filters.users
            }
            className="text-capitalize"
            placeholder="user"
            isClearable={true}
            isMulti={true}
            styles={customStyles}
          />
        </Col>

        <Col className="d-flex flex-column justify-content-center">
          <DatePicker
            className=" broadcast-filter-date-picker text-capital "
            selectsRange={true}
            dateFormat="yyyy/MM/dd"
            startDate={startDate}
            placeholderText=" Date "
            endDate={endDate}
            onChange={handleDateChange}
            maxDate={new Date()}
            isClearable
            minDate={null}
          />
        </Col>
      </Row>
    </div>
  )
}
