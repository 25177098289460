import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import OverViewTicket from "./TicketDahboard/OverViewTicket"
import TicketCharts from "./TicketDahboard/TicketCharts"
import DatePicker from "react-datepicker"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
// import "react-datepicker/dist/react-datepicker.css"

export default function TicketDahboard() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  //setDate
  const handleDateChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleExportPDF = () => {
    const input = document.getElementById("ticket-dashboard-page") // The ID of the section you want to export
    html2canvas(input, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width, canvas.height],
      })

      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height)
      pdf.save("dashboard.pdf") // Save the PDF with a filename
    })
  }

  return (
    <div
      id="ticket-dashboard-page"
      className="user-setting-page ticket-dashboard d-flex flex-column gap-3 "
    >
      <Row className="d-flex justify-content-between align-items-center">
        <Col
          md={4}
          className="d-flex flex-grow-1 align-items-center users-setting"
        >
          <p className="mb-0">
            Ticket <span className="mx-1">&#62;</span>
            <span className="users-setting-span"> Dashboard</span>
          </p>
        </Col>

        <Col
          md={4}
          className="d-flex justify-content-end align-items-center flex-grow-0"
        >
          <Row className="d-flex align-items-center">
            <Col>
              <DatePicker
                className="broadcast-filter-date-picker text-capital py-1 px-2"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={new Date()}
                placeholderText="Select Date"
                isClearable
                minDate={null}
              />
            </Col>

            <Col>
              <button
                onClick={handleExportPDF}
                className="btn btn-outline-secondary ms-2 py-1 px-2"
              >
                Export PDF
              </button>
            </Col>
          </Row>
          {/* ******* dropdown filter ******** */}
        </Col>
      </Row>

      {/*  ******* ticket Overview ******** */}

      <OverViewTicket startDate={startDate} endDate={endDate} />

      {/*  ******* ticket Charts ******** */}

      <TicketCharts startDate={startDate} endDate={endDate} />
    </div>
  )
}
