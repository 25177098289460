import React from "react"

export default function TotalUsers({
  allUserData,
  isActive,
  setIsActive,
  isloading,
  loadingActiveFilter,
  setLoadingActiveFilter,
  initialLoading,
  setInitialLoading,
}) {
  const handleActiveFilterChange = activeStatus => {
    setIsActive(activeStatus)
    if (initialLoading) {
      setLoadingActiveFilter(false)
    }
  }

  return (
    <div className="d-flex gap-4 justify-content-between users-setting-active">
      <div
        onClick={() => handleActiveFilterChange("")}
        className={`user-status-item p-2 px-3 ${
          isActive === "" ? "active-filter" : ""
        }`}
      >
        <>
          <h5 className="m-0 mb-2 font-size-22">
            {initialLoading && loadingActiveFilter ? (
              <span>Loading....</span>
            ) : (
              <span className=" fw-bold">{allUserData.totalUsers}</span>
            )}
          </h5>
          <p className="m-0">Total Users</p>
        </>
      </div>

      <div
        onClick={() => handleActiveFilterChange(true)}
        className={`user-status-item p-2 px-3 ${
          isActive === true ? "active-filter" : ""
        }`}
      >
        <>
          <h5 className="m-0 mb-2 font-size-22">
            {initialLoading && loadingActiveFilter ? (
              <span>Loading....</span>
            ) : (
              <span className=" fw-bold">{allUserData.activeUsers}</span>
            )}
          </h5>
          <p className="m-0">Active Users</p>
        </>
      </div>

      <div
        onClick={() => handleActiveFilterChange(false)}
        className={`user-status-item flex-end p-2 px-3 ${
          isActive === false ? "active-filter" : ""
        }`}
      >
        <>
          <h5 className="m-0 mb-2 font-size-22">
            {initialLoading && loadingActiveFilter ? (
              <span>Loading....</span>
            ) : (
              <span className=" fw-bold">{allUserData.inactiveUsers}</span>
            )}
          </h5>
          <p className="m-0">Inactive Users</p>
        </>
      </div>
    </div>
  )
}
