import React, { useState } from "react"
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import Doughnut from "./Doughnut"

function PerformanceContainer({ sessionLoading }) {
  const [drp_secondary_sm, setDrp_secondary_sm] = useState(false)
  const [graphSelectedOption, setGraphSelectedOption] = useState("me")
  const [noDataToShow, setNoDataToShow] = useState(false)

  return (
    <div className="performance-chart-container">
      <div className="d-flex justify-content-between align-items-center mt-3">
        <h4 className="mb-0">Performance Data</h4>

        <ButtonDropdown
          isOpen={drp_secondary_sm}
          toggle={() => setDrp_secondary_sm(!drp_secondary_sm)}
        >
          <DropdownToggle
            caret
            style={{ fontSize: "0.875rem" }}
            color="secondary"
            className="btn btn-secondary btn-sm"
          >
            {graphSelectedOption.toUpperCase()} &nbsp;{" "}
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>

          <DropdownMenu>
            <DropdownItem
              style={{ fontSize: "1rem" }}
              onClick={() => setGraphSelectedOption("me")}
            >
              Me
            </DropdownItem>
            <DropdownItem
              style={{ fontSize: "1rem" }}
              onClick={() => setGraphSelectedOption("team")}
            >
              Team
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>

      <div id="doughnut-chart" className="e-chart">
        <Doughnut
          sessionLoading={sessionLoading}
          graphSelectedOption={graphSelectedOption}
          dataColors={
            noDataToShow
              ? '["#ddd","#ddd", "#ddd", "#ddd", "--bs-info", "--bs-success"]'
              : '["#31C456","#F6B140", "#DF4128", "#3879f0", "--bs-info", "--bs-success"]'
          }
          setNoDataToShow={setNoDataToShow}
        />
      </div>
    </div>
  )
}

export default PerformanceContainer
