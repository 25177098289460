import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import configs from "config"
import { useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"
import CustomSpinner from "components/Common/CustomSpinner"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const { Url, apiVersion } = configs.client
import "./TicketForms/TicketFormsList.css"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TicketForms = () => {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const [forms, setForms] = useState([])
  const [loadingForms, setLoadingForms] = useState(true)
  const [noFormsFound, setNoFormsFound] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const fetchFormsData = () => {
    axios
      .get(`${Url}/${apiVersion}/forms`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setForms(res.data.data.forms)

        if (res.data.data.forms.length === 0) {
          setNoFormsFound(true)
        } else {
          setNoFormsFound(false)
        }

        setLoadingForms(false)
      })
      .catch(err => {
        setLoadingForms(false)

        if (
          err.response?.status == 401 ||
          err.response?.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to fetch forms. Please try again later.")
        }
      })
  }

  const newFormsOrder = orderedForms => {
    axios
      .patch(
        `${Url}/${apiVersion}/forms/order`,
        { forms: orderedForms },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        console.log(res)
        toast.success("Forms reordered successfully!")
      })
      .catch(err => {
        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to update form order. Please try again later.")
        }
      })
  }

  useEffect(() => {
    fetchFormsData()
  }, [])

  const handleEdit = id => {
    navigate(`/ticket/forms/${id}`)
  }

  const handleAddNew = () => {
    navigate("/ticket/forms/new")
  }

  const onDragEnd = result => {
    const { destination, source } = result

    if (!destination) {
      return
    }

    const reorderedForms = Array.from(forms)
    const [movedForm] = reorderedForms.splice(source.index, 1)
    reorderedForms.splice(destination.index, 0, movedForm)

    setForms(reorderedForms)

    newFormsOrder(
      reorderedForms.map((form, index) => ({
        form: form._id,
        order: index + 1,
      }))
    )
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <React.Fragment>
      <Container fluid className="p-4">
        <Breadcrumbs title="Tickets" breadcrumbItem="Forms" />

        <Row className="mt-2">
          {loadingForms ? (
            <Col md={4} className="mx-auto">
              <CustomSpinner />
            </Col>
          ) : (
            <Col lg="12">
              <div className="d-flex mb-2 justify-content-end">
                <Button color="secondary py-1 px-2" onClick={handleAddNew}>
                  <i className="bx bx-plus"></i> Add Form
                </Button>
              </div>

              <Card className="rounded-3">
                <CardBody>
                  <div className="table-responsive">
                    <div
                      className="mt-3"
                      style={{
                        maxHeight: "74vh",
                        overflowY: "auto",
                      }}
                    >
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="forms">
                          {provided => (
                            <table
                              className="table table-bordered text-center forms-table"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{ borderCollapse: "collapse" }}
                            >
                              <thead
                                style={{
                                  backgroundColor: "#007bff",
                                  color: "#ffffff",
                                  fontWeight: "bold",
                                }}
                              >
                                <tr
                                  className="font-size-16"
                                  style={{
                                    borderBottom: "1px solid rgb(211 211 211)",
                                  }}
                                >
                                  <th>Name</th>
                                  <th style={{ width: "10%" }}></th>{" "}
                                  <th>Status</th>
                                </tr>
                              </thead>
                              {noFormsFound ? (
                                <td
                                  className="text-center py-4 font-size-16"
                                  colSpan={3}
                                >
                                  No Forms are found
                                </td>
                              ) : (
                                <tbody>
                                  {forms.map((form, index) => (
                                    <Draggable
                                      key={form._id}
                                      draggableId={form._id}
                                      index={index}
                                    >
                                      {provided => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() => handleEdit(form._id)}
                                          className="hover-form-row"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: form.default
                                              ? "bold"
                                              : "normal",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <td style={{ borderRight: "none" }}>
                                            {form.name}
                                          </td>{" "}
                                          <td
                                            style={{
                                              width: "10%",
                                              borderLeft: "none",
                                              borderRight: "none",
                                            }}
                                          >
                                            {" "}
                                            {/* No borders */}
                                            {form.default && (
                                              <span className="badge bg-primary rounded-pill py-2 px-3">
                                                Default
                                              </span>
                                            )}
                                          </td>
                                          <td style={{ borderLeft: "none" }}>
                                            {form.status === "active" ? (
                                              <span className="badge-soft-success badge bg-secondary rounded-pill py-2 px-3">
                                                Active
                                              </span>
                                            ) : (
                                              <span className="badge-soft-dark badge bg-secondary rounded-pill py-2 px-3">
                                                Inactive
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              )}
                            </table>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default withRouter(TicketForms)
