import React, { useEffect, useState } from "react"
import { Formik, useFormik, validateYupSchema } from "formik"
import { FormControl } from "react-bootstrap"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Toast,
  ToastBody,
} from "reactstrap"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function AddCategories({
  toggle,
  isOpen,
  setShowSuccessToast,
  fetchAllCategories,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const dispatch = useDispatch()

  const AddCategoriesSchema = Yup.object().shape({
    name: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    description: Yup.string(),
  })

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  // formik
  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: AddCategoriesSchema,
    onSubmit: values => {
      setIsLoading(true)
      axios
        .post(`${Url}/${apiVersion}/ticketCategories`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          if (res.status === 201) {
            fetchAllCategories()
            toggle()
            resetForm()
            setIsLoading(false)
          } else {
            console.error("Unexpected status code:", res.status, res.data)
          }
        })
        .catch(error => {
          toast.error("An error occurred. Please try again later.")

          setIsLoading(false)

          if (
            error.response.status === 401 ||
            error.response.status === 0 ||
            !error.response?.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    },
  })

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={() => resetForm()}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>Add New Categorey</ModalHeader>
      <ModalBody>
        {errorMessage && (
          <div className="" aria-live="polite" aria-atomic="true">
            <div className="">
              <Toast isOpen={errorMessage} fade={true}>
                <ToastBody className="delete-message-bg">
                  <h5 className="m-0 mb-2">Error</h5>
                  <p className="m-0">{errorMessage}</p>
                </ToastBody>
              </Toast>
            </div>
          </div>
        )}

        <Row className="justify-content-center mt-4">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup className="m-0 m-mb-3">
                  <Label className="mb-1">Name</Label>
                  <Input
                    className=" p-5px m-0 "
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                  />
                  {errors.name && touched.name && (
                    <div className="error mb-0">{errors.name}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="m-0 m-mb-5">
                  <Label className="mb-1">Description</Label>
                  <FormControl
                    as="textarea"
                    rows={6}
                    id="description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="p-2"
                  />
                  {errors.description && touched.description && (
                    <div className="error mb-0">{errors.description}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}
            <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
              <Button type="submit" className="edit-button">
                Add
              </Button>{" "}
              <Button onClick={toggle} className="edit-button cancel-delete ">
                Cancel
              </Button>
            </FormGroup>
          </form>
        </Row>
      </ModalBody>
    </Modal>
  )
}
