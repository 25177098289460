import React, { useEffect, useState } from "react"
import axios from "axios"
import {
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import { changeSelectedOverview, unAuthUser } from "store/actions"
import socket from "socket"

import configs from "config"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import TeamMemberTable from "./../../Home/TeamMember/TeamMemberTable"
const { Url, apiVersion } = configs.client

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function TicketsTeamMembers({ ticketFeature }) {
  const {
    access,
    selectedTeams,
    selectedTeamMember,
    chatsType,
    chatStatus,
    authorized,
  } = useSelector(state => ({
    access: state.Login.userAccess,
    selectedTeams: state.Login.selectedTeams,
    selectedTeamMember: state.Login.selectedTeamMember,
    chatsType: state.Login.chatsType,
    chatStatus: state.Login.chatStatus,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [teamMembersDropdown, setTeamMembersDropdown] = useState(false)
  const [teamMembers, setTeamMembers] = useState([])
  const [loadingTeams, setLoadingTeams] = useState(false)

  const toggleTeamMembersDropdown = () => {
    setTeamMembersDropdown(!teamMembersDropdown)

    // teamMembersDropdown && dispatch(updateChatStatus("all"))
    teamMembersDropdown &&
      !chatStatus &&
      dispatch(changeSelectedOverview("My Overview", "all", "userChats"))
    !teamMembersDropdown && getTeamMembersSessions()
  }

  const getTeamMembersSessions = () => {
    setLoadingTeams(true)
    // {{URL}}/ticket-filters/filters/{{teamsIDs}}/team-users

    axios
      .get(
        `${Url}/${apiVersion}/ticket-filters/filters/${selectedTeams}/team-users`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        setTeamMembers(res.data.data.teams)
        setLoadingTeams(false)
      })
      .catch(err => {
        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to load team members. Please try again.")
          setLoadingTeams(false)
        }
      })
  }

  useEffect(() => {
    teamMembersDropdown && selectedTeams && getTeamMembersSessions()
  }, [selectedTeams])

  useEffect(() => {
    if (socket && selectedTeams && teamMembersDropdown) {
      const handleUpdating = () => {
        socket.emit("client_to_server", {
          type: "teamUsersTicketFilters",
          teamsIDs: selectedTeams.split(","),
        })
      }

      const handleServerToClient = response => {
        if (response.teamUsersTicketsFilters) {
          setTeamMembers(response.teamUsersTicketsFilters)
        }
      }

      socket.on("updatingTickets", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      // Cleanup function to remove listeners on component unmount or dependencies change
      return () => {
        socket.off("updatingTickets", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [selectedTeamMember, selectedTeams, socket])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      <UncontrolledAccordion defaultOpen="0" flush>
        <AccordionItem>
          <AccordionHeader
            onClick={() => toggleTeamMembersDropdown()}
            targetId="1"
          >
            Team Members
          </AccordionHeader>

          <AccordionBody accordionId="1">
            {loadingTeams ? (
              <h5 className="text-center">Loading data...</h5>
            ) : (
              teamMembers.map(team => (
                <TeamMemberTable
                  key={team._id}
                  selectedTeamMember={selectedTeamMember}
                  team={team}
                  ticketFeature={ticketFeature}
                />
              ))
            )}
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>
    </>
  )
}

export default TicketsTeamMembers
